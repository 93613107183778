import { Alert } from '../../components/alerts/Alert';

import Card from '../../components/ui/Card';

export default function Alerts({data, loading, error}) {
    return (
        <div className="space-y-2.5">
            {data.alerts.map(a => (
                <Card className="px-2" key={a.id}>
                    <Alert o={a.id === data.alerts[0].id} alert={a}/>
                </Card>
            ))}
        </div>
    )
}