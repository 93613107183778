import { useApp, useLetsWeatherContext } from '../../utils/LocationUtil';
import { useHash, usePage } from '../../utils/LocationUtil';
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { useFetch } from '../../utils/FetchUtil';

import { HelmetLoc } from '../head/Helmet';
import { Loading } from '../ui/Icons';

import LocationHeader from '../../nav/LocationHeader';
import Notifications from './sidebar/Notifications';
import AirQuality from './sidebar/AirQuality';
import PageHeader from '../page/PageHeader';
import Outlook from './sidebar/Outlook';
import Alerts from './sidebar/Alerts';
import WSSI from './sidebar/WSSI';
import WSO from './sidebar/WSO';

import clsx from 'clsx';
import { PUSH_STATE, usePushContext } from '../../utils/PushUtil';
import ERO from './sidebar/ERO';

export default function Location({Page = null, name, data = undefined, path = undefined}) {
    if(path === undefined)
        path = name.toLowerCase();

    const { location, setLocation } = useLetsWeatherContext();
    const { subscriptionState, getSubscriptionJson } = usePushContext();

    let app = useApp();
    let params = useParams();
    let offset = useHash();
    let page = usePage();

    const loc = useFetch();
    const preview = useFetch();
    const air = useFetch();
    const wso = useFetch();
    const wssi = useFetch();
    const ero = useFetch();
    const outlooks = useFetch();

    function previewLoc() {
        preview.fetchNowLocation('preview', {offset, ...params});
    }

    useEffect(() => {
        previewLoc();
        let interval = setInterval(() => previewLoc(), 300000); // Update every 5 minutes for temp changes.

        return () => clearInterval(interval);
    }, [offset, params.country, params.city, params.zipcode]);

    useEffect(() => {
        if(preview.data !== undefined)
            setLocation(preview.data);
    }, [preview.data]);

    useEffect(() => {
        if(name !== "Notifications" || (subscriptionState.state !== PUSH_STATE.INITIALISING && !subscriptionState.state.id.includes("STARTING") && !subscriptionState.state.id.includes("PERMISSION"))) {
            loc.fetchNowLocation(path, {
                offset, ...params
            }, {
                body: name === "Notifications" ? JSON.stringify(getSubscriptionJson()) : undefined,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                method: 'POST'
            });
        }
    }, [subscriptionState, path, offset, params.country, params.city, params.zipcode]);

    useEffect(() => {
        air.fetchNowLocation('air', {offset, ...params});
        wso.fetchNowLocation('wso', {offset, ...params});
        wssi.fetchNowLocation('wssi', {offset, ...params});
        ero.fetchNowLocation('ero', {offset, ...params});
        outlooks.fetchNowLocation('outlooks', {offset, ...params});
    }, [offset, params.country, params.city, params.zipcode]);

    return (
        <div>
            <HelmetLoc title={name} location={preview.data}/>
            {!app && <LocationHeader />}
            {name === "Map" ? (
                <div className={clsx(loc.loading || loc.data === undefined || loc.data.type !== path ? "pt-2.5" : "")}>
                    {loc.loading || loc.data === undefined || loc.data.type !== path ? (app ? null : <Loading/>) : <Page {...loc}/>}
                </div>
            ) : (
                <div className="mx-auto max-w-d2 px-2.5 xs:px-6 md:px-10 xl:px-0">
                    {name !== "Current" && loc.loading && <div className="h-[32px]"/>}
                    {name !== "Precipitation" && loc !== undefined && !(loc.loading || loc.data === undefined || loc.data.type !== path) && (((loc.data[data || name.toLowerCase()] || []).length > 0) || (Object.keys(loc.data[data || name.toLowerCase()] || []).length > 0)) && <PageHeader title={name} data={loc.data} />}
                    {name === "Precipitation" && loc !== undefined && !(loc.loading || loc.data === undefined || loc.data.type !== path) && loc.data.hasData && <PageHeader title="Precipitation" data={loc.data} emptyMessage={undefined}/>}
                    <div className="space-y-2.5 lg:space-y-0 lg:grid grid-cols-10 gap-2.5 pt-2.5">
                        <div className="col-span-7">
                            {name !== "Precipitation" && loc !== undefined && !(loc.loading || loc.data === undefined || loc.data.type !== path) && (((loc.data[data || name.toLowerCase()] || []).length === 0)) && <PageHeader title={name} data={loc.data} emptyMessage={`No ${data || name.toLowerCase().replace("s", "")} information available.`}/>}
                            {name === "Precipitation" && loc !== undefined && !(loc.loading || loc.data === undefined || loc.data.type !== path) && !loc.data.hasData && <PageHeader title="Precipitation" data={loc.data} emptyMessage={"There are currently no precipitation events at this location."}/>}
                            {loc.loading || loc.data === undefined || loc.data.type !== path ? <Loading/> : <Page {...loc}/>}
                        </div>
                        <div className="col-span-3 space-y-2.5">
                            {air.data === undefined && preview.data === undefined && wso.data === undefined && <Loading/>}
                            {outlooks.data !== undefined && <Outlook outlooks={outlooks.data}/>}
                            {wssi.data !== undefined && <WSSI wssi={wssi.data}/>}
                            {wso.data !== undefined && <WSO wso={wso.data}/>}
                            {ero.data !== undefined && <ERO ero={ero.data}/>}
                            {page !== "alerts" && preview.data !== undefined && preview.data.alerts !== undefined && preview.data.alerts.length > 0 && <Alerts alerts={preview.data.alerts}/>}
                            {air.data !== undefined && <AirQuality air={air.data}/>}
                            <Notifications/>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}