import { useState } from 'react';

import { convertUnit, temperature, useUnit } from '../../utils/ConversionUtil';
import { WiRaindrop, WiWindy } from 'weather-icons-react';
import { IconText, OpenClose } from '../ui/Icons';

import DayDropdown from './DayDropdown';
import DayDropdownExtra from './DayDropdownExtra';

import clsx from 'clsx';

export default function Day({o, day}) {
    const [open, setOpen] = useState(o);
    const unit = useUnit();

    return (
        <>
            <div className={clsx("flex flex-row justify-between font-medium items-center text-white text-lg text-gray-3 px-4", !open && "cursor-pointer")}>
                {open ? (
                    <div className="w-full cursor-pointer" id="close" onClick={e => {if(e.target.id === "close") setOpen(!open)}}>
                        <div className="pt-0.5 cursor-pointer w-auto text-md text-gray-500 float-right cursor-pointer" onClick={() => setOpen(!open)}>
                            <svg className="flex ml-2 mb-0.5 h-4 w-4" viewBox="0 0 24 24">
                                <OpenClose open={open}/>
                            </svg>
                        </div>
                        <div className={clsx("w-full cursor-auto", day.both ? "grid grid-cols-1 xl:grid-cols-2 gap-3" : "grid")}>
                            {day.both && (
                                <div className="col-span-1 w-full">
                                    <div className="text-sm">
                                        <span className="font-semibold">{day.name}</span> | Day
                                        <DayDropdown both={true} {...day.day}/>
                                    </div>
                                </div>
                            )}
                            <div className={`${day.both ? " col-span-1" : ""} w-full`}>
                                <div className="text-sm">
                                    <span className="font-semibold">{day.name}</span> | Night
                                    <DayDropdown both={day.both} {...day.night}/>
                                </div>
                            </div>

                            {day.both && <DayDropdownExtra small={false} both={day.both} day={true} {...day.day}/>}
                            <DayDropdownExtra small={false} both={day.both} day={false} {...day.night}/>
                        </div>
                    </div>
                ) : (
                    <>
                        <div className="flex flex-row w-full items-center" onClick={() => setOpen(!open)}>
                            <div className="flex-shrink text-sm" style={{flexBasis: "70px"}}>
                                {day.both ? day.day.date : day.night.day}
                            </div>
                            <div style={{flexBasis: "90px"}}>
                                {day.both ? <><span className="font-bold text-xl">{temperature(unit, day.day.temperature, false)}°</span><span className="text-md"><span className="ml-px mr-0.5">/</span>{temperature(unit, day.night.temperature, false)}°</span></> : <><span className="font-bold text-xl">--°</span><span className="text-md"><span className="ml-px mr-0.5">/</span>{temperature(unit, day.night.temperature, false)}°</span></>}
                            </div>
                            <div className="flex items-center text-md flex-auto">
                                <IconText text={day.title} night={!day.both} size={26}/><span className="hidden md:flex text-sm truncate max-w-[325px] ml-2">{day.title}</span>
                            </div>
                            <div className="flex items-center text-sm">
                                {(day.both ? day.day.precipitationChance : day.night.precipitationChance) !== -1 && (
                                    <div className="flex justify-between items-center mr-3 whitespace-nowrap" style={{minWidth: "65px"}}>
                                        <span className="text-[#00BFFF]"><WiRaindrop size={30}/></span>{day.both ? (day.day.precipitationChance > day.night.precipitationChance ? day.day.precipitationChance : day.night.precipitationChance) : day.night.precipitationChance}%<span className="text-transparent select-none">{(day.both ? (day.day.precipitationChance > day.night.precipitationChance ? day.day.precipitationChance : day.night.precipitationChance) : day.night.precipitationChance) < 10 ? "0" : ""}</span>
                                    </div>
                                )}
                                <div className="hidden xsm:flex items-center" style={{minWidth: "175px"}}>
                                    <span className="text-[#00BFFF]"><WiWindy size={30}/></span>
                                    <div className="flex whitespace-nowrap ml-2">
                                        {((day.both ? day.day : day.night).windSpeed !== undefined) ? (
                                            <>
                                                {day.both ? `${day.day.windDirection || ""} ${convertUnit(unit, `${day.day.windSpeed} mph`)}` : `${day.night.windDirection || ""} ${convertUnit(unit, `${day.night.windSpeed} mph`)}`}
                                            </>
                                        ) : (
                                            <>
                                                Unknown
                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="flex" onClick={() => setOpen(!open)}>
                            <div className="flex items-center text-md text-gray-500 float-right">
                                <svg className="flex ml-2 mb-0.5 h-4 w-4" viewBox="0 0 24 24">
                                    <OpenClose open={open}/>
                                </svg>
                            </div>
                        </div>
                    </>
                )}
            </div>
        </>
    )
}
