import { Icon } from '@iconify/react';


import clsx from 'clsx';
import Moment from '../../utils/TimeUtil';
import { capitalize } from '../../utils/WordUtil';

export default function DayDropdownExtra(props) {
    return (
        <div
            className={clsx(props.small ? "block lg:hidden" : "hidden lg:block", "p-2 rounded-lg text-sm")}> {/* border border-gray-800/30 */}
            <div className={`grid grid-cols-1 lg:grid-cols-1 gap-3 p-0.5`}>
                {props.isDay && (
                    <div className="grid-span-1 border-t pt-3 border-gray-800/30">
                        <div className="flex grid grid-cols-2 gap-2">
                            <div className="flex grid-span-1 mx-auto">
                                <div className="flex items-center">
                                    <svg className="h-6 w-6" style={{fill: "#ffd158", color: "#febc11"}}
                                         aria-hidden="true" viewBox="0 0 24 24">
                                        <path d="M12.003 16.125v-2.21m-5.602 2.129l1.69 1.441m9.237-1.489l-1.4 1.63"
                                              stroke="currentColor" strokeWidth="2" strokeLinecap="round"/>
                                        <path d="M4.05 20.938h2.48m11.27 0h2.481" stroke="currentColor" strokeWidth="2"
                                              strokeLinecap="round" strokeLinejoin="round"/>
                                        <path d="M12 9.938V4.426M8.563 6.5L12 3.062M15.438 6.5L12 3.062"
                                              stroke="currentColor" strokeLinecap="round" strokeWidth="2"/>
                                        <path
                                            d="M12.02 21.605h3.059c.421 0 .543-.229.543-.455 0-1.735-1.613-3.142-3.602-3.142-1.99 0-3.603 1.407-3.603 3.142 0 .266.1.455.529.455h3.074z"/>
                                    </svg>
                                </div>
                                <div className="ml-1.5">
                                    <div className="text-sm">Sunrise</div>
                                    <div className="font-semibold lowercase">
                                        <Moment unix={props.sunrise} timeDay={true}/>
                                    </div>
                                </div>
                            </div>
                            <div className="flex grid-span-1 mx-auto">
                                <div className="flex items-center">
                                    <svg className="h-6 w-6" style={{fill: "#ffd158", color: "#febc11"}}
                                         aria-hidden="true" viewBox="0 0 24 24">
                                        <path d="M12.003 15.781v-2.21M6.401 15.7l1.69 1.442m9.237-1.49l-1.4 1.63"
                                              stroke="currentColor" strokeWidth="2" strokeLinecap="round"/>
                                        <path d="M4.05 20.594h2.48m11.27 0h2.481" stroke="currentColor" strokeWidth="2"
                                              strokeLinecap="round" strokeLinejoin="round"/>
                                        <path d="M12 3.063v5.51M8.563 6.5L12 9.938M15.438 6.5L12 9.938"
                                              stroke="currentColor" strokeLinecap="round" strokeWidth="2"/>
                                        <path
                                            d="M12.02 21.261h3.059c.421 0 .543-.229.543-.455 0-1.735-1.613-3.142-3.602-3.142-1.99 0-3.603 1.407-3.603 3.142 0 .266.1.455.529.455h3.074z"/>
                                    </svg>
                                </div>
                                <div className="ml-1.5">
                                    <div className="text-sm">Sunset</div>
                                    <div className="font-semibold lowercase">
                                        <Moment unix={props.sunset} timeDay={true}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                {!props.isDay && (
                    <div className="grid-span-1 border-t pt-3 border-gray-800/30">
                        <div className="flex grid grid-cols-2 gap-2">
                            <div className="flex grid-span-1 mx-auto">
                                <div className="flex items-center">
                                    <svg className="h-6 w-6" style={{fill: "#77756f"}} aria-hidden="true"
                                         viewBox="0 0 24 24">
                                        <path
                                            d="M12 17.186c3.59 0 6.5 2.542 6.5 5.678 0 .409-.22.822-.98.822H6.454c-.773 0-.955-.342-.955-.822 0-3.136 2.91-5.678 6.5-5.678zM12.062.202c.291.014.578.133.8.355l4.19 4.19a1.219 1.219 0 11-1.724 1.723l-2.11-2.11v5.438a1.219 1.219 0 01-2.43.124l-.007-.124V4.36L8.672 6.47a1.219 1.219 0 01-1.624.089l-.1-.089a1.219 1.219 0 01-.088-1.625l.089-.099 4.19-4.189c.221-.222.508-.34.799-.355h.124z"/>
                                    </svg>
                                </div>
                                <div className="ml-1.5">
                                    <div className="text-sm">Moonrise</div>
                                    <div className="font-semibold lowercase">
                                        <Moment unix={props.moonrise} timeDay={true}/>
                                    </div>
                                </div>
                            </div>
                            <div className="flex grid-span-1 mx-auto">
                                <div className="flex items-center">
                                    <svg className="h-6 w-6" style={{fill: "#77756f"}} aria-hidden="true"
                                         viewBox="0 0 24 24">
                                        <path
                                            d="M12 17.186c3.59 0 6.5 2.542 6.5 5.678 0 .409-.22.822-.98.822H6.454c-.773 0-.955-.342-.955-.822 0-3.136 2.91-5.678 6.5-5.678zM12 .2c.631 0 1.15.48 1.212 1.094l.007.125-.001 5.436 2.11-2.109a1.219 1.219 0 011.624-.088l.1.088c.443.445.473 1.146.088 1.625l-.089.099-4.19 4.19c-.19.19-.427.304-.674.342l-.125.013h-.124a1.214 1.214 0 01-.8-.356L6.948 6.47a1.219 1.219 0 111.724-1.724l2.109 2.109V1.419C10.781.746 11.327.2 12 .2z"/>
                                    </svg>
                                </div>
                                <div className="ml-1.5">
                                    <div className="text-sm">Moonset</div>
                                    <div className="font-semibold lowercase">
                                        <Moment unix={props.moonset} timeDay={true}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
            {!props.isDay && (
                <div className="flex items-center justify-center pt-4">
                    <div className="text-gray-300">
                        <Icon
                            icon={`wi:moon-alt-${props.moonPhase === "FULL_MOON" || props.moonPhase === "NEW_MOON" || props.moonPhase === "FIRST_QUARTER" ? `${props.moonPhase.toLowerCase().replace("_moon", "").replace("_", "-")}` : `${props.moonPhase.toLowerCase().replace("_", "-")}-3`}`}
                            width={18} height={18}/>
                    </div>
                    <div className="ml-1.5 text-xs-md">
                        {capitalize(props.moonPhase.replace("_", " "))}
                    </div>
                </div>
            )}
        </div>
    )
}