import { NavLink } from 'react-router-dom';

import { InfoIcon, RightArrow } from '../../ui/Icons';
import { useHash, useUrl } from '../../../utils/LocationUtil';

import Card from '../../ui/Card';
import Moment from '../../../utils/TimeUtil';

export default function Alerts({alerts}) {
    const url = useUrl();
    const hash = useHash(true);

    return alerts === undefined || alerts === null || !(typeof Array.isArray(alerts)) || alerts.length === 0 ? null : (
        <Card className="px-4">
            <span className="text-sm font-semibold tracking-wider text-white uppercase">Alerts</span>
            <div className="text-xs font-medium">
                <Moment time={false} unix={[...alerts].sort((a, b) => { return a.effective - b.effective})[alerts.length - 1].effective}/>
            </div>
            <div className="mt-2.5 space-y-2">
                {alerts.map((a, i) => (
                    <NavLink to={`${url}/alerts${hash}`} key={i} className="flex items-center text-sm font-medium dark:text-white">
                        <InfoIcon className="mr-2 h-4 w-4" color={a.color.hexColor}/>
                        {a.event}
                    </NavLink>
                ))}
            </div>
            <NavLink to={`${url}/alerts${hash}`} className="flex items-center justify-between pt-2.5">
                <div className="mb-0.5">{alerts.length > 0 && <span className="font-medium text-gray-100 uppercase text-xs tracking-wider">{alerts.length} Current Alert{alerts.length === 1 ? "" : "s"}</span>}</div>
                <div className="flex">
                    <div className="flex items-center space-x-1">
                        <div className="flex items-center text-sm font-medium text-gray-100">See More </div>
                        <RightArrow className="text-gray-600 dark:text-gray-400 h-3 w-3"/>
                    </div>
                </div>
            </NavLink>
        </Card>
    )
}
