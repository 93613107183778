import { ReactComponent as HeavySnowWhite } from '../../assets/images/snow/heavySnowWhite.svg';
import { ReactComponent as HeavySnow } from '../../assets/images/snow/heavySnow.svg';
import { ReactComponent as LightSnow } from '../../assets/images/snow/lightSnow.svg';
import { ReactComponent as Snow } from '../../assets/images/snow/snow.svg';

import { ReactComponent as Drizzle } from '../../assets/images/rain/drizzle.svg';
import { ReactComponent as LightRain } from '../../assets/images/rain/lightRain.svg';
import { ReactComponent as Rain } from '../../assets/images/rain/rain.svg';
import { ReactComponent as HeavyRain } from '../../assets/images/rain/heavyRain.svg';
import { ReactComponent as HeavyRainWhite } from '../../assets/images/rain/heavyRainWhite.svg';
import { ReactComponent as Thunder } from '../../assets/images/rain/thunder.svg';

import { ReactComponent as CloudyDay } from '../../assets/images/cloudy/cloudyDay.svg';
import { ReactComponent as CloudyNight } from '../../assets/images/cloudy/cloudyNight.svg';

import { ReactComponent as Day } from '../../assets/images/sunny/day.svg';
import { ReactComponent as Night } from '../../assets/images/sunny/night.svg';

import { ReactComponent as Fog } from '../../assets/images/fog.svg';
import { ReactComponent as Ice } from '../../assets/images/sleet/ice.svg';
import { ReactComponent as RainSnow } from '../../assets/images/sleet/rainAndSnow.svg';
import { pickTextColorBasedOnBgColorSimple } from '../../utils/IconUtil';
import clsx from 'clsx';

export function IconText({text, size, className, night = false}) {
    if(text === undefined)
        return null;

    const t = text.toLowerCase();
    let Svg;

    if(t.includes("thunderstorms")) {
        Svg = Thunder;
    } else if(t.includes("sleet") || t.includes("ice") || (t.includes("freez") && (t.includes("drizzle") || t.includes("rain")))) {
        Svg = Ice;
    } else if(t.includes("drizzle")) {
        Svg = Drizzle;
    } else if(t.includes("rain") && t.includes("snow")) {
        Svg = RainSnow;
    } else if(t.includes("rain")) {
        if (t.includes("heavy") && t.includes("white")) {
            Svg = HeavyRainWhite;
        } else if (t.includes("light")) {
            Svg = LightRain;
        } else if (t.includes("heavy")) {
            Svg = HeavyRain;
        } else {
            Svg = Rain;
        }
    } else if (t.includes("flurries")) {
        Svg = LightSnow;
    } else if(t.includes("snow")) {
        if (t.includes("heavy") && t.includes("white")) {
            Svg = HeavySnowWhite;
        } else if (t.includes("heavy")) {
            Svg = HeavySnow;
        } else if (t.includes("light")) {
            Svg = LightSnow;
        } else {
            Svg = Snow;
        }
    } else if(t.includes("fog") || t.includes("haze") || t.includes("mist")) {
        Svg = Fog;
    } else if(t.includes("sun") || t.includes("clear")) {
        Svg = night ? Night : Day;
    } else {
        Svg = night ? CloudyNight : CloudyDay;

        return <Svg className={className || "text-lwbg"} style={{width: `${size}px`, height: `${size}px`}}/>;
    }

    return <Svg className={className || "text-lwbg"} style={{width: `${size}px`, height: `${size}px`}}/>;
}

export function InfoIcon({color, className}) {
    return (
        <svg className={className || "mr-2 h-5 w-5"} style={{color: color}} data-testid="Icon" aria-hidden="true" role="img" viewBox="0 0 24 24">
            <circle fill="currentColor" transform="matrix(-1 0 0 1 24 0)" cx="12" cy="12" r="12"/>
            <path fill={pickTextColorBasedOnBgColorSimple(color, '#FFF', '#000')} d="M12 19a1.5 1.5 0 100-3 1.5 1.5 0 000 3zm-.75-5h1.5S14 7.77 14 7a2 2 0 10-4 0c0 1.105 1.25 7 1.25 7z"/>
        </svg>
    )
}

export function RightArrow({className}) {
    return (
        <svg className={className || "h-3 w-3"} data-testid="Icon" aria-hidden="true" role="img" viewBox="0 0 24 24">
            <path fill="currentColor" d="M15.086 12L7.793 4.707a1 1 0 1 1 1.414-1.414l8 8a1 1 0 0 1 0 1.414l-8 8a1 1 0 1 1-1.414-1.414L15.086 12z"/>
        </svg>
    )
}

export function OpenClose({open}) {
    return <path fill="currentColor" d={open ? "M12 8.914l7.293 7.293a1 1 0 1 0 1.414-1.414l-8-8a1 1 0 0 0-1.414 0l-8 8a1 1 0 1 0 1.414 1.414L12 8.914z" : "M12 16.086l7.293-7.293a1 1 0 1 1 1.414 1.414l-8 8a1 1 0 0 1-1.414 0l-8-8a1 1 0 1 1 1.414-1.414L12 16.086z"}/>;
}

export function Loading({className}) {
    return (
        <div className={clsx("flex justify-center", className)}>
            <div aria-busy="true" className="">
                <svg width="50" height="30" viewBox="0 0 120 30" xmlns="http://www.w3.org/2000/svg" fill="#00BFFF"
                     aria-label="audio-loading">
                    <circle cx="15" cy="15" r="15">
                        <animate attributeName="r" from="15" to="15" begin="0s" dur="0.8s" values="15;9;15"
                                 calcMode="linear" repeatCount="indefinite"/>
                        <animate attributeName="fill-opacity" from="1" to="1" begin="0s" dur="0.8s" values="1;.5;1"
                                 calcMode="linear" repeatCount="indefinite"/>
                    </circle>
                    <circle cx="60" cy="15" r="9" attributeName="fill-opacity" from="1" to="0.3">
                        <animate attributeName="r" from="9" to="9" begin="0s" dur="0.8s" values="9;15;9"
                                 calcMode="linear" repeatCount="indefinite"/>
                        <animate attributeName="fill-opacity" from="0.5" to="0.5" begin="0s" dur="0.8s" values=".5;1;.5"
                                 calcMode="linear" repeatCount="indefinite"/>
                    </circle>
                    <circle cx="105" cy="15" r="15">
                        <animate attributeName="r" from="15" to="15" begin="0s" dur="0.8s" values="15;9;15"
                                 calcMode="linear" repeatCount="indefinite"/>
                        <animate attributeName="fill-opacity" from="1" to="1" begin="0s" dur="0.8s" values="1;.5;1"
                                 calcMode="linear" repeatCount="indefinite"/>
                    </circle>
                </svg>
            </div>
        </div>
    )
}
