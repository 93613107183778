import { WiRaindrop, WiWindy } from 'weather-icons-react';

import { convertUnit, temperature, useUnit } from '../../utils/ConversionUtil';
import { IconText } from '../ui/Icons';

import DayDropdownExtra from './DayDropdownExtra';

export default function DayDropdown(day) {
    const unit = useUnit();

    return (
        <div>
            <div className="flex items-center">
                <div className="text-6xl font-bold">
                    <div className="flex">
                        {temperature(unit, day.temperature, false)}&deg;
                        <div className="ml-3">
                            <IconText text={day.shortForecast} night={!day.isDay} size={60}/>
                        </div>
                    </div>
                </div>
                <div className="pl-6">
                    {day.precipitationChance !== -1 && (
                        <div className="flex items-center">
                            <span className="text-[#00BFFF]"><div className="mt-0.5"><WiRaindrop size={30}/></div></span>{day.precipitationChance}%
                        </div>
                    )}
                    {day.humidity !== -1 && day.humidity !== undefined && (
                        <div className="flex items-center">
                            <svg className="w-5 h-5 ml-1 mr-1.5" viewBox="0 0 24 24">
                                <path fill="#00BFFF" fillRule="evenodd"
                                      d="M11.743 17.912a4.182 4.182 0 0 1-2.928-1.182 3.972 3.972 0 0 1-.614-4.962.743.743 0 0 1 .646-.349c.234 0 .476.095.66.275l4.467 4.355c.385.376.39.998-.076 1.275a4.216 4.216 0 0 1-2.155.588M11.855 4c.316 0 .61.14.828.395.171.2.36.416.562.647 1.857 2.126 4.965 5.684 4.965 8.73 0 3.416-2.85 6.195-6.353 6.195-3.505 0-6.357-2.78-6.357-6.195 0-3.082 2.921-6.406 4.854-8.605.242-.275.47-.535.673-.772A1.08 1.08 0 0 1 11.855 4"/>
                            </svg>
                            {day.humidity}%
                        </div>
                    )}
                    <div className="flex items-center">
                        <span className="text-[#00BFFF]"><WiWindy size={30}/></span>{day.windSpeed === undefined ? "Unknown" : `${day.windDirection || ""} ${convertUnit(unit, `${day.windSpeed} mph`)}`}
                    </div>
                </div>
            </div>
            <div className="text-gray-300 pt-1.5">
                <span className="text-white text-base font-semibold">{day.shortForecast}</span><br/>
                {convertUnit(unit, day.forecast)}
            </div>

            <DayDropdownExtra small={true} both={day.both} day={!day.both} {...day}/>
        </div>
    )
}
