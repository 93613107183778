import Card from '../../components/ui/Card';
import Product from '../../components/products/Product';

export default function Products({data, loading, error}) {
    let zfp = data.products.filter(s => s.productCode === "ZFP")[0];

    function guessYear(d, s) {
        return s.includes(d + "\n\n");
    }

    if(zfp !== undefined && zfp.nearestCounty !== undefined && zfp.nearestCounty !== "") {
        const beginning = zfp.productText.split((guessYear(new Date().getFullYear(), zfp.productText) ? new Date().getFullYear() : (new Date().getFullYear() + 1)) + "\n\n")[0];
        const text = zfp.productText.replace(beginning, "").split("\n\n$$\n\n");

        for (let i2 = 0; i2 < text.length; i2++) {
            if (text[i2].includes(zfp.nearestCounty.replace(" County", "") + "-")) {
                zfp = {...zfp, productText: beginning + "\n\n" + text[i2].replace("-\n", "-").replace(zfp.nearestCounty.replace(" County", "") + "-", zfp.nearestCounty.replace(" County", ""))}
            }
        }
    }

    const products = [...data.products.filter(s => s.productCode !== "ZFP"), zfp].filter(s => s !== undefined);

    return (
        <div className="space-y-2.5">
            {products.map(p => (
                <Card className="px-2" key={p.id}>
                    <Product o={p.id === data.products[1].id} product={p}/>
                </Card>
            ))}
        </div>
    )
}