import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';

import './index.css';

import LetsWeather from './LetsWeather';

import { BrowserRouter, useLocation } from 'react-router-dom';

import moment from 'moment';
import { createInstance, MatomoProvider } from '@jonkoops/matomo-tracker-react';
moment.relativeTimeThreshold('s', 60);
moment.relativeTimeThreshold('m', 60);
moment.relativeTimeThreshold('h', 24);
moment.relativeTimeThreshold('d', 7);
moment.relativeTimeThreshold('w', 4);
moment.relativeTimeThreshold('M', 12);

const instance = createInstance({
    urlBase: 'https://matomo.eazyftw.com',
    siteId: 1
});

const MatomoRouterProvider = (
    {children}: {children: JSX.Element}) => {

    let location = useLocation();
    useEffect(() => {
        // track page view on each location change
        instance.trackPageView();
    }, [location]);

    return (
        <MatomoProvider value={instance}>
            {children}
        </MatomoProvider>
    )
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <BrowserRouter>
        <MatomoRouterProvider>
            <LetsWeather/>
        </MatomoRouterProvider>
    </BrowserRouter>
);
