import { useRoutes} from 'react-router-dom';

import Home from './pages/Home';
import Discord from './pages/Discord';
import Cameras from './pages/Cameras';

import Current from './pages/location/Current';
import Hourly from './pages/location/Hourly';
import Daily from './pages/location/Daily';
import Weekend from './pages/location/Weekend';
import Alerts from './pages/location/Alerts';
import Map from './pages/location/Map';
import Products from './pages/location/Products';
import Graphs from './pages/location/Graphs';
import Precipitation from './pages/location/Precipitation';

import NotFound from './pages/NotFound';
import Location from './components/location/Location';
import Alert from './pages/Alert';
import Radar from './pages/Radar';
import Notifications from './pages/location/Notifications';

const CHILDREN = [
        { path: "", element: <Location Page={Current} name='Current' data='temperature' /> },
        { path: "hourly", element: <Location Page={Hourly} name='Hourly' data='days' path='hourlyDays' /> },
        { path: "daily", element: <Location Page={Daily} name='Daily' data='days' /> },
        { path: "weekend", element: <Location Page={Weekend} name='Weekend' data='days' /> },
        { path: "alerts", element: <Location  Page={Alerts} name='Alerts' /> },
        { path: "map", element: <Location Page={Map} name='Map' path='current' /> },
        { path: "products", element: <Location Page={Products} name='Products' /> },
        { path: "graphs", element: <Location Page={Graphs} name='Graphs' /> },
        { path: "precipitation", element: <Location Page={Precipitation} name='Precipitation' /> },
        { path: "notifications", element: <Location Page={Notifications} name='Notifications' /> },
]

export function LetsWeatherRoutes() {
    return useRoutes([
        { path: "/", element: <Home /> },
        { path: "/discord", element: <Discord /> },
        { path: "/cameras", element: <Cameras /> },
        { path: "/radar", element: <Radar /> },
        { path: "/alert/:id", element: <Alert /> },
        {
            path: "/en/:country/:city/:zipcode",
            children: CHILDREN
        },
        {
            path: "/en/:latlng",
            children: CHILDREN
        },
        // Not found routes work as you'd expect
        { path: "*", element: <NotFound /> },
    ]);
}