import { WiRaindrops, WiThermometerExterior, WiWindy } from 'weather-icons-react';
import { convertUnit, temperature, useUnit } from '../../utils/ConversionUtil';

import clsx from 'clsx';

export default function HourDropdown(hour) {
    const unit = useUnit();

    return (
        <div className="pt-3">
            <span className="block md:hidden text-sm font-semibold text-white pb-2 pl-1.5">{hour.name}</span>
            <div className="w-auto border-t border-gray-800/30 text-white pt-4 ml-1.5 mr-1.5 p-2">
                <div
                    className={clsx("hidden lg:flex w-full text-md pl-4 pr-7 items-center", hour.feelsLike === -1 ? "justify-evenly" : "justify-between")}>
                    {hour.feelsLike !== -1 && (
                        <div className="flex">
                            <div className="flex items-center">
                                <span className="text-[#00BFFF]"><WiThermometerExterior size={25}/></span>
                            </div>
                            <div className="ml-1.5 font-semibold">
                                <div className="font-medium text-sm">Feels Like</div>
                                {temperature(unit, hour.feelsLike, false)}&deg;
                            </div>
                        </div>
                    )}
                    {hour.humidity !== -1 && (
                        <div className="flex">
                            <div className="flex items-center">
                                <svg className="w-5 h-5" viewBox="0 0 24 24">
                                    <path fill="#00BFFF" fillRule="evenodd"
                                          d="M11.743 17.912a4.182 4.182 0 0 1-2.928-1.182 3.972 3.972 0 0 1-.614-4.962.743.743 0 0 1 .646-.349c.234 0 .476.095.66.275l4.467 4.355c.385.376.39.998-.076 1.275a4.216 4.216 0 0 1-2.155.588M11.855 4c.316 0 .61.14.828.395.171.2.36.416.562.647 1.857 2.126 4.965 5.684 4.965 8.73 0 3.416-2.85 6.195-6.353 6.195-3.505 0-6.357-2.78-6.357-6.195 0-3.082 2.921-6.406 4.854-8.605.242-.275.47-.535.673-.772A1.08 1.08 0 0 1 11.855 4"/>
                                </svg>
                            </div>
                            <div className="ml-1.5 font-semibold">
                                <div className="font-medium text-sm">Humidity</div>
                                {hour.humidity}%
                            </div>
                        </div>
                    )}
                    {hour.dewpoint !== -1 && (
                        <div className="flex">
                            <div className="flex items-center">
                                <span className="text-[#00BFFF]"><WiRaindrops size={35}/></span>
                            </div>
                            <div className="ml-1.5 font-semibold">
                                <div className="font-medium text-sm">Dewpoint</div>
                                {temperature(unit, hour.dewpoint, false)}&deg;
                            </div>
                        </div>
                    )}
                    {hour.windSpeed !== -1 && (
                        <div className="flex">
                            <div className="flex items-center">
                                <span className="text-[#00BFFF]"><WiWindy size={25}/></span>
                            </div>
                            <div className="ml-2 font-semibold">
                                <div className="font-medium text-sm">Wind</div>
                                {hour.windSpeed === undefined ? "N/A" : `${hour.windDirection} ${convertUnit(unit, `${hour.windSpeed} mph`)}`}
                            </div>
                        </div>
                    )}
                    {hour.windGust !== -1 && (
                        <div className="flex">
                            <div className="flex items-center">
                                <svg className="text-[#00BFFF] w-5 h-5" viewBox="0 0 24 24">
                                    <path
                                        d="M6 8.67h5.354c1.457 0 2.234-1.158 2.234-2.222S12.687 4.4 11.354 4.4c-.564 0-1.023.208-1.366.488M3 11.67h15.54c1.457 0 2.235-1.158 2.235-2.222S19.873 7.4 18.54 7.4c-.747 0-1.311.365-1.663.78M6 15.4h9.389c1.457 0 2.234 1.159 2.234 2.223 0 1.064-.901 2.048-2.234 2.048a2.153 2.153 0 0 1-1.63-.742"
                                        strokeWidth="2" stroke="currentColor" strokeLinecap="round" fill="none"/>
                                </svg>
                            </div>
                            <div className="ml-2 font-semibold">
                                <div className="font-medium text-sm">Wind Gusts</div>
                                {convertUnit(unit, `${Math.round(hour.windGust)} mph`)}
                            </div>
                        </div>
                    )}
                    {hour.visibility !== -1 && (
                        <div className="flex">
                            <div className="flex items-center">
                                <svg xmlns="http://www.w3.org/2000/svg" className="text-[#00BFFF] w-5 h-5" fill="none"
                                     viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                                          d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"/>
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                                          d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"/>
                                </svg>
                            </div>
                            <div className="ml-2 font-semibold">
                                <div className="font-medium text-sm">Visibility</div>
                                {convertUnit(unit, `${hour.visibility === -1 ? "10" : (hour.visibility.toFixed(2).endsWith(".00") ? hour.visibility : hour.visibility.toFixed(2))} mi`)}
                            </div>
                        </div>
                    )}
                </div>
                <div className="block lg:hidden">
                    {hour.feelsLike !== -1 && hour.humidity !== -1 && hour.dewpoint !== -1 && (
                        <div className="flex lg:hidden w-full text-sm justify-evenly items-center">
                            {hour.feelsLike !== -1 && (
                                <div className="flex" style={{flexBasis: "130px"}}>
                                    <div className="flex items-center">
                                        <span className="text-[#00BFFF]"><WiThermometerExterior size={25}/></span>
                                    </div>
                                    <div className="ml-1.5 font-semibold">
                                        <div className="font-medium text-xs">Feels Like</div>
                                        {temperature(unit, hour.feelsLike, false)}&deg;
                                    </div>
                                </div>
                            )}
                            {hour.humidity !== -1 && (
                                <div className="flex" style={{flexBasis: "130px"}}>
                                    <div className="flex items-center">
                                        <svg className="w-5 h-5" viewBox="0 0 24 24">
                                            <path fill="#00BFFF" fillRule="evenodd"
                                                  d="M11.743 17.912a4.182 4.182 0 0 1-2.928-1.182 3.972 3.972 0 0 1-.614-4.962.743.743 0 0 1 .646-.349c.234 0 .476.095.66.275l4.467 4.355c.385.376.39.998-.076 1.275a4.216 4.216 0 0 1-2.155.588M11.855 4c.316 0 .61.14.828.395.171.2.36.416.562.647 1.857 2.126 4.965 5.684 4.965 8.73 0 3.416-2.85 6.195-6.353 6.195-3.505 0-6.357-2.78-6.357-6.195 0-3.082 2.921-6.406 4.854-8.605.242-.275.47-.535.673-.772A1.08 1.08 0 0 1 11.855 4"/>
                                        </svg>
                                    </div>
                                    <div className="ml-2 font-semibold">
                                        <div className="font-medium text-xs">Humidity</div>
                                        {hour.humidity}%
                                    </div>
                                </div>
                            )}
                            {hour.dewpoint !== -1 && (
                                <div className="flex" style={{flexBasis: "130px"}}>
                                    <div className="flex items-center">
                                        <span className="text-[#00BFFF]"><WiRaindrops size={35}/></span>
                                    </div>
                                    <div className="ml-1.5 font-semibold">
                                        <div className="font-medium text-xs">Dewpoint</div>
                                        {temperature(unit, hour.dewpoint, false)}&deg;
                                    </div>
                                </div>
                            )}
                        </div>
                    )}
                    <div
                        className={`flex lg:hidden w-full text-sm justify-evenly items-center${hour.feelsLike !== -1 && hour.humidity !== -1 && hour.dewpoint !== -1 ? " pt-1" : ""}`}>
                        {hour.windSpeed !== -1 && (
                            <div className="flex" style={{flexBasis: "130px"}}>
                                <div className="flex items-center">
                                    <span className="text-[#00BFFF]"><WiWindy size={25}/></span>
                                </div>
                                <div className="ml-1.5 font-semibold">
                                    <div className="font-medium text-xs">Wind</div>
                                    {hour.windDirection} {Math.round(hour.windSpeed)} mph
                                </div>
                            </div>
                        )}
                        {hour.windGust !== -1 && (
                            <div className="flex" style={{flexBasis: "130px"}}>
                                <div className="flex items-center">
                                    <svg className="text-[#00BFFF] w-5 h-5" viewBox="0 0 24 24">
                                        <path
                                            d="M6 8.67h5.354c1.457 0 2.234-1.158 2.234-2.222S12.687 4.4 11.354 4.4c-.564 0-1.023.208-1.366.488M3 11.67h15.54c1.457 0 2.235-1.158 2.235-2.222S19.873 7.4 18.54 7.4c-.747 0-1.311.365-1.663.78M6 15.4h9.389c1.457 0 2.234 1.159 2.234 2.223 0 1.064-.901 2.048-2.234 2.048a2.153 2.153 0 0 1-1.63-.742"
                                            strokeWidth="2" stroke="currentColor" strokeLinecap="round" fill="none"/>
                                    </svg>
                                </div>
                                <div className="ml-2 font-semibold">
                                    <div className="font-medium text-xs">Wind Gusts</div>
                                    {Math.round(hour.windGust)} mph
                                </div>
                            </div>
                        )}
                        {hour.visibility !== -1 && (
                            <div className="flex" style={{flexBasis: "130px"}}>
                                <div className="flex items-center ml-2">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="text-[#00BFFF] w-5 h-5"
                                         fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                                              d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"/>
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                                              d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"/>
                                    </svg>
                                </div>
                                <div className="ml-3 font-semibold">
                                    <div className="font-medium text-xs">Visibility</div>
                                    {convertUnit(unit, `${hour.visibility === -1 ? "10" : (hour.visibility.toFixed(2).endsWith(".00") ? hour.visibility : hour.visibility.toFixed(2))} mi`)}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}
