import { useLetsWeatherContext } from '../../utils/LocationUtil';

import { useEffect, useState } from 'react';
import { Map as LocationMap } from '../../components/map/Map';

export default function Map() {
    const { location } = useLetsWeatherContext();
    const [center, setCenter] = useState(null);

    useEffect(() => {
        if (location?.location?.latitude && location?.location?.longitude)
            setCenter([location.location.latitude, location.location.longitude]);
    }, [location]);

    // return center === null ? null : <LocationMap zoom={6.5} key={center} center={center} top={true} show={{outlooks: true, alerts: true, radar: false, popups: true, wso: true, wssi: true}}/>;
    return center === null ? null : <LocationMap top={true} zoom={11} center={center} show={{outlooks: true, alerts: true, radar: false, popups: true}}/>;
}