import { useState } from 'react';

import { convertUnit, temperature, useUnit } from '../../utils/ConversionUtil';
import { IconText, OpenClose } from '../ui/Icons';
import { WiRaindrop, WiWindy } from 'weather-icons-react';

import HourDropdown from './HourDropdown';

export default function Hour({o, hour}) {
    const unit = useUnit();
    const [open, setOpen] = useState(o);

    return (
        <>
            <div>
                <div className="flex flex-row justify-between items-center text-lg font-medium text-white px-4 cursor-pointer">
                    <div className="flex flex-row w-full items-center" onClick={() => setOpen(!open)}>
                        <div className="flex-shrink text-sm" style={{flexBasis: "70px"}}>
                            {hour.time}
                        </div>
                        <div className="font-bold text-xl" style={{flexBasis: "90px"}}>
                            {temperature(unit, hour.temperature, false)}&deg;
                        </div>
                        <div className="flex items-center text-md font-medium flex-auto">
                            <IconText text={hour.name} night={!hour.isDay} size={26}/><span className="hidden md:block text-sm ml-2">{hour.name}</span>
                        </div>
                        <div className="flex items-center text-sm">
                            <div className="flex justify-between items-center mr-3 whitespace-nowrap" style={{flexBasis: "75px"}}>
                                <span className="text-[#00BFFF]"><WiRaindrop size={28}/></span>
                                {hour.precipitationChance}%<span className="text-transparent select-none">{hour.precipitationChance < 10 ? "0" : ""}</span>
                            </div>
                            <div className="hidden xsm:flex items-center" style={{minWidth: "145px"}}>
                                <span className="text-[#00BFFF]"><WiWindy size={28}/></span>
                                <div className="flex whitespace-nowrap ml-1">
                                    {hour.windSpeed === undefined ? "Unknown" : `${hour.windDirection} ${convertUnit(unit, `${hour.windSpeed} mph`)}`}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flex" onClick={() => setOpen(!open)}>
                        <div className="flex items-center text-md text-gray-500 float-right">
                            <svg className="flex ml-2 mb-0.5 h-4 w-4" viewBox="0 0 24 24">
                                <OpenClose open={open}/>
                            </svg>
                        </div>
                    </div>
                </div>
            </div>
            {open && (
                <HourDropdown {...hour}/>
            )}
        </>
    )
}