import clsx from 'clsx';

export default function Checkbox({name, checked, onCheck, reverse = false, className}) {
    return (
        <div className={clsx("flex items-center", reverse && "flex-row-reverse", className)}>
            <input type="checkbox" id={name + "-cb"} name={name + "-cb"} className="opacity-0 absolute h-4.5 w-4.5" checked={checked} onChange={e => onCheck(e.target.checked)}/>
            <div className="bg-lwbg3 border-2 rounded-md border-[#00BFFF] w-4.5 h-4.5 flex flex-shrink-0 justify-center items-center focus-within:border-[#00BFFF]">
                <svg className="fill-current hidden w-2 h-2 text-[#00BFFF] pointer-events-none" version="1.1" viewBox="0 0 17 12" xmlns="http://www.w3.org/2000/svg">
                    <g fill="none" fillRule="evenodd">
                        <g transform="translate(-9 -11)" fill="#00BFFF" fillRule="nonzero">
                            <path d="m25.576 11.414c0.56558 0.55188 0.56558 1.4439 0 1.9961l-9.404 9.176c-0.28213 0.27529-0.65247 0.41385-1.0228 0.41385-0.37034 0-0.74068-0.13855-1.0228-0.41385l-4.7019-4.588c-0.56584-0.55188-0.56584-1.4442 0-1.9961 0.56558-0.55214 1.4798-0.55214 2.0456 0l3.679 3.5899 8.3812-8.1779c0.56558-0.55214 1.4798-0.55214 2.0456 0z"/>
                        </g>
                    </g>
                </svg>
            </div>
            <label htmlFor={name + "-cb"} className={clsx("text-gray-100 text-sm font-medium uppercase tracking-wide select-none", reverse ? "mr-2" : "ml-2")}>{name}</label>
        </div>
    )
}
