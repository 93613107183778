import { Line } from 'react-chartjs-2';

const options = (hex, hex1, white = false, unit) => ({
    legend: {
        display: false,
    },
    animation: {
        duration: 300,
        mode: 'point',
        intersect: false,
    },
    tooltips: {
        callbacks: {
            title: function(tooltipItem, data) {
                const d = data.datasets[0].data[tooltipItem[0].index];
                const options = { weekday: 'short', month: 'long', day: 'numeric', hour: 'numeric' };

                return `${new Date(Date.parse(d.x)).toLocaleDateString('en-US', options)} - ${new Date(Date.parse(d.x) + d.addition).toLocaleDateString('en-US', options)} (${d.addition / 3.6e+6} hour${d.addition / 3.6e+6 === 1 ? "" : "s"})`;
            },
            label: function(tooltipItem, data) {
                return `${tooltipItem.yLabel.toFixed(2)} ${unit === "F" ? "in" : "cm"}`;
            },
        },
        backgroundColor: '#0e1118',
        displayColors: false
    },
    elements: {
        point: {
            radius: 0,
            hitRadius: 5,
            backgroundColor: 'transparent',
        },
        line: {
            tension: 0.3,
            backgroundColor: `#${hex}`,
            borderColor: `#${hex1}`,
        },
    },
    scales: {
        xAxes: [ {
            type: 'time',
            time: {
                unit: "hour",
                tooltipFormat: 'll',
                distribution: 'linear',
                displayFormats: {
                    hour: "ddd hA  "
                }
            },
            ticks: {
                display: true,
                source: 'auto',
                fontColor: white ? 'white' : 'darkgray',
            },
            gridLines: {
                display: false,
            }
        } ],
        yAxes: [ {
            scaleLabel: {
                display: true,
                labelString: `Units (${unit === "F" ? "in" : "cm"}.)`,
                fontColor: white ? 'white' : 'darkgray',
                fontSize: 12,
                fontFamily: '"IBM Plex Mono", monospace',
            },
            gridLines: {
                drawTicks: false,
                color: white ? 'rgba(229,232,235,0.2)' : 'rgba(229,232,235,0.03)',
                zeroLineWidth: 3,
            },
            ticks: {
                fontSize: 10,
                fontFamily: '"IBM Plex Mono", monospace',
                fontColor: white ? 'white' : 'darkgray',
                beginAtZero: true,
                maxTicksLimit: 5,
                padding: 10
            }
        } ],
    },
});

const data = (d, unit) => ({
    datasets: [
        {
            fill: true,
            data: d.map((item) => {
                return {
                    x: item.x,
                    y: unit === "F" ? item.y : item.y * 2.54,
                    addition: item.addition
                }
            })
        },
    ],
});

export default function PrecipitationChart(props) {
    return (
        <div className="pt-5 -mr-2.5">
            <Line redraw={true} data={data(props.data, props.unit)} options={options(props.hex, props.hex1, props.white, props.unit)}
                  height={window.innerWidth > 600 ? 65 : 350}/>
        </div>
    )
}