import { useState } from 'react';
import { NavLink, useParams } from 'react-router-dom';

import { pickTextColorBasedOnBgColorSimple } from '../../../utils/IconUtil';
import { useHash, useUrl } from '../../../utils/LocationUtil';

import Button from '../../interactive/Button';
import Card from '../../ui/Card';
import clsx from 'clsx';

import Moment from '../../../utils/TimeUtil';

const days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

export const TYPE_DESC = {
    "tornado": "Probability of a tornado within 25 miles of a point.",
    "hail": "Probability of one inch diameter hail or larger within 25 miles of a point.",
    "wind": "Probability of damaging thunderstorm winds or wind gusts of 50 knots or higher within 25 miles of a point.",
    "probabilistic": "Probability of severe thunderstorms within 25 miles of a point."
}

export const TYPE_SIG = {
    "tornado": "Hatched Area: 10% or greater probability of a EF2 - EF5 tornadoes within 25 miles of a point.",
    "hail": "Hatched Area: 10% or greater probability of two inch diameter hail or larger within 25 miles of a point.",
    "wind": "Hatched Area: 10% or greater probability of wind gusts 65 knots or greater within 25 miles of a point.",
    "probabilistic": "Hatched Area: 10% or greater probability of significant severe weather within 25 miles of a point."
}

export default function Outlook({outlooks, ll}) {
    const url = useUrl();
    const offset = useHash();

    const [day, setDay] = useState(getFirst(outlooks));
    const [show, setShow] = useState("");
    const [sig, setSig] = useState(false);

    const t = ["tornado", "hail", "wind", "probabilistic"];
    let types = [];

    for (let i = 0; i < t.length; i++) {
        const type = t[i];
        const d = outlooks[day];

        if(d !== undefined) {
            const outlook = d[type];

            if (outlook !== undefined && outlook.length > 0) {
                if (outlook.some(o => o.label.includes("Significant"))) {
                    const withoutSignificant = outlook.filter(o => !o.label.includes("Significant"));

                    types.push({type: type, ...outlook.filter(o => o.label.includes("Significant"))[0]});
                    types.push({type: type, ...withoutSignificant[withoutSignificant.length - 1]});
                } else {
                    types.push({type: type, ...outlook[outlook.length - 1]});
                }
            }
        }
    }

    types = types.map(type => types.some(t => t.label.includes(`Significant ${type.label.split("% ")[1]}`)) ? {...type, label: `Sig ${type.label}`} : type);

    types.sort((a, b) => {
        if(a.label.includes("Sig") && b.label.includes("Sig")) {
            return 1;
        } else if(a.label.includes("Sig") && !b.label.includes("Sig")) {
            return -1;
        } else {
            return 0;
        }
    });

    return outlooks === undefined || outlooks === null || (outlooks["1"] === undefined && outlooks["2"] === undefined && outlooks["3"] === undefined && outlooks["4"] === undefined && outlooks["5"] === undefined && outlooks["6"] === undefined && outlooks["7"] === undefined && outlooks["8"]) || outlooks[day] === undefined ? null : (
        <Card className="px-4">
            <div className="flex items-center justify-between">
                <div className="font-semibold">
                    <span className="text-sm font-semibold tracking-wider text-gray-700 text-white uppercase">SPC Outlook</span>
                    <div className="text-xs font-medium">
                        <Moment time={false} unix={outlooks[day].issued}/>
                    </div>
                </div>
                <div>
                    <div className="rounded-full px-3 py-1 text-sm text-white font-medium" style={{color: pickTextColorBasedOnBgColorSimple(outlooks[day].fill, '#FFF', '#000'), backgroundColor: outlooks[day].fill}}>
                        {outlooks[day].category.name}
                    </div>
                </div>
            </div>
            <div className="text-xs-md pt-1.5 dark:text-gray-300">
                <div className="text-sm text-white font-medium">{outlooks[day].category.description}</div>
                {outlooks[day].category.descriptionMore || ""}
            </div>

            <div className="text-xs mt-1.5 font-medium">
                {types.filter(t => !t.label.includes("Significant ")).map((type, i) => (
                    <div key={i} style={{color: type.stroke === "#000000" ? "#999" : type.stroke}} className="flex items-center justify-between">
                        <div>
                            <span className="text-gray-600">-</span> <NavLink to={`${url}/map?day=${day}&outlook=${type.type}${offset === "" ? "" : `#${offset}`}`}>{type.label.includes("Sig") ? type.label.replace("Sig ", "").replace("%", "%#") : type.label}</NavLink>
                        </div>
                        <div>
                            <button onClick={() => {
                                if(show === type.type && (type.label.includes("Sig") === sig)) {
                                    setShow("");
                                    setSig(false);
                                } else {
                                    setShow(type.type);
                                    setSig(type.label.includes("Sig"))
                                }
                            }} className={clsx("cursor-pointer text-xs hover:underline", show === type.type && type.label.includes("Significant") === sig && "underline")}>
                                more info
                            </button>
                        </div>
                    </div>
                ))}
                {show !== "" && types.some(type => type.type === show) && (
                    <div className="text-gray-200 text-xs mt-1.5">
                        <div className="mt-2.5 pt-2.5 border-t border-lwbg3">
                            {TYPE_DESC[show]}
                        </div>
                        {sig && (
                            <div className="mt-1">
                                {TYPE_SIG[show]}
                            </div>
                        )}
                    </div>
                )}
                {types.length > 0 && (
                    <div className="mt-1.5">
                        <a className="text-xs text-sky-400 hover:underline" target="_blank" rel="noreferrer" href="https://www.spc.noaa.gov/misc/about.html#Convective">{show ? "Still want more info?" : "More info"}</a>
                    </div>
                    )}
            </div>
            <div className="border-t border-lwbg3 pt-2.5 mt-2.5">
                <div className="flex text-md justify-evenly items-center">
                    {[...Array(8)].map((_, i) => i + 1).filter(n => outlooks[`${n}`] !== undefined).map((n, i) => <Button key={i} active={day === n} name={`Day ${n}`} title={outlooks[`${n}`] === undefined ? "" : days[new Date(outlooks[`${n}`].valid).getUTCDay()]} className={day === n ? "border-lwbg3" : ""} func={() => setDay(n)}/>)}
                </div>
            </div>
        </Card>
    )
}

function getFirst(outlooks) {
    if(outlooks === undefined) {
        return -1;
    } else if(outlooks["1"] !== undefined) {
        return 1;
    } else if(outlooks["2"] !== undefined) {
        return 2;
    } else if(outlooks["3"] !== undefined) {
        return 3;
    } else if(outlooks["4"] !== undefined) {
        return 4;
    } else if(outlooks["5"] !== undefined) {
        return 5;
    } else if(outlooks["6"] !== undefined) {
        return 6;
    } else if(outlooks["7"] !== undefined) {
        return 7;
    } else if(outlooks["8"] !== undefined) {
        return 8;
    }

    return undefined;
}