import { Line } from 'react-chartjs-2';
import { useState } from 'react';
import { useUnit } from '../../utils/ConversionUtil';

import Card from '../../components/ui/Card';
import Checkbox from '../../components/ui/Checkbox';
import clsx from 'clsx';

const WEATHER_CODE = {
    "0": "Clear Sky",
    "1": "Mainly Clear",
    "2": "Partly Cloudy",
    "3": "Overcast",
    "45" : "Fog",
    "48": "Depositing Rime Fog",
    "51": "Light Drizzle",
    "53": "Drizzle",
    "55": "Heavy Drizzle",
    "56": "Light Freezing Drizzle",
    "57": "Freezing Drizzle",
    "61": "Light Rain",
    "62": "Rain",
    "63": "Heavy Rain",
    "66": "Light Freezing Rain",
    "67": "Freezing Rain",
    "71": "Light Snow",
    "72": "Snow",
    "73": "Heavy Snow",
    "77": "Snow Grains",
    "80": "Slight Rain Showers",
    "81": "Rain Showers",
    "82": "Heavy Rain Showers",
    "85": "Light Snow Showers",
    "86": "Snow Showers",
    "95": "Thunderstorms",
    "96": "Thunderstorms (Hail)",
    "97": "Thunderstorms (Hail)",
}

const options = (hex, hex1, unit, unit_name, type_time = "hour", data_point) => ({
    legend: {
        display: false,
    },
    animation: {
        duration: 300,
        mode: 'point',
        intersect: false,
    },
    tooltips: {
        callbacks: {
            title: function(tooltipItem, data) {
                const d = data.datasets[0].data[tooltipItem[0].index];
                const options = type_time === "hour" ? { weekday: 'short', month: 'long', day: 'numeric', hour: 'numeric' } : { weekday: 'short', month: 'long', day: 'numeric' };

                return `${new Date(d.x).toLocaleDateString('en-US', options)}`;
            },
            label: function(tooltipItem, data) {
                if(data_point === "weathercode")
                    return `${WEATHER_CODE[tooltipItem.yLabel]} (${tooltipItem.yLabel.toFixed(0)})`;
                else return `${tooltipItem.yLabel.toFixed(2)} ${unit}`;
            },
        },
        backgroundColor: '#0e1118',
        displayColors: false
    },
    elements: {
        point: {
            radius: 0,
            hitRadius: 5,
            backgroundColor: 'transparent',
        },
        line: {
            tension: 0.3,
            backgroundColor: `#${hex}`,
            borderColor: `#${hex1}`,
        },
    },
    scales: {
        xAxes: [ {
            type: 'time',
            time: {
                unit: type_time,
                tooltipFormat: 'll',
                distribution: 'linear',
                displayFormats: {
                    hour: "ddd hA"
                }
            },
            ticks: {
                display: true,
                source: 'auto',
                fontColor: 'darkgray',
            },
            gridLines: {
                display: false,
            },
        } ],
        yAxes: [ {
            scaleLabel: {
                display: true,
                labelString: `${unit_name} (${unit})`,
                fontColor: 'darkgray',
                fontSize: 12,
                fontFamily: '"IBM Plex Mono", monospace',
            },
            gridLines: {
                drawTicks: false,
                color: 'rgb(229,232,235,0.03)',
                zeroLineWidth: 3,
            },
            ticks: {
                fontSize: 10,
                fontFamily: '"IBM Plex Mono", monospace',
                fontColor: 'darkgray',
                beginAtZero: true,
                maxTicksLimit: 5,
                padding: 10
            }
        } ],
    },
});

const d = (time, data, unit, convert) => ({
    datasets: [
        {
            fill: true,
            data: data.filter(d => d !== null).map((item, i) => {
                return {
                    x: time[i] * 1000,
                    y: unit === "F" || !convert ? item : item / 2.54
                }
            })
        },
    ],
});

const UNIT_TYPES = {
    'temperature_2m': ['Temperature', '{U}°', true],
    'temperature_2m_max': ['Max Temperature', '{U}°', true],
    'temperature_2m_min': ['Min Temperature', '{U}°', true],
    'weathercode': ['Weather Code', 'Code', false],
    'precipitation_probability': ['Precip. Chance', '%', false],
    'precipitation_probability_max': ['Max Precip. Chance', '%', false]
}

function Graph({name, data, data_point, time_type = "hour"}) {
    const unit = useUnit();

    const time = time_type === "hour" ? "hourly" : "daily";
    const keys = Object.keys(data.graphs[`${time}_units`]).filter(key => {
        if(data.graphs[time][key] === undefined)
            return key;
        return data?.graphs[time][key] !== undefined && key !== "time" && key.startsWith(data_point) && data.graphs[time][key].filter(m => m !== null).length > 0
    });
    const [model, setModel] = useState(keys[0]);

    return (
        <Card>
            <div className="text-white text-md font-semibold uppercase tracking-wide text-center">{name.toUpperCase()}</div>
            <div className="font-medium text-xs text-gray-300 text-center mb-3">{data.graphs[time][model].filter(m => m !== null).length} {time_type}s</div>
            <Line redraw={true} data={d(data.graphs[time].time, data.graphs[time][model], unit, UNIT_TYPES[data_point][2])} options={options("104F69", "49a2d5", UNIT_TYPES[data_point][1].replaceAll("{U}", unit), UNIT_TYPES[data_point][0].replaceAll("{U}", unit), time_type, data_point)}
                  height={window.innerWidth > 600 ? 65 : 350}/>
            <div className="flex justify-center mt-2 border-t border-lwbg3 pt-2">
                <div className={clsx("grid", keys.length === 2 ? "grid-cols-2" : "grid-cols-3")}>
                    {keys.sort().map((key, i) => (
                        <Checkbox key={i} name={key.replaceAll(data_point + "_", "").replace("_seamless", "").replace("_global", "").replace("gfs_hrrr", "hrrr")} checked={key === model} onCheck={() => setModel(key)} className={clsx(keys.length === 1 && "col-span-3", "!mt-1 mr-2 col")}/>
                    ))}
                </div>
            </div>
        </Card>
    )
}

export default function Graphs({data, loading, error}) {
    return (
        <div className="space-y-2.5">
            <Graph name="Temperature" data={data} data_point="temperature_2m"/>
            <Graph name="Max Temperature" data={data} data_point="temperature_2m_max" time_type="day"/>
            <Graph name="Min Temperature" data={data} data_point="temperature_2m_min" time_type="day"/>
            <Graph name="Hourly Weather Code" data={data} data_point="weathercode"/>
            <Graph name="Daily Weather Code" data={data} data_point="weathercode" time_type="day"/>
            <Graph name="Precipitation Probability" data={data} data_point="precipitation_probability"/>
            <Graph name="Max Precipitation Probability" data={data} data_point="precipitation_probability_max" time_type="day"/>
        </div>
    )
}