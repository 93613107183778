import { convertUnit, useUnit } from '../../utils/ConversionUtil';
import { IconText } from '../../components/ui/Icons';

import PrecipitationChart from '../../components/chart/PrecipitationChart';
import Card from '../../components/ui/Card';
import clsx from 'clsx';

import moment from 'moment';
import RMoment from 'react-moment';

export default function Precipitation({data, error, loading}) {
    const unit = useUnit();

    const rainEvents = data.events.filter(ev => ev.type === "rain");
    const snowEvents = data.events.filter(ev => ev.type === "snow");
    const iceEvents = data.events.filter(ev => ev.type === "ice");

    return (
        <div className="space-y-2.5">
            {rainEvents.map((ev, i) => <DailyPrecipEvent key={i} data={ev} name="Rain" unit={unit} graph={data.hourly.rain.graph}/>)}
            {snowEvents.map((ev, i) => <DailyPrecipEvent key={i} data={ev} name="Snow" unit={unit} graph={data.hourly.snow.graph}/>)}
            {data.hourly.snow.events.filter(ev => !snowEvents.some(se => se.amount.exact.toFixed(1) === ev.totalInches.toFixed(1))).map((ev, i) => <HourlyPrecipEvent key={i} data={ev} name="Snow" unit={unit} graph={data.hourly.snow.graph}/>)}
            {(snowEvents.length === 0 || data.hourly.rain.events.length !== snowEvents.length) && <PrecipHourly name="Rain" data={data.hourly.rain} unit={unit}/>}
            {(snowEvents.length === 0 || data.hourly.snow.events.length !== snowEvents.length) && <PrecipHourly name="Snow" data={data.hourly.snow} unit={unit}/>}
            {iceEvents.length === 0 && <PrecipHourly name="Ice" data={data.hourly.ice} unit={unit}/>}
        </div>
    )
}

function HourlyPrecipEvent({name, data, unit, graph, round = false}) {
    const started = data.started
    const totalInches = data.totalInches;

    let phrase;

    if(totalInches >= 0.7 && totalInches <= 1.4) {
        phrase = "Around an inch";
    } else if(totalInches > 1.4) {
        phrase = "";
    } else if(totalInches < 0.7 && totalInches > 0.3) {
        phrase = "Less than an inch";
    } else {
        phrase = "Less than half an inch";
    }

    let start = 0;
    let end = graph.length - 1;

    for (let j = 0; j < graph.length; j++) {
        let time = new Date(Date.parse(graph[j].x)).getTime();

        if(time < (data.startPeriod)) {
            start = Math.max(0, j - 1);
        }
        if(time <= (data.endPeriod)) {
            end = Math.min(graph.length - 1, j + 1);
        }
    }

    graph = graph.slice(start + 1, end);

    return (
        <Card background={started ? "bg-[#3492cc]" : undefined} title={started ? `Additional ${name}` : `Upcoming ${name}`}>
            <div className="px-3.5 font-medium text-center text-gray-300 text-sm">
                {phrase !== "" && (
                    <div className={clsx("flex items-center justify-center space-x-4 py-5 text-white text-[2.5rem]")}>
                        <IconText size={50} text={`heavy ${name}${started ? " white" : ""}`}/>
                        <div>{convertUnit(unit, phrase)}</div>
                    </div>
                )}
                <div className={clsx(started ? "text-white font-semibold" : "", phrase === "" && "mt-6 mb-1")}>{name}</div>
                <div className="mx-auto w-fit pt-1 text-xs">
                    <div className={clsx("text-[0.65rem] px-2 tracking-tigther font-bold rounded-full w-auto p-0.5", started ? "bg-white text-[#00BFFF] mt-1" : "bg-[#00BFFF] text-white")}>
                        {started ? "STARTED" : "UPCOMING"}
                    </div>
                </div>
                <div className={clsx("text-sm font-medium mt-1", started ? "text-white" : "")}>
                    {totalInches !== -1 && <>{unit === "F" ? `${Math.round(totalInches * 100) / 100} inch${totalInches === 1 ? "" : "es"}`: `${Math.round(totalInches * 100 * 2.54) / 100} cm`}</>}
                </div>
                {graph.length > 0 && <PrecipitationChart unit={unit} white={started} name={name} total={totalInches} data={graph} hex={started ? "" : "104F69"} hex1={started ? "" : "49a2d5"}/>}
                {started ? (
                    <div className="text-white pt-4">
                        <div className="text-center">
                            <div className="text-xs font-bold tracking-tight">END</div>
                            <div className="text-lg font-semibold">
                                <RMoment unix format="hh:mm A">{data.endPeriod / 1000}</RMoment>
                            </div>
                            <div className="text-xs-md font-normal">
                                <RMoment unix format="dddd, MM/DD">{data.endPeriod / 1000}</RMoment>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className="flex items-center justify-between pt-6">
                        <div className="text-left">
                            <div className="text-xs font-semibold tracking-wider">START</div>
                            <div className="text-lg font-semibold">
                                <RMoment unix format="hh:mm A">{data.startPeriod / 1000}</RMoment>
                            </div>
                            <div className="text-xs-md font-normal">
                                <RMoment unix format="dddd, MM/DD">{data.startPeriod / 1000}</RMoment>
                            </div>
                        </div>
                        <div className="text-right">
                            <div className="text-xs font-semibold tracking-wider">END</div>
                            <div className="text-lg font-semibold">
                                <RMoment unix format="hh:mm A">{data.endPeriod / 1000}</RMoment>
                            </div>
                            <div className="text-xs-md font-normal">
                                <RMoment unix format="dddd, MM/DD">{data.endPeriod / 1000}</RMoment>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </Card>
    )
}
function DailyPrecipEvent({name, data, unit, graph, round = false}) {
    const started = data?.graph?.started || data.startTime < moment().unix();
    const totalInches = data.amount.exact;

    let start = 0;
    let end = graph.length - 1;

    for (let j = 0; j < graph.length; j++) {
        let time = new Date(Date.parse(graph[j].x)).getTime();

        if(time < (data.graph.startTime)) {
            start = Math.max(0, j - 1);
        }
        if(time <= (data.graph.endTime)) {
            end = Math.min(graph.length - 1, j + 1);
        }
    }

    graph = graph.slice(start + 1, end);

    return (
        <Card background={started ? "bg-[#3492cc]" : undefined} title={started ? `Additional ${name}` : `Upcoming ${name}`}>
            <div className="px-3.5 font-medium text-center text-gray-300 text-sm">
                <div className={clsx("flex items-center justify-center space-x-4 py-5 text-white", data.amount.min !== data.amount.max ? (data.amount.min === 0 ? "text-[2.5rem]" : "text-6xl") : "text-5xl")}>
                    <IconText size={data.amount.min === 0 ? 45 : (data.amount.min !== data.amount.max ? 50 : 60)} text={`heavy ${name}${started ? " white" : ""}`}/>
                    {data.amount.min === 0 && data.amount.max !== 0 && <div>{convertUnit(unit, 'Less than half an inch.')}</div>}
                    {data.amount.min !== 0 && data.amount.min !== data.amount.max && <div>{convertUnit(unit, `${round ? Math.round(data.amount.min) : data.amount.min} - ${round ? Math.round(data.amount.max) : data.amount.max} in`, name !== "Rain")}</div>}
                    {data.amount.min === data.amount.max && <div>{data.amount.min === 1 ? (unit === "F" ? "Around an inch" : "Around 2.5 cm") : convertUnit(unit, `${round ? Math.round(data.amount.min) : data.amount.min} in`)}</div>}
                </div>
                <div className={clsx(started ? "text-white font-semibold" : "")}>{name}</div>
                <div className="mx-auto w-fit pt-1 text-xs">
                    <div className={clsx("text-[0.65rem] px-2 tracking-tigther font-bold rounded-full w-auto p-0.5", started ? "bg-white text-[#00BFFF] mt-1" : "bg-[#00BFFF] text-white")}>
                        {started ? "STARTED" : "UPCOMING"}
                    </div>
                </div>
                <div className={clsx("text-sm font-medium mt-1", started ? "text-white" : "")}>
                    {totalInches !== -1 && <>{unit === "F" ? `${Math.round(totalInches * 100) / 100} inch${totalInches === 1 ? "" : "es"}`: `${Math.round(totalInches * 100 * 2.54) / 100} cm`}</>}
                </div>
                {graph.length > 0 && <PrecipitationChart unit={unit} white={started} name={name} total={totalInches} data={graph} hex={started ? "" : "104F69"} hex1={started ? "" : "49a2d5"}/>}
                {started ? (
                    <div className="text-white pt-4">
                        <div className="text-center">
                            <div className="text-xs font-bold tracking-tight">END</div>
                            <div className="text-lg font-semibold">
                                <RMoment unix format="hh:mm A">{data.graph.endTime / 1000}</RMoment>
                            </div>
                            <div className="text-xs-md font-normal">
                                <RMoment unix format="dddd, MM/DD">{data.graph.endTime / 1000}</RMoment>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className="flex items-center justify-between pt-6">
                        <div className="text-left">
                            <div className="text-xs font-semibold tracking-wider">START</div>
                            <div className="text-lg font-semibold">
                                <RMoment unix format="hh:mm A">{data.graph.startTime / 1000}</RMoment>
                            </div>
                            <div className="text-xs-md font-normal">
                                <RMoment unix format="dddd, MM/DD">{data.graph.startTime / 1000}</RMoment>
                            </div>
                        </div>
                        <div className="text-right">
                            <div className="text-xs font-semibold tracking-wider">END</div>
                            <div className="text-lg font-semibold">
                                <RMoment unix format="hh:mm A">{data.graph.endTime / 1000}</RMoment>
                            </div>
                            <div className="text-xs-md font-normal">
                                <RMoment unix format="dddd, MM/DD">{data.graph.endTime / 1000}</RMoment>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </Card>
    )
}

function PrecipHourly({name, data, unit}) {
    const totalInches = data.amount.inches;
    const started = false;

    return totalInches === 0 ? null : (
        <Card>
            <div className="px-3.5 font-medium text-center text-gray-300 text-sm">
                <div className="text-white text-md font-semibold uppercase tracking-wide">{name}</div>
                <div className="text-sm font-medium">
                    {totalInches !== -1 && <>{unit === "F" ? `${Math.round(totalInches * 100) / 100} inch${totalInches === 1 ? "" : "es"}`: `${Math.round(totalInches * 100 * 2.54) / 100} cm`}</>}
                </div>
                {totalInches !== -1 && <PrecipitationChart unit={unit} white={false} name="Rain" total={totalInches} data={data.graph} hex={started ? "" : "104F69"} hex1={started ? "" : "49a2d5"}/>}
            </div>
        </Card>
    )
}