import { Helmet as RHelmet } from 'react-helmet';

export function Helmet({title, color = undefined}) {
    return (
        <RHelmet>
            <title>{title}</title>
            {color !== undefined && <meta name="theme-color" content={color} />}
        </RHelmet>
    )
}

export function HelmetLoc({title, location, color = undefined}) {
    return (
        <RHelmet>
            <title>{title} {location !== undefined ? `- ${location.name} ` : ""}| LetsWeather</title>
            {color !== undefined && <meta name="theme-color" content={color} />}
        </RHelmet>
    )
}