import Card from '../ui/Card';
import clsx from 'clsx';

import Moment from '../../utils/TimeUtil';

export default function PageHeader({title, data, emptyMessage = false}) {
    if(emptyMessage === undefined)
        return null;

    return (
        <>
            <Card border={emptyMessage !== false} className={clsx(emptyMessage !== false ? "px-3 !py-2 " : "!bg-transparent -mb-2.5")}>
                <div className={clsx(emptyMessage !== false ? "justify-center -mb-1.5 mt-1" : "justify-between", "flex items-center")}>
                    <div className="text-md uppercase font-semibold tracking-wide">{title || "Unknown Page"}</div>
                    {data.updated !== undefined && (
                        <div className={clsx(emptyMessage !== false && "ml-1", "text-xs uppercase font-medium text-gray-300")}>
                            {emptyMessage !== false && "- "}<Moment time={true} unix={data.updated}/>
                        </div>
                    )}
                </div>
                {emptyMessage !== false && <div className="text-sm font-medium text-gray-300 text-center my-1.5">{emptyMessage}</div>}
            </Card>
            {/*<div className="text-sm text-center">*/}
            {/*    <div className="text-md uppercase font-semibold tracking-wide -mb-0.5">{title || "Unknown Page"}</div>*/}
            {/*    <Moment time={true} unix={data.updated}/>*/}
            {/*</div>*/}
        </>
    )
}