import { useEffect, useState } from 'react';

export function temperature(unit, temperature: number, showUnit: boolean = true): string {
    switch (unit) {
        case "F":
            return `${Math.round(temperature)}${showUnit ? "°F" : ""}`;
        default:
            return `${Math.round((temperature - 32) * (5/9))}${showUnit ? "°C" : ""}`;
    }
}

export function wind(unit, wind: number, showUnit: boolean = true, decimals = 0): string {
    switch (unit) {
        case "F":
            return `${Math.round(wind)}${showUnit ? " mph" : ""}`;
        default:
            return `${(wind * 1.609344).toFixed(decimals)}${showUnit ? " km/h" : ""}`;
    }
}

export function precipitation(unit, amount: number, showUnit: boolean = true, round = true): string {
    switch (unit) {
        case "F":
            return `${round ? Math.round(amount) : amount.toFixed(2)}${showUnit ? " in" : ""}`;
        default:
            return `${round ? Math.round(amount * 2.54) : (amount * 2.54).toFixed(2)}${showUnit ? " cm" : ""}`;
    }
}

export function precipitationFeet(unit, amount: number, showUnit: boolean = true): string {
    switch (unit) {
        case "F":
            return `${Math.round(amount)}${showUnit ? " feet" : ""}`;
        default:
            return `${Math.round(amount * 0.3048)}${showUnit ? " meters" : ""}`;
    }
}

export function getUnit() {
    switch (localStorage.getItem("unit") || "F") {
        case "F":
            return "F";
        default:
            return "C";
    }
}

export function setUnit(unit: string) {
    if (unit === "F" || unit === "C") {
        localStorage.setItem("unit", unit);
        document.dispatchEvent(new Event('unitChange'));
    }
}


export function useUnit() {
    const [unit, setUnit] = useState(getUnit());

    useEffect(() => {
        document.addEventListener('unitChange', onUnitChange);

        return () => document.removeEventListener('unitChange', onUnitChange);
    }, [unit]);

    function onUnitChange() {
        setUnit(getUnit());
    }

    return unit;
}

export function useTemperature(temperature: number, showUnit: boolean = true) {
    const unit = useUnit();

    return temperature(unit, temperature, showUnit);
}

export function convertUnit(unit, forecast, round = true) {
    if(unit === "F")
        return forecast;

    const phrases = [
        'Temperatures as low as [T1]-[T2]', 'Low around [T]', 'High near [T]', 'with a high near [T]', 'with a low around [T]', 'with temperatures falling to around [T]', 'with temperatures rising to around [T]', 'values as low as [T]', // Temp
        '[W1] to [W2] mph', '[W1] mph', 'Winds will gust to [W1]', // Wind
        'snow accumulation of [S1] to [S2] inches possible', '[S1] - [S2] in', `[S1] in`, '[S1] to [S2] inches', 'near [S1] inches', 'snow accumulations between [S1] and [S2] inches', 'up to [S1] inches', '[F1] to [F2] feet', // Snow
        '[U] mi', // Visibility
        '[F] feet' // Height
    ];

    forecast = forecast.replaceAll('New snow accumulation of less than one inch possible.', 'New snow accumulation of less than 2 cm possible.').replaceAll('New snow accumulation of less than half an inch possible.', 'New snow accumulation of less than 1 cm possible.').replaceAll('New snow accumulation of around one inch possible.', 'New snow accumulation of around 2.5 cm possible.');
    forecast = forecast.replaceAll('A foot or more', '30 cm or more');
    forecast = forecast.replaceAll('Less than half an inch', 'Less than 1 cm');
    forecast = forecast.replaceAll('Less than an inch', 'Less than 2.5 cm');
    forecast = forecast.replaceAll('Around an inch', 'Around 2.5 cm');

    for (let i = 0; i < phrases.length; i++) {
        const phrase = phrases[i];

        const regex = new RegExp(phrase.replace(/(\[T]|\[T1]|\[T2]|\[W1]|\[W2]|\[S1]|\[S2]|\[U]|\[F]|\[F1]|\[F2])/gm, '([\\d\\.,]*)'), "gm");
        let m;

        while ((m = regex.exec(forecast)) !== null) {
            // This is necessary to avoid infinite loops with zero-width matches
            if (m.index === regex.lastIndex)
                regex.lastIndex++;

            m = m.filter(m => m !== undefined && m !== "0."); // This randomly started to happen (the undefined values); idk why.

            if(phrase.includes("[T]")) {
                forecast = forecast.replaceAll(m[0], phrase.replace("[T]", temperature(unit, parseInt(m[1]), false)));
            } else if(phrase.includes("[T1]") || phrase.includes("[T2]")) {
                forecast = forecast.replaceAll(m[0], phrase.replace("[T1]", temperature(unit, parseInt(m[1]), false)).replace("[T2]", temperature(unit, parseInt(m[2]), false)));
            } else if(phrase.includes("[W1]") || phrase.includes("[W2]")) {
                forecast = forecast.replaceAll(m[0], phrase.replace('mph', 'km/h').replace("[W1]", wind(unit, parseInt(m[1]), false)).replace("[W2]", wind(unit, parseInt(m[2]), false)));
            } else if(phrase.includes("[S1]") || phrase.includes("[S2]")) {
                forecast = forecast.replaceAll(m[0], phrase.replace('inches', 'cm').replace('in', 'cm').replace("[S1]", precipitation(unit, parseFloat(m[1]), false, round)).replace("[S2]", precipitation(unit, parseFloat(m[2]), false, round)));
            } else if(phrase.includes("[U]")) {
                forecast = forecast.replaceAll(m[0], phrase.replace('mi', 'km').replace("[U]", wind(unit, parseFloat(m[1]), false, 2)));
            } else if(phrase.includes("[F1]") || phrase.includes("[F2]")) {
                forecast = forecast.replaceAll(m[0], phrase.replace('feet', 'cm').replace("[F1]", precipitation(unit, parseFloat(m[1].replaceAll(",", "") * 12), false)).replace("[F2]", precipitation(unit, parseFloat(m[2].replaceAll(",", "") * 12), false)));
            } else if(phrase.includes("[F]")) {
                forecast = forecast.replaceAll(m[0], phrase.replace('feet', `meter${precipitationFeet(unit, parseFloat(m[1]), false) !== "1" ? "s" : ""}`).replace("[F]", precipitationFeet(unit, parseFloat(m[1]), false)));
            }
        }
    }

    return forecast;
}

export function getNumberWithOrdinal(n) {
    const s = ["th", "st", "nd", "rd"],
        v = n % 100;
    return n + (s[(v - 20) % 10] || s[v] || s[0]);
}