import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { LetsWeatherRoutes } from './LetsWeatherRouters';
import { LocationContext, useApp } from './utils/LocationUtil';
import { PushContext } from './utils/PushUtil';

import Header from './nav/Header';
import Footer from './nav/Footer';

import clsx from 'clsx';

function LetsWeather(props) {
    const app = useApp();

    return (
        <LocationContext>
            <PushContext>
                <div className={clsx("font-inter antialiased flex transition-colors duration-500 flex-col text-gray-200 bg-lwbg min-h-screen")}>
                    <div className="flex-grow">
                        {!app && <Header/>}

                        <div className={!app ? "pt-[62px]" : ""}>
                            <LetsWeatherRoutes/>
                        </div>
                    </div>

                    {!app && <Footer {...props}/>}
                </div>
            </PushContext>
        </LocationContext>
    );
}

export default withRouter(LetsWeather);

function withRouter(Component) {
    function ComponentWithRouterProp(props) {
        let [loaded, setLoaded] = useState(-1);
        let location = useLocation();
        let navigate = useNavigate();
        let params = useParams();

        useEffect(() => {
            const perfObserver = new PerformanceObserver((observedEntries) => {
                const entry: PerformanceEntry =
                    observedEntries.getEntriesByType('navigation')[0]

                setLoaded(entry.duration);
            })

            perfObserver.observe({
                type: 'navigation',
                buffered: true
            })
        }, []);

        return (
            <Component
                {...props} {...{ loaded, location, navigate, params }}
            />
        );
    }

    return ComponentWithRouterProp;
}