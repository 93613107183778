import { convertUnit, temperature, useUnit } from '../../utils/ConversionUtil';
import { IconText, Loading, RightArrow } from '../../components/ui/Icons';
import { isNight } from '../../utils/IconUtil';

import Card from '../../components/ui/Card';

import Moment, { notEqualTOD } from '../../utils/TimeUtil';
import clsx from 'clsx';
import { capitalize } from '../../utils/WordUtil';
import { WiWindy } from 'weather-icons-react';
import { NavLink } from 'react-router-dom';
import { useUrl } from '../../utils/LocationUtil';

export default function Current({data, loading, error}) {
    let unit = useUnit();
    let url = useUrl();

    const snowEvents = data.precipitation.hasData ? data.precipitation.events.filter(e => e.type === "snow") : [];

    return (
        <>
            <Card className="!p-3.5">
                {data.time === undefined ? <Loading/> : (
                    <div className="flex justify-between">
                        <div>
                            <div className="text-white text-sm font-semibold tracking-wider">CURRENT WEATHER</div>
                            <div className="text-xs font-medium mt-0.5">
                                {data.time > 0 && <Moment time={true} unix={data.time}/>}
                            </div>
                            <div className="text-white font-medium text-[5.4rem] -mt-3 -mb-2">
                                {temperature(unit, data.temperature, false)}&deg;
                                <span className="text-gray-300 font-medium text-3xl -ml-7">{unit}</span>
                            </div>
                            <div className="text-lg font-semibold text-gray-100 tracking-wide">{data.text}</div>
                        </div>
                        <div className="my-auto space-y-6">
                            <IconText size={100} text={data.text || ""} night={isNight(data.sunset)}/>
                            <div className="text-xl text-white text-center font-bold">{data.high !== undefined ? `${data.high}°` : "--"}<span className="mx-0.5">/</span>{data.low !== undefined ? `${data.low}°` : "--"}</div>
                        </div>
                    </div>
                )}
            </Card>
            <Card className="!p-3.5 mt-2.5">
                <div className="text-sm font-semibold tracking-wider text-white uppercase">Weather Today in {data.name}</div>
                <div className="text-xs font-medium mt-0.5">
                    {data.time !== undefined && data.time > 0 && <Moment time={true} unix={data.time}/>}
                </div>
                <div className="grid grid-cols-1 lg:grid-cols-2 lg:gap-x-2 w-full pt-2 font-medium text-md text-white">
                    <CurrentlyItem number={1} name="High / Low" value={`${data.high === undefined || data.high === "--" ? "--" : temperature(unit, data.high, false)}° / ${data.low === undefined ? "--" : temperature(unit, data.low, false)}°`} icon={<svg className="text-gray-200 mt-1" fill="currentColor" height="18" width="24" viewBox="0 0 1086 2357"><path d="M0 1814q0-136 63-253.5T239 1364V306q0-128 88-217T543 0q129 0 217.5 89T849 306v1058q113 79 175 196.5t62 253.5q0 148-72.5 273T816 2284.5T543 2357q-147 0-272-72.5T73 2087T0 1814zm187 0q0 149 104.5 254.5T543 2174q149 0 256.5-107T907 1814q0-100-51.5-184.5T715 1498l-30-15q-16-7-16-31V306q0-52-36-86t-90-34q-52 0-88.5 34T418 306v1146q0 24-15 31l-29 15q-88 47-137.5 131T187 1814zm83 0q0 114 78 194t188 80t191-80.5t81-193.5q0-101-69.5-178T570 1545V762q0-10-10.5-20.5T536 731q-14 0-22.5 9t-8.5 22v783q-98 14-166.5 91T270 1814z"/></svg>}/>
                    <CurrentlyItem number={2} name="Wind" value={data.wind === undefined ? "--" : convertUnit(unit, `${data.windDirection} ${Math.round(data.wind)} mph${data.windGusts === 0 ? "" : ` (${Math.round(data.windGusts)} mph Gusts)`}`)} icon={<WiWindy className="text-gray-200" size={25}/>}/>
                    <CurrentlyItem number={3} name="Humidity" value={data.humidity === undefined ? "--" : `${temperature(unit, data.humidity, false)}%`} icon={<svg className="text-gray-200" fill="currentColor" viewBox="0 0 24 24"><path fillRule="evenodd" d="M11.743 17.912a4.182 4.182 0 0 1-2.928-1.182 3.972 3.972 0 0 1-.614-4.962.743.743 0 0 1 .646-.349c.234 0 .476.095.66.275l4.467 4.355c.385.376.39.998-.076 1.275a4.216 4.216 0 0 1-2.155.588M11.855 4c.316 0 .61.14.828.395.171.2.36.416.562.647 1.857 2.126 4.965 5.684 4.965 8.73 0 3.416-2.85 6.195-6.353 6.195-3.505 0-6.357-2.78-6.357-6.195 0-3.082 2.921-6.406 4.854-8.605.242-.275.47-.535.673-.772A1.08 1.08 0 0 1 11.855 4"/></svg>}/>
                    <CurrentlyItem number={4} name="Dewpoint" value={data.dewpoint === undefined ? "--" : `${temperature(unit, data.dewpoint, false)}°`} icon={<svg className="text-gray-200" fill="currentColor" viewBox="0 0 24 24"><path d="M17 8.1a2.8 2.8 0 1 1 0-5.6 2.8 2.8 0 0 1 0 5.6zm0-1.85a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"/><path fillRule="evenodd" d="M9.743 18.912a4.182 4.182 0 0 1-2.928-1.182 3.972 3.972 0 0 1-.614-4.962.743.743 0 0 1 .646-.349c.234 0 .476.095.66.275l4.467 4.355c.385.376.39.998-.076 1.275a4.216 4.216 0 0 1-2.155.588M9.855 5c.316 0 .61.14.828.395.171.2.36.416.562.647 1.857 2.126 4.965 5.684 4.965 8.73 0 3.416-2.85 6.195-6.353 6.195-3.505 0-6.357-2.78-6.357-6.195 0-3.082 2.921-6.406 4.854-8.605.242-.275.47-.535.673-.772C9.245 5.14 9.54 5 9.855 5"/></svg>}/>
                    <CurrentlyItem number={5} name="Visibility" value={data.visibility === undefined ? "--" : `${convertUnit(unit, `${data.visibility.toFixed(2).endsWith("00") ? data.visibility.toFixed() : data.visibility.toFixed(2)} mi`)}`} icon={<svg className="text-gray-200 w-4 h-5 mt-0.5 ml-1" fill="currentColor" viewBox="0 0 1024 1024"><path d="M491.856 879.808c-60.48-5.056-110.848-25.184-171.328-55.424-120.96-55.424-216.704-146.112-292.256-256.96-25.248-40.352-30.24-80.64 0-126.016 80.608-115.872 186.464-211.68 317.472-272.096 110.816-50.4 226.752-50.4 337.664 0 136 60.48 241.824 156.224 317.44 282.208 15.104 25.216 25.12 65.504 10.048 85.728-105.792 191.424-256.992 367.84-519.04 342.56zm292.256-377.92c0-151.168-120.96-272.064-272.096-272.064-146.144 0-272.128 126.016-272.128 272.064 0 151.232 120.96 277.216 272.128 277.216 151.104-.032 272.096-125.984 272.096-277.216z"/><path d="M789.808 500.416c0 156.896-125.472 287.52-282.336 282.336-156.864 0-282.336-130.656-282.336-287.488 0-146.4 130.656-277.12 282.336-277.12 156.896-.032 287.584 125.376 282.336 282.272zM512.752 348.832c-83.68 0-151.584 67.968-151.584 151.584 0 88.864 67.968 156.896 151.584 156.896 83.648 0 156.832-73.216 156.832-156.896-5.184-83.648-73.152-151.584-156.832-151.584z"/></svg>}/>
                    <CurrentlyItem number={6} name="Moon Phase" value={data.moonPhase === undefined ? "--" : capitalize(data.moonPhase.toLowerCase().replaceAll("_", " "))} icon={<svg className="text-gray-200" fill="currentColor" viewBox="0 0 24 24"><path fill="none" d="M12.099 20.19a8.095 8.095 0 1 0 0-16.19 8.095 8.095 0 0 0 0 16.19z"/><path d="M12.079 4.518c3.4.673 4.065 5.797 4.066 7.577 0 1.78-.665 6.759-4.066 7.542-4.462 0-8.079-3.07-8.079-7.542 0-4.47 3.617-7.577 8.079-7.577z"/></svg>}/>
                    <CurrentlyItem number={7} name={<span className="text-[#febc11]">Sunrise</span>} value={data.sunrise === undefined ? "--" : <div className="lowercase"><Moment unix={data.sunrise} time={true}/></div>} icon={<svg fill="#febc11" aria-hidden="true" viewBox="0 0 24 24"><path d="M10.862 6.052v5.329a.75.75 0 0 0 1.5 0V6.036l1.772 1.534a.75.75 0 0 0 .982-1.134l-3.003-2.601a.75.75 0 0 0-.982 0L8.128 6.436A.75.75 0 0 0 9.11 7.57l1.752-1.518zM21 19.128a.75.75 0 0 0 0-1.5H3.167a.75.75 0 1 0 0 1.5H21z"/></svg>}/>
                    <CurrentlyItem number={8} name={<span className="text-[#febc11]">Sunset</span>} value={data.sunset === undefined ? "--" : <div className="lowercase"><Moment unix={data.sunset} time={true}/></div>} icon={<svg fill="#febc11" aria-hidden="true" viewBox="0 0 24 24"><path d="M10.862 9.853L9.044 8.278a.75.75 0 1 0-.982 1.134l3.003 2.602a.75.75 0 0 0 .982 0l3.004-2.602a.75.75 0 0 0-.983-1.134l-1.706 1.478V4a.75.75 0 0 0-1.5 0v5.853zM21 19.075a.75.75 0 1 0 0-1.5H3.167a.75.75 0 1 0 0 1.5H21z"/></svg>}/>
                </div>
            </Card>
            {snowEvents.length > 0 && (
                <Card className="!p-3.5 mt-2.5">
                    <div className="text-sm font-semibold tracking-wider text-white uppercase">Precipitation</div>
                    <div className="font-medium text-sm text-gray-300 pt-1">
                        {notEqualTOD(snowEvents[0].graph.startTime, snowEvents[0].graph.endTime) ? (
                            <>
                                <Moment tod={true} unix={snowEvents[0].graph.startTime}/> - <Moment tod={true} unix={snowEvents[0].graph.endTime}/>
                            </>
                        ) : (
                            <>
                                <Moment tod={true} unix={snowEvents[0].graph.startTime}/>
                            </>
                        )}
                    </div>
                    <div className="flex items-center pt-3 pb-1">
                        <IconText size={45} text="heavy snow"/>
                        <div className="ml-3">
                            <div className="text-3xl text-white font-medium">
                                {snowEvents[0].amount.min === 0 ? convertUnit(unit, "Less than half an inch") : snowEvents[0].amount.min === snowEvents[0].amount.max ? (snowEvents[0].amount.min === 1 ? convertUnit(unit, "Around an inch") : convertUnit(unit, `${snowEvents[0].amount.min} in`)) : convertUnit(unit, (`${snowEvents[0].amount.min} - ${snowEvents[0].amount.max} in`))}
                            </div>
                            <div className="text-sm text-gray-200 tracking-wide">{snowEvents[0].started ? "Additional " : ""}Snow</div>
                        </div>
                    </div>
                    <NavLink to={`${url}/precipitation`} className="flex items-center justify-between pt-2.5">
                        <div>{snowEvents.length > 1 && <span className="font-medium text-white uppercase text-xs tracking-wider">{snowEvents.length - 1} Upcoming Storm{snowEvents.length - 1 === 1 ? "" : "s"}</span>}</div>
                        <div className="flex">
                            <div className="flex items-center space-x-1">
                                <div className="flex items-center text-md font-medium text-white">See More </div>
                                <RightArrow className="text-gray-400 h-3 w-3"/>
                            </div>
                        </div>
                    </NavLink>
                </Card>
            )}
        </>
    )
}

function CurrentlyItem({number, icon, name, value}) {
    return (
        <div className={clsx("grid-span-1 w-full divide-y divide-gray-200 py-2.5 lg:p-2", number === 1 ? "" : (number === 2 ? "border-t border-lwbg3 lg:border-none" : "border-t border-lwbg3"))}>
            <div className="flex items-center justify-between w-full">
                <div className="flex items-center">
                    <div className="w-5 h-5">
                        {icon}
                    </div>
                    <div className="font-semibold ml-2">
                        {name}
                    </div>
                </div>
                <div>
                    {value}
                </div>
            </div>
        </div>
    )
}
