import { useFetch } from '../utils/FetchUtil';
import { NavLink } from 'react-router-dom';

import { useEffect } from 'react';
import { useHash } from '../utils/LocationUtil';
import { HelmetLoc } from '../components/head/Helmet';
import { isNight } from '../utils/IconUtil';
import { IconText, Loading } from '../components/ui/Icons';
import { temperature, useUnit } from '../utils/ConversionUtil';

import clsx from 'clsx';

export default function Cameras() {
    let id = useHash();
    let unit = useUnit();

    const { data, error, loading, fetchNow } = useFetch();

    useEffect(() => {
        fetchNow(id !== "" ? `/api/camera/${id}` : '/api/cameras', {method: 'POST'});
    }, [id]);

    return (
        <div>
            <HelmetLoc title={`Camera${id !== "" ? `${data !== undefined && data.id !== undefined ? ` - ${data.name}` : ""}` : "s"}`}/>
            <div className="mx-auto max-w-d mt-2">
                <h1 className="text-white font-semibold text-3xl text-center lg:text-left px-2">
                    {loading ? "Cameras" : `${id !== "" ? `${data !== undefined && data.id !== undefined ? data.name : ""} Camera` : "Cameras"}`}
                    {loading && <Loading className="mt-2.5"/>}
                    {id !== "" && data !== undefined && data.id !== undefined && (
                        <div className="flex items-center space-x-1.5 -mt-1.5 justify-center lg:justify-start">
                            <div className="text-gray-300 text-[17px]">
                                {data.location !== undefined && (
                                    <NavLink to={`/en/${data.location.country_code.toLowerCase()}/${data.location.name.toLowerCase().replaceAll(" ", "-")}/${data.location.zip_codes[0]}`}>
                                        <div className="flex items-center space-x-2 font-semibold lg:font-medium text-md w-full">
                                            <div>
                                                {data.location.name}, {data.location.state_code}
                                            </div>
                                            <div>
                                                {temperature(unit, data.current.temperature)}
                                            </div>
                                            <div>
                                                <IconText size={20} text={data.current.text} night={isNight()}/>
                                            </div>
                                        </div>
                                    </NavLink>
                                )}
                            </div>
                        </div>
                    )}
                </h1>
                {error && <p className="text-red-400 text-md">{error.message || error.error}</p>}
                {!error && data && (
                    <>
                        {Array.isArray(data) ? (
                            <div className="px-2.5 xl:px-0 grid xs:grid-cols-2 lg:grid-cols-3 mt-2 gap-3.5">
                                {data.map(camera => (
                                    <div key={camera.id}>
                                        <NavLink to={`/cameras#${camera.id}`}>
                                            <div className="rounded-md overflow-hidden">
                                                <img src={camera.thumbnail} alt={camera.name} className="w-full h-full"/>
                                            </div>
                                            <div className="flex items-center justify-between mt-1">
                                                <div>
                                                    <div className="text-white font-semibold textmd">
                                                        {camera.name}
                                                    </div>
                                                    <div className="text-gray-300 text-sm -mt-1">
                                                        {camera.location.name}, {camera.location.state_code}
                                                    </div>
                                                </div>
                                                <div className="flex items-center space-x-1.5">
                                                    <div>{temperature(unit, camera.current.temperature)}</div>
                                                    <IconText size={20} text={camera.current.text} night={isNight(camera.current.sunset)}/>
                                                </div>
                                            </div>
                                        </NavLink>
                                    </div>
                                ))}
                            </div>
                        ) : (
                            <div className={clsx(data.url.includes("frame") ? " overflow-hidden" : "")}>
                                {data.url.includes("frame") ? <iframe title={data.name} src={data.url} className="h-[690px] w-full" allowFullScreen={true} scrolling="no" frameBorder={0}/> : <video src={data.url} autoPlay={true} muted={true} loop={true} className="w-full h-full rounded-lg shadow-lg mt-5"/>}
                            </div>
                        )}
                    </>
                )}
            </div>
        </div>
    );
}