import { useEffect } from 'react';
import { useLetsWeatherContext } from '../utils/LocationUtil';

import { Helmet } from '../components/head/Helmet';
import { Map } from '../components/map/Map';

export default function Radar() {
    const { setLocation } = useLetsWeatherContext();

    useEffect(() => {
        setLocation(null);
    });

    return (
        <>
            <Helmet title="Radar | LetsWeather" />
            <Map/>
        </>
    )
}