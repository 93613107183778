import { PUSH_STATE, usePushContext } from '../../../utils/PushUtil';

import { useState } from 'react';

import Button from '../../interactive/Button';
import Card from '../../ui/Card';

export default function Notifications() {
    const { subscribeDevice, subscriptionState } = usePushContext();
    const [ignored, setIgnored] = useState(false);

    if(ignored || subscriptionState.state === PUSH_STATE.UNSUPPORTED || subscriptionState.state === PUSH_STATE.INITIALISING || subscriptionState.state === PUSH_STATE.SUBSCRIBED)
        return null;

    return (
        <Card className="px-4 py-3.5">
            <div className="flex items-center justify-between">
                <div className="text-sm font-semibold tracking-wider text-white uppercase">Notifications</div>
            </div>
            <div className="text-sm pt-1.5 dark:text-gray-300 font-medium">
                Would you like to enable notifications for your locations?<br/>
                {/*<span className="text-[10px]">{JSON.stringify(subscriptionState)}</span>*/}
                <div className="border-t border-lwbg3 pt-3 mt-3">
                    <div className="flex text-md justify-center space-x-6 items-center">
                        <Button name="Enable" func={() => subscribeDevice()}/>
                        <Button name="Ignore" func={() => setIgnored(true)}/>
                    </div>
                </div>
            </div>
        </Card>
    )
}