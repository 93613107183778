import { Fragment } from 'react';

import { getNumberWithOrdinal } from '../../utils/ConversionUtil';

import Card from '../../components/ui/Card';
import Hour from '../../components/hourly/Hourly';

export default function Hourly({data, error, loading}) {
    return (
        <div className="space-y-2.5 px-0.5">
            {data.days.map((d, i) => (
               <Fragment key={i}>
                   {d.hours.map((h, i2) => (
                        <Card className="px-2" key={i2}>
                            {i2 === 0 && <div className="text-center mt-0.5 mx-2 mb-2.5 text-md font-semibold border-b pb-2.5 border-gray-800/30">{`${d.date.split(" ")[0]} ${d.date.split(" ")[1]} ${getNumberWithOrdinal(Number.parseInt(d.date.split(" ")[2]))}`}</div>}
                            <Hour o={i === 0 && i2 === 0} hour={h} key={i}/>
                        </Card>
                    ))}
               </Fragment>
            ))}
        </div>
    )
}