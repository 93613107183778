import Card from '../../ui/Card';

import { pickTextColorBasedOnBgColorSimple } from '../../../utils/IconUtil';
import { useState } from 'react';

import Button from '../../interactive/Button';

import Moment from '../../../utils/TimeUtil';
import moment from 'moment/moment';
import { NavLink, useParams } from 'react-router-dom';
import { useHash, useUrl } from '../../../utils/LocationUtil';

const PROB_COLORS = {
    "LESS_THAN_TEN": "#FFFFFF",
    "TEN_TO_THIRTY": "#03BFCE",
    "THIRTY_TO_FIFTY": "#F7FF03",
    "FIFTY_TO_EIGHTY": "#FB4B46",
    "GREATER_THAN_EIGHTY": "#990099"
}

export const PROB_NAMES = {
    "LESS_THAN_TEN": "<10%",
    "TEN_TO_THIRTY": "10 - 30%",
    "THIRTY_TO_FIFTY": "30 - 50%",
    "FIFTY_TO_EIGHTY": "50 - 80%",
    "GREATER_THAN_EIGHTY": ">80%"
}

export const PROB_NAMES2 = {
    "LESS_THAN_TEN": "less than 10%",
    "TEN_TO_THIRTY": "10 to 30%",
    "THIRTY_TO_FIFTY": "30 to 50%",
    "FIFTY_TO_EIGHTY": "50 to 80%",
    "GREATER_THAN_EIGHTY": "greater than 80%"
}

export default function WSO({wso}) {
    const url = useUrl();
    const offset = useHash();

    const [day, setDay] = useState(getFirst(wso?.wsos || []));

    // const time = wso === undefined  ? 0 : Math.max(wso.wsos.map(s => s.issued));
    const w = wso === undefined ? undefined : wso.wsos.filter(s => s.day === day)[0];
    const days = wso === undefined ? [] : wso.wsos.map(s => s.day);

    return w === undefined || wso === undefined ? null : (
        <Card className="px-4">
            <div className="flex items-center justify-between">
                <div>
                    <span className="text-sm font-semibold tracking-wider text-white uppercase">Winter Storm Outlook</span>
                    <div className="text-xs font-medium">
                        <Moment unix={w.issued}/>
                    </div>
                </div>
                <div>
                    <div className="rounded-full px-3 py-1 text-sm dark:text-white font-medium" style={{color: pickTextColorBasedOnBgColorSimple(PROB_COLORS[w.probability], '#FFF', '#000'), backgroundColor: PROB_COLORS[w.probability]}}>
                        <NavLink to={`${url}/map?situation=wso&day=${day}${offset === "" ? "" : `#${offset}`}`}>
                            {PROB_NAMES[w.probability]}
                        </NavLink>
                    </div>
                </div>
            </div>
            <div className="text-sm pt-1.5 dark:text-gray-300 font-medium">
                <NavLink to={`${url}/map?situation=wso&day=${day}${offset === "" ? "" : `#${offset}`}`}>
                    There is a {PROB_NAMES2[w.probability]} chance of exceeding warning criteria {w.day === 1 ? "today" : w.day === 2 ? "tomorrow" : `${moment.unix(w.valid / 1000).format('dddd')}`}.
                </NavLink>
                <div className="mt-1.5">
                    <a className="text-xs text-sky-400 hover:underline" target="_blank" rel="noreferrer" href="https://www.wpc.ncep.noaa.gov/wwd/wso/index.php">More info</a>
                </div>
            </div>
            {days.length > 0 && (
                <div className="border-t border-lwbg3 pt-3 mt-3">
                    <div className="flex text-md justify-evenly items-center">
                        {days.map((n, i) => <Button key={i} active={day === n} name={`Day ${n}`} className={day === n ? "border-lwbg3" : ""} func={() => setDay(n)}/>)}
                    </div>
                </div>
            )}
        </Card>
    )
}

function getFirst(wso) {
    if(wso === undefined) {
        return -1;
    } else if(wso.some(s => s.day === 1)) {
        return 1;
    } else if(wso.some(s => s.day === 2)) {
        return 2;
    } else if(wso.some(s => s.day === 3)) {
        return 3;
    } else if(wso.some(s => s.day === 4)) {
        return 4;
    }

    return -1;
}
