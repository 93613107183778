import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { useLocation, useParams, useSearchParams } from 'react-router-dom';
import { fetchJson } from './FetchUtil';

export let STATE_ABBREVIATIONS = {
    "AL": "Alabama",
    "AK": "Alaska",
    "AS": "American Samoa",
    "AZ": "Arizona",
    "AR": "Arkansas",
    "CA": "California",
    "CO": "Colorado",
    "CT": "Connecticut",
    "DE": "Delaware",
    "DC": "District Of Columbia",
    "FM": "Federated States Of Micronesia",
    "FL": "Florida",
    "GA": "Georgia",
    "GU": "Guam",
    "HI": "Hawaii",
    "ID": "Idaho",
    "IL": "Illinois",
    "IN": "Indiana",
    "IA": "Iowa",
    "KS": "Kansas",
    "KY": "Kentucky",
    "LA": "Louisiana",
    "ME": "Maine",
    "MH": "Marshall Islands",
    "MD": "Maryland",
    "MA": "Massachusetts",
    "MI": "Michigan",
    "MN": "Minnesota",
    "MS": "Mississippi",
    "MO": "Missouri",
    "MT": "Montana",
    "NE": "Nebraska",
    "NV": "Nevada",
    "NH": "New Hampshire",
    "NJ": "New Jersey",
    "NM": "New Mexico",
    "NY": "New York",
    "NC": "North Carolina",
    "ND": "North Dakota",
    "MP": "Northern Mariana Islands",
    "OH": "Ohio",
    "OK": "Oklahoma",
    "OR": "Oregon",
    "PW": "Palau",
    "PA": "Pennsylvania",
    "PR": "Puerto Rico",
    "RI": "Rhode Island",
    "SC": "South Carolina",
    "SD": "South Dakota",
    "TN": "Tennessee",
    "TX": "Texas",
    "UT": "Utah",
    "VT": "Vermont",
    "VI": "Virgin Islands",
    "VA": "Virginia",
    "WA": "Washington",
    "WV": "West Virginia",
    "WI": "Wisconsin",
    "WY": "Wyoming"
}

export const LetsWeatherContext = createContext({location: null, setLocation: () => {}});

export const LetsWeatherProvider = LetsWeatherContext.Provider;

export function useLetsWeatherContext() {
    return useContext(LetsWeatherContext);
}

export function LocationContext(props) {
    const [location, setLocation] = useState(null);

    const value = useMemo(
        () => ({ location, setLocation }),
        [location]
    );

    useEffect(() => {
        if(location !== null && location !== undefined)
            addLocation(location.location);
    }, [location]);

    return (
        <LetsWeatherProvider value={value}>
            {useMemo(() => (
                <>
                    {props.children}
                </>
            ), [props])}
        </LetsWeatherProvider>
    )
}

export function getLocations() {
    const locations = localStorage.getItem('locs');

    try {
        return locations === null || locations === "null" || locations === "" ? [] : JSON.parse(locations);
    } catch (err) {
        console.error(err);
        return [];
    }
}

export function clearLocations() {
    localStorage.removeItem('locs');
    document.dispatchEvent(new Event('locationChange'));
}

export function getLocationParams(params) {
    const city = params.city;
    const zipCode = params.zipcode;

    return { city, zipCode };
}

export function addLocation(loc, ignoreOffset = false) {
    let locations = localStorage.getItem('locs');

    if(locations == null || !isJson(locations)) {
        locations = [];
    } else {
        locations = JSON.parse(locations);
    }

    if(!locations.some(s => loc.id === s.id && (ignoreOffset || loc.offsetId === s.offsetId))) {
        locations.push(loc.fullName === undefined ? {...loc, fullName: `${loc.country_code === "US" ? `${loc.name}, ${loc.state_code}` : `${loc.name}, ${loc.state_code}, ${loc.country_code}`}`} : loc);
        localStorage.setItem("locs", JSON.stringify(locations));
        document.dispatchEvent(new Event('locationChange'));
    }
}

export function removeLocation(id, offsetId) {
    const locations = getLocations();
    const newLocations = locations.filter((location) => !(location.id === id && location.offsetId === offsetId));

    localStorage.setItem('locs', JSON.stringify(newLocations));
    document.dispatchEvent(new Event('locationChange'));
}

export function isJson(str) {
    if(str === "null" || str === null || str === undefined)
        return false;

    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }

    return true;
}

export function currentLocationGeo(callback) {
    navigator.geolocation.getCurrentPosition((pos) => {
        if(pos === undefined || pos.coords === undefined || pos.coords.latitude === undefined || pos.coords.longitude === undefined) {
            currentLocationGeo2(callback, "Error: undefined");
        } else {
            callback(pos.coords.latitude, pos.coords.longitude);
        }
    }, e => {
        currentLocationGeo2(callback, e);
    }, {enableHighAccuracy: true});
}

export default function currentLocationGeo2(callback, e = undefined) {
    if(e !== undefined) {
        console.error("Failed to get high accuracy location; trying non-high accuracy.");
        console.error(e);
    }

    navigator.geolocation.getCurrentPosition((pos) => {
        if(pos === undefined || pos.coords === undefined || pos.coords.latitude === undefined || pos.coords.longitude === undefined) {
            currentLocationIP(callback, "Error: undefined");
        } else {
            callback(pos.coords.latitude, pos.coords.longitude);
        }
        callback(pos.coords.latitude, pos.coords.longitude);
    }, (e) => {
        currentLocationIP(callback, e);
    });
}

export function currentLocationIP(callback, e = undefined) {
    if(e !== undefined) {
        console.error("Failed to get non-high accuracy location; trying IP.");
        console.error(e);
    }

    fetch('https://ip.eazyftw.com/api/ip')
        .then(r => r.json())
        .then(data => {
            if (data.location !== undefined && data.location.latitude !== undefined && data.location.longitude !== undefined) {
                fetchJson(`/api/city/${data.location.latitude},${data.location.longitude}`, s => {
                    if(s.latitude !== undefined) {
                        callback(s.latitude, s.longitude);
                    } else {
                        callback();
                    }
                })
            } else {
                callback();
            }
        })
}

export function getNearestCity(lat, lng, callback) {
    fetchJson(`/api/city/${lat},${lng}`, data => {
        callback(data)
    }, (err) => {
        callback();
        console.error(err);
    });
}

export function useHash(includePound = false) {
    let offset = useLocation().hash;
    if(offset !== "") offset = offset.substring(1);

    return includePound ? (offset === "" ? "" : `#${offset}`) : offset;
}

export function useUrl() {
    let params = useParams();

    let path = params.latlng !== undefined ? `${params.latlng}` : `${params.country}/${params.city}/${params.zipcode}`;

    return `/en/${path}`;
}

export function usePage(includeSlash = false, home = "home", ignoreRadar = true) {
    let location = useLocation();

    if(!location.pathname.startsWith("/en/")) {
        if (location.pathname === "/")
            return home;

        if(ignoreRadar && location.pathname === "/radar")
            return "";

        return includeSlash ? location.pathname : location.pathname.substring(1);
    }

    let page = location.pathname.split("/")[5];

    if(ignoreRadar && page === "radar")
        return includeSlash ? "/" : "";

    return page === undefined ? "" : `${includeSlash ? "/" : ""}${page}`;
}

export function useDebug() {
    let [ searchParams ] = useSearchParams();

    return searchParams.get("debug") !== null;
}

export function useApp() {
    let [ searchParams ] = useSearchParams();

    return searchParams.get("app") !== null;
}
