import Card from '../../components/ui/Card';
import Day from '../../components/day/Day';

export default function Daily({data, loading, error}) {
    return (
        <div className="space-y-2.5">
            {data.days.map((d, i) => (
                <Card className="px-2" key={i}>
                    <Day o={i === 0} day={d}/>
                </Card>
            ))}
        </div>
    )
}