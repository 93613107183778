import React, { useRef, useEffect, useState, useCallback } from 'react';

import { useNavigate, useSearchParams } from 'react-router-dom';
import { CURRENT_IP, useFetch } from '../../utils/FetchUtil';
import { bbox } from '@turf/turf';

import Button from '../interactive/Button';
import clsx from 'clsx';
import Checkbox from '../ui/Checkbox';
import MapPopup from './MapPopup';

import ReactDOM from 'react-dom/client';
import maplibregl from 'maplibre-gl';

import 'maplibre-gl/dist/maplibre-gl.css';

import lightning from '../../assets/images/lightning.svg';
import { useApp } from '../../utils/LocationUtil';

import doubleRightArrow from '../../assets/images/double-arrow-right-icon.svg';
import RMoment from 'react-moment';
import Moment from '../../utils/TimeUtil';

const addRainViewerLayer = (map, opacity, colorScheme, frame) => {
    const radarLayerId = 'rainviewer-radar';

    if (map.getLayer(radarLayerId)) {
        map.removeLayer(radarLayerId);
    }

    if (map.getSource(radarLayerId)) {
        map.removeSource(radarLayerId);
    }

    if (frame?.path === undefined) return;

    const tileUrl = `https://tilecache.rainviewer.com${frame.path}/256/{z}/{x}/{y}/${colorScheme}/0_1.png`;

    map.addSource(radarLayerId, {
        type: 'raster',
        tiles: [tileUrl],
        tileSize: 256,
        scheme: "xyz",
        bounds: [-180, -90, 180, 90]
    });

    map.addLayer({
        id: radarLayerId,
        type: 'raster',
        source: radarLayerId,
        paint: {
            "raster-fade-duration": 0,
            'raster-opacity-transition': {
                duration: 0
            },
            'raster-opacity': opacity,
        },
    });
};

const updateRainViewerLayer = (map, opacity, colorScheme, frame) => {
    const radarLayerId = 'rainviewer-radar';

    if (frame?.path === undefined) return;
    const tileUrl = `https://tilecache.rainviewer.com${frame.path}/256/{z}/{x}/{y}/${colorScheme}/0_1.png`;

    if (map.getLayer(radarLayerId)) {
        map.getSource(radarLayerId).tiles = [tileUrl];
        map.setPaintProperty(radarLayerId, 'raster-opacity', opacity);

        map.style.sourceCaches['rainviewer-radar'].clearTiles();
        map.style.sourceCaches['rainviewer-radar'].update(map.transform);
        map.triggerRepaint();
    }
};

export function Map({center = [37.7902, -95.7129], zoom = 4.1, height, top = false, borderRadius = false, show = {outlooks: true, alerts: true, radar: true, popups: true}}) {
    let [ searchParams ] = useSearchParams();
    const navigate = useNavigate();
    const app = useApp();

    const mapContainer = useRef(null);
    const map = useRef(null);

    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [isPlaying, setIsPlaying] = useState(false);

    const intervalRef = useRef(null);

    const [lat] = useState(center[0]);
    const [lng] = useState(center[1]);
    const [z] = useState(zoom);
    const [loaded, setLoaded] = useState(false);
    const [popupMapLibre, setPopupMapLibre] = useState(null);

    const [opacity, setOpacity] = useState(0.35);
    const [colorScheme, setColorScheme] = useState('6');

    const [frames, setFrames] = useState([]);
    const [frame, setFrame] = useState({});
    const [frameValue, setFrameValue] = useState(0);

    const [lastClick, setLastClick] = useState(null);

    let startingDay = 1;
    let startingType = "category";
    let startingSit = "severe";

    if(searchParams.has("day") && ["1", "2", "3", "4", "5", "6", "7", "8"].includes(searchParams.get("day")))
        startingDay = parseInt(searchParams.get("day"));
    if(searchParams.has("outlook") && ["category", "wind", "tornado", "hail"].includes(searchParams.get("outlook").toLowerCase())) {
        startingType = searchParams.get("outlook").toLowerCase();

        if(startingDay === 3 && startingType !== "category")
            startingType = "category";
    }
    if(searchParams.has("situation") && ["severe", "fire", "wso", "wssi", "eros", "snowfall", "storms"].includes(searchParams.get("situation").toLowerCase())) {
        startingSit = searchParams.get("situation").toLowerCase();

        if(startingSit.includes("w") && (startingDay === 5 || startingDay === 6 || startingDay === 7 || startingDay === 8))
            startingDay = 4;
        if(startingSit === "wssi" && startingDay === 4)
            startingDay = 3;
        if(startingSit === "eros" && startingDay > 5)
            startingDay = 1;
    }

    const [outlooks, setOutlooks] = useState(show.outlooks);
    const [alerts, setAlerts] = useState(typeof show.alerts !== "boolean");

    const [situation, setSituation] = useState(startingSit);
    const [prevSituation, setPrevSituation] = useState(null);
    const [outlookType, setOutlookType] = useState(startingType);
    const [day, setDay] = useState(startingDay);

    const [range, setRange] = useState(0);
    const [maxRange, setMaxRange] = useState(0);
    const [time, setTime] = useState(null);
    const [running, setRunning] = useState(false);

    const [rainViewer, setRainViewer] = useState(null);

    const [popupLoc, setPopupLoc] = useState(null);
    const [showPopup, setShowPopup] = useState(false);
    const [canShowPopup, setCanShowPopup] = useState(true);
    const [popupFeature, setPopupFeature] = useState(null);

    const [lightningData, setLightningData] = useState([]);

    const severeFetch = useFetch();
    const fireFetch = useFetch();
    const wsoFetch = useFetch();
    const wssiFetch = useFetch();
    const popup = useFetch();
    const alertsFetch = useFetch();
    const forecastsFetch = useFetch();
    const erosFetch = useFetch();
    const stormsFetch = useFetch();

    function getPulsingDot(map, size) {
        return {
            width: size,
            height: size,
            data: new Uint8Array(size * size * 4),

            // get rendering context for the map canvas when layer is added to the map
            onAdd: function () {
                const canvas = document.createElement('canvas');
                canvas.width = this.width;
                canvas.height = this.height;
                this.context = canvas.getContext('2d');
            },

            // called once before every frame where the icon will be used
            render: function () {
                const duration = 1800;
                const t = (performance.now() % duration) / duration;

                const radius = (size / 2) * 0.3;
                const outerRadius = (size / 2) * 0.7 * t + radius;
                const context = this.context;

                // draw outer circle
                context.clearRect(0, 0, this.width, this.height);
                context.beginPath();
                context.arc(
                    this.width / 2,
                    this.height / 2,
                    outerRadius,
                    0,
                    Math.PI * 2
                );
                context.fillStyle = 'rgba(29, 161, 242,' + (1 - t) + ')';
                context.fill();

                // draw inner circle
                context.beginPath();
                context.arc(
                    this.width / 2,
                    this.height / 2,
                    radius,
                    0,
                    Math.PI * 2
                );
                context.fillStyle = '#1DA1F2';
                context.strokeStyle = 'white';
                context.lineWidth = 2 + 4 * (1 - t);
                context.fill();
                context.stroke();

                // update this image's data with data from the canvas
                this.data = context.getImageData(
                    0,
                    0,
                    this.width,
                    this.height
                ).data;

                // continuously repaint the map, resulting in the smooth animation of the dot
                map.triggerRepaint();

                return true;
            }
        };
    }

    function decode(b) {
        var a;
        var e = {};
        var d = b.split("");
        var c = d[0];
        var f = c;
        var g = [c];
        var h = 256;
        var o = h;
        for (b = 1; b < d.length; b++) {
            a = d[b].charCodeAt(0);
            a = h > a ? d[b] : e[a] ? e[a] : f + c;
            g.push(a);
            c = a.charAt(0);
            e[o] = f + c;
            o++;
            f = a;
        }
        return g.join("");
    }

    useEffect(() => {
        if (show.radar) {
            const hosts = ["ws1", "ws3", "ws7", "ws8"];
            const uri = `wss://${hosts[Math.floor(Math.random() * hosts.length)]}.blitzortung.org:443/`;
            // websockets
            const ws = new WebSocket(uri);

            ws.onopen = () => {
                ws.send('{"a": 418}');
            };

            ws.onmessage = (event) => {
                const data = event.data;

                setLightningData(oldData => [...oldData, JSON.parse(decode(data))]);
                // console.log(decode(data))
                // console.log(JSON.parse(decode(data)))
            };
        }

        // fetch('https://map.blitzortung.org/GEOjson/getjson.php?f=s&n=01').then(res => res.json()).then(data => {
        //     console.log(data)
        // });
    }, []);

    useEffect(() => {
        if(!loaded)
            return;

        if(map.current.getSource('lightning')) {
            const features = lightningData.map(l => ({
                "type": "Feature",
                "geometry": {
                    "type": "Point",
                    "coordinates": [l.lon, l.lat]
                },
                "properties": l
            }));
            map.current.getSource('lightning').setData({
                'type': "FeatureCollection",
                'features': features
            });
        }
    }, [loaded, lightningData])

    useEffect(() => {
        if(outlooks) {
            if (show.outlooks) {
                severeFetch.fetchNow(`/api/outlooksSevere`, {method: "POST"});
                fireFetch.fetchNow(`/api/outlooksFire`, {method: "POST"});
                wsoFetch.fetchNow(`/api/wsos`, {method: "POST"});
                wssiFetch.fetchNow(`/api/wssis`, {method: "POST"});
                forecastsFetch.fetchNow(`/api/forecasts`, {method: "POST"});
                erosFetch.fetchNow(`/api/eros`, {method: "POST"});
                erosFetch.fetchNow(`/api/eros`, {method: "POST"});
                stormsFetch.fetchNow(`/api/stormReports`, {method: "POST"});
            }
        }
    }, []);

    useEffect(() => {
        if(alertsFetch.loading || alertsFetch.error || alertsFetch.data !== undefined)
            return;

        if(alerts && show.alerts && typeof show.alerts === "boolean")
            alertsFetch.fetchNow(`/api/alertsGeo`, {method: "POST"});
    }, [alerts]);

    function removeLayerSources(list) {
        list.forEach((l) => {
            if(map.current.getLayer(l))
                map.current.removeLayer(l);
            if(map.current.getSource(l))
                map.current.removeSource(l);
        });
    }

    useEffect(() => {
        if(popupLoc !== null && show.popups && situation !== "storms")
            popup.fetchNow(`/api/map/${popupLoc}/${day}/${outlookType}/${situation}`, {method: "POST"});
    }, [popupLoc, day, outlookType, situation]);

    useEffect(() => {
        if(popupMapLibre === undefined &&  document.getElementById("hide-popup") !== null)
            return;

        const popup = document.getElementById("hide-popup");

        setTimeout(() => {
            if(popup !== null)
                popup.addEventListener("click", hidePopup);
        }, 1000);
        return () => {
            if(popup !== null)
                popup.removeEventListener("click", hidePopup);
        }
    }, [popupMapLibre, document.getElementById("hide-popup")]);

    useEffect(() => {
        if(!showPopup || popupLoc === undefined || popupFeature === null)
            return;

        if(popupMapLibre !== null)
            popupMapLibre.remove();

        const popupNode = document.createElement("div");
        const root = ReactDOM.createRoot(popupNode);

        root.render(
            <div className="flex justify-between w-full">
                <div className="w-full">
                    <MapPopup onUrlClick={(url) => navigate(url)} popup={popup.data} loading={popup.loading} popupFeature={popupFeature} outlookType={outlookType} showOutlooks={outlooks} showAlerts={alerts} situation={situation}/>
                </div>
            </div>
        );

        const p = new maplibregl.Popup({closeButton: false, closeOnClick: false, focusAfterOpen: true})
            .setLngLat([popupLoc.split(",")[1], popupLoc.split(",")[0]])
            .setDOMContent(popupNode)
            .addTo(map.current);

        setPopupMapLibre(p);
    }, [showPopup, alerts, outlooks, popupFeature, popup.data, popup.loading]);

    useEffect(() => {
        if(!loaded || !popup || !show.popups)
            return;

        if(map.current._listeners.click !== undefined)
            map.current.off('click', map.current._listeners.click[0]);

        map.current.on('click', function (event) {
            setPopupFeature(null);

            hidePopup();

            if(situation === "snowfall" || situation === "storms") {
                const features = map.current.queryRenderedFeatures(event.point, {layers: ['outlooks-fill']});

                if (features.length > 0) {
                    const feature = features[0];
                    setPopupFeature(feature);
                    setShowPopup(true);
                    setPopupLoc(`${features[0].geometry.coordinates[1]},${features[0].geometry.coordinates[0]}`);
                }
            } else if(show.radar) {
                const features = map.current.queryRenderedFeatures(event.point, {layers: ['lightning']});

                if (features.length > 0) {
                    const feature = features[0];
                    setPopupFeature(feature);
                    setShowPopup(true);
                    setPopupLoc(`${features[0].geometry.coordinates[1]},${features[0].geometry.coordinates[0]}`);
                } else {
                    setShowPopup(true);
                    setPopupFeature(undefined);
                    setPopupLoc(`${event.lngLat.lat},${event.lngLat.lng}`);
                }
            } else {
                setShowPopup(true);
                setPopupFeature(undefined);
                setPopupLoc(`${event.lngLat.lat},${event.lngLat.lng}`);
            }
        });
    }, [loaded, situation]);

    const fetchRadarData = async () => {
        const url = 'https://api.rainviewer.com/public/weather-maps.json';
        try {
            const response = await fetch(url);
            if (!response.ok) {
                throw new Error(`Error fetching radar data: ${response.statusText}`);
            }
            const data = await response.json();
            return [...data.radar.past, ...data.radar.nowcast]; // data.radar.past; // Return past radar frames
        } catch (error) {
            console.error(error);
            return [];
        }
    };

    useEffect(() => {
        if(!loaded)
            return;

        ['outlooks-sig-fill', 'outlooks-sig-line', 'outlooks-fill', 'outlooks-line'].forEach((l) => {
            if(map.current.getLayer(l))
                map.current.setLayoutProperty(l, 'visibility', outlooks ? 'visible' : 'none');
        });
    }, [loaded, outlooks]);

    useEffect(() => {
        if(!loaded)
            return;

        if(map.current.getLayer('lightning'))
            map.current.setLayoutProperty('lightning', 'visibility', situation !== "snowfall" && situation !== "storms" ? 'visible' : 'none');
    }, [loaded, situation]);

    function hidePopup() {
        setShowPopup(false);
        setPopupLoc(null);
        if(popupMapLibre !== null) {
            popupMapLibre.remove();
            setPopupMapLibre(null);
        }
    }

    useEffect(() => {
        const outlookData = situation === "severe" ? severeFetch : situation === "fire" ? fireFetch : situation === "wso" ? wsoFetch : situation === "wssi" ? wssiFetch : situation === "forecasts" ? forecastsFetch : situation === "eros" ? erosFetch : situation === "storms" ? stormsFetch : situation === "snowfall" ? {data: [], loading: false} : {data: undefined, loading: false};

        if(outlookData.data === undefined || outlookData.loading || !loaded)
            return;

        let data;

        if(situation === "forecasts") {
            data = outlookData.data[day];
        } else {
            data = outlookData.data.filter((d) => {
                return d.day === day;
            });

            if(!situation.includes("w") && situation !== "eros")
                data = data[0];
        }

        setPopupFeature(undefined);

        if(prevSituation !== situation)
            hidePopup();

        setPrevSituation(situation);

        const HOURS = {
            "1": 1,
            "2": 3,
            "3": 6,
            "4": 12,
            "5": 24,
            "6": 36,
            "7": 48,
            "8": 72,
        }

        const hr = HOURS[day];

        removeLayerSources(['outlooks-sig-fill', 'outlooks-sig-line', 'outlooks-fill', 'outlooks-line', 'outlooks', 'outlooks-sig']);

        map.current.addSource('outlooks', {
            'type': 'geojson',
            'data': situation === "snowfall" ? `${process.env.NODE_ENV !== `production` ? `http://${CURRENT_IP}:8000` : ``}/api/snowfallReports` : situation === "storms" ? {
                'type': 'FeatureCollection',
                'features': stormsFetch.data.filter(s => s.today && day === 1 || !s.today && day === 2).map(f => {
                    const text = f.type === "WIND" ? f.speed : f.type === "HAIL" ? f.size : f.fScale;

                    return {
                        'type': 'Feature',
                        'geometry': {
                            'type': 'Point',
                            'coordinates': [f.lon, f.lat]
                        },
                        'properties': {
                            'color': f.type === "WIND" ? '#0000ff' : f.type === "HAIL" ? '#00ff00' : '#ff0000',
                            'radius': f.type === "WIND" ? f.speed > 74 ? 8 : 6 : f.type === "HAIL" ? f.size > 2 ? 8 : 6 : 6,
                            'type': f.type,
                            'size': f.size,
                            'speed': f.speed,
                            'fScale': f.fscale,
                            'comments': f.comments,
                            'time': f.time,
                            'office': f.office,
                            'location': f.location,
                            'county': f.county,
                            'state': f.state,
                            'text': text === -1 ? "" : text,
                            'today': f.today,
                            'lat': f.lat,
                            'lon': f.lon
                        }
                    }
                })
            } : {
                    'type': 'FeatureCollection',
                    'features': (situation.includes("w") || situation === "forecasts" || situation === "eros" ? data : data[outlookType].filter(f => !f.label.includes("Significant"))).map((feature) => {
                        return situation.includes("w") || situation === "eros" ? {
                            'type': 'Feature',
                            'geometry': {
                                'type': 'MultiPolygon',
                                'coordinates': feature.polygon.map((coord) => {
                                    return [coord.map((c) => {
                                        return [c[1], c[0]];
                                    })];
                                })
                            },
                            'properties': {
                                'fill': feature.hex,
                                'stroke': '#000000'
                            }
                        } : {
                            'type': 'Feature',
                            'geometry': {
                                'type': 'MultiPolygon',
                                'coordinates': feature.coordinates.map((coord) => {
                                    return [coord.map((c) => {
                                        return [c[1], c[0]];
                                    })];
                                })
                            },
                            'properties': {
                                'fill': feature.fill,
                                'stroke': feature.stroke
                            }
                        }
                    })
                }
        });
        if(!situation.includes("w") && situation !== "forecasts" && situation !== "eros" && situation !== "storms")
            map.current.addSource('outlooks-sig', {
                'type': 'geojson',
                'data': {
                    'type': 'FeatureCollection',
                    'features': data[outlookType].filter(f => f.label.includes("Significant")).map((feature) => {
                        return {
                            'type': 'Feature',
                            'geometry': {
                                'type': 'MultiPolygon',
                                'coordinates': feature.coordinates.map((coord) => {
                                    return [coord.map((c) => {
                                        return [c[1], c[0]];
                                    })];
                                })
                            }
                        }
                    })
                }
            });
        if (situation === "storms") {
            map.current.addLayer({
                'id': 'outlooks-fill',
                'type': 'circle',
                'source': 'outlooks',
                'layout': {
                    'visibility': outlooks ? 'visible' : 'none'
                },
                'paint': {
                    'circle-color': ['get', 'color'],
                    'circle-radius': ['get', 'radius'],
                }
            });
            map.current.addLayer({
                'id': 'outlooks-line',
                'type': 'symbol',
                'source': 'outlooks',
                'layout': {
                    'visibility': outlooks ? 'visible' : 'none',
                    'text-field': ['get', 'text'],
                    'text-variable-anchor': ['bottom'],
                    'text-radial-offset': 0.8,
                    'text-justify': 'auto',
                    'text-size': 15,
                    'text-font': ['Open Sans Semibold', 'Arial Unicode MS Bold']
                },
                'paint': {
                    'text-color': '#FFFFFF'
                }
            });
        } else if(situation !== "snowfall") {
            map.current.addLayer({
                'id': 'outlooks-fill',
                'type': 'fill',
                'source': 'outlooks',
                'layout': {
                    'visibility': outlooks ? 'visible' : 'none'
                },
                'paint': {
                    'fill-color': ['get', 'fill'],
                    'fill-opacity': situation === "eros" ? 0.08 : 0.18
                }
            });
            map.current.addLayer({
                'id': 'outlooks-line',
                'type': 'line',
                'source': 'outlooks',
                'layout': {
                    'visibility': outlooks ? 'visible' : 'none'
                },
                'paint': {
                    'line-color': ['get', 'fill'],
                    'line-width': 1.5,
                    'line-opacity': situation === "eros" ? 0.2 : 0.3
                }
            });
        } else {
            map.current.addLayer({
                'id': 'outlooks-fill',
                'type': 'circle',
                'source': 'outlooks',
                'filter': ['>', 'valid_time', Math.floor(Date.now() / 1000) - (hr * 3600)],
                'layout': {
                    'visibility': outlooks ? 'visible' : 'none'
                },
                'paint': {
                    'circle-color': ['get', 'color'],
                    'circle-radius': 6,
                }
            });
            map.current.addLayer({
                'id': 'outlooks-line',
                'type': 'symbol',
                'source': 'outlooks',
                'filter': ['>', 'valid_time', Math.floor(Date.now() / 1000) - (hr * 3600)],
                'layout': {
                    'visibility': outlooks ? 'visible' : 'none',
                    'text-field': ['get', 'amount'],
                    'text-variable-anchor': ['bottom'],
                    'text-radial-offset': 0.8,
                    'text-justify': 'auto',
                    'text-size': 15,
                    'text-font': ['Open Sans Semibold', 'Arial Unicode MS Bold']
                },
                'paint': {
                    'text-color': '#FFFFFF'
                }
            });
        }
        if(!situation.includes("w") && situation !== "forecasts" && situation !== "eros" && situation !== "storms") {
            if (map.current.hasImage('sig-pattern')) {
                map.current.addLayer({
                    'id': 'outlooks-sig-fill',
                    'type': 'fill',
                    'source': 'outlooks-sig',
                    'layout': {
                        'visibility': outlooks ? 'visible' : 'none'
                    },
                    'paint': {
                        'fill-pattern': 'sig-pattern',
                        'fill-opacity': 0.5
                    }
                });
                map.current.addLayer({
                    'id': 'outlooks-sig-line',
                    'type': 'line',
                    'source': 'outlooks-sig',
                    'layout': {
                        'visibility': outlooks ? 'visible' : 'none'
                    },
                    'paint': {
                        'line-color': '#000000',
                        'line-width': 3,
                        'line-opacity': 0.5
                    }
                });
            } else {
                map.current.loadImage(
                    '/stripe.png',
                    function (err, image) {
                        // Throw an error if something went wrong
                        if (err) throw err;

                        // Declare the image
                        map.current.addImage('sig-pattern', image);

                        // Use it
                        map.current.addLayer({
                            'id': 'outlooks-sig-fill',
                            'type': 'fill',
                            'source': 'outlooks-sig',
                            'layout': {
                                'visibility': outlooks ? 'visible' : 'none'
                            },
                            'paint': {
                                'fill-pattern': 'sig-pattern'
                            }
                        });
                        map.current.addLayer({
                            'id': 'outlooks-sig-line',
                            'type': 'line',
                            'source': 'outlooks-sig',
                            'layout': {
                                'visibility': outlooks ? 'visible' : 'none'
                            },
                            'paint': {
                                'line-color': ['get', 'fill'],
                                'line-width': 1.5,
                                'line-opacity': 0.3
                            }
                        });
                    }
                );
            }
        }
    }, [loaded, day, situation, outlookType]);

    useEffect(() => {
        if(!loaded)
            return;
        if(typeof show.alerts == "boolean" && (alertsFetch.data === undefined || alertsFetch.loading))
            return;

        removeLayerSources(['alerts-fill', 'alerts-line', 'alerts']);

        map.current.addSource('alerts', {
            'type': 'geojson',
            'data': {
                'type': 'FeatureCollection',
                'features': (typeof show.alerts == "boolean" ? alertsFetch.data : show.alerts).map((feature) => {
                    return {
                        'type': 'Feature',
                        'geometry': {
                            'type': 'MultiPolygon',
                            'coordinates': feature.geometry.map((coord) => {
                                return [coord.map((c) => {
                                    return [c[1], c[0]];
                                })];
                            })
                        },
                        'properties': {
                            'fill': `#${typeof show.alerts == "boolean" ? feature.color : feature.color.hexColor.replace("#", "")}`,
                        }
                    }
                })
            }
        });
        map.current.addLayer({
            'id': 'alerts-fill',
            'type': 'fill',
            'source': 'alerts',
            'layout': {
                'visibility': alerts ? 'visible' : 'none'
            },
            'paint': {
                'fill-color': ['get', 'fill'],
                'fill-opacity': 0.15
            }
        });
        map.current.addLayer({
            'id': 'alerts-line',
            'type': 'line',
            'source': 'alerts',
            'layout': {
                'visibility': alerts ? 'visible' : 'none'
            },
            'paint': {
                'line-color': ['get', 'fill'],
                'line-width': 1.5,
                'line-opacity': 0.3
            }
        });
        if(typeof show.alerts == "object")
            map.current.on('sourcedata', function (e) {
                if (e.sourceId !== 'alerts' || !e.isSourceLoaded) return
                const f = map.current.querySourceFeatures('alerts')
                if (f.length === 0) return
                const b = bbox({
                    type: 'FeatureCollection',
                    features: f
                });
                map.current.fitBounds(b, {padding: 20});
            });
    }, [alertsFetch.data, loaded]);

    useEffect(() => {
        if(!loaded)
            return;

        ['alerts-fill', 'alerts-line'].forEach((l) => {
            if(map.current.getLayer(l))
                map.current.setLayoutProperty(l, 'visibility', alerts ? 'visible' : 'none');
        });
    }, [loaded, alerts]);

    useEffect(() => {
        if (map.current) {
            updateRainViewerLayer(map.current, opacity, colorScheme, frame);
        }
    }, [opacity, colorScheme, frame]);

    useEffect(() => {
        if (map.current) return;

        map.current = new maplibregl.Map({
            container: mapContainer.current,
            style: "/map.json",
            center: [lng, lat],
            zoom: z
        });

        map.current.addControl(
            new maplibregl.NavigationControl({
                visualizePitch: true,
                showZoom: true,
                showCompass: true
            })
        );

        map.current.on('load', () => {
            fetchRadarData().then((data) => {
                setFrames(data);
                if (data?.length) {
                    setFrameValue(data.length - 4);
                    // setFrame(data[data.length - 4]); // Set the latest frame initially
                }

                addRainViewerLayer(map.current, opacity, colorScheme, data[data.length - 4]);
            });
        });

        map.current.on('load', function () {
            const customIcon = new Image(60, 60);
            customIcon.onload = () => map.current.addImage("lightning", customIcon)
            customIcon.src = lightning;
            map.current.addSource('lightning', {
                'type': 'geojson',
                'data': {
                    'type': 'FeatureCollection',
                    'features': []
                }
            });
            map.current.addLayer({
                'id': 'lightning',
                'type': 'symbol',
                'source': 'lightning',
                // 'layout': {
                //     'visibility': outlooks ? 'visible' : 'none'
                // },
                layout: {
                    'icon-image': 'lightning',
                    "icon-size": 0.23,
                    "icon-allow-overlap": true,
                    "icon-ignore-placement": true
                }
                // 'paint': {
                //     'circle-color': '#FFFFFF',
                //     'circle-radius': 5,
                // }
            });
//             map.current.addSource('wms-test-source', {
//                 'type': 'raster',
//                 'tiles': [
//                     'http://mesonet.agron.iastate.edu/cgi-bin/wms/nexrad/n0q.cgi?bbox={bbox-epsg-3857}&format=image/png&service=WMS&version=1.1.1&request=GetMap&srs=EPSG:3857&transparent=true&width=256&height=256&layers=nexrad-n0q-900913'
//                 ],
//                 'tileSize': 256
//             });
//             map.current.addLayer(
//                 {
//                     'id': 'wms-test-layer',
//                     'type': 'raster',
//                     'source': 'wms-test-source',
//                     'paint': {}
//                 }
//             );
            setLoaded(true);
        });

        if(center[0] !== 37.0902 && center[1] !== -95.7129 && typeof show.alerts == "boolean") {
            map.current.on('load', function () {
                map.current.addImage('pulsing-dot', getPulsingDot(map.current, 100), {pixelRatio: 2});

                map.current.addSource('points', {
                    'type': 'geojson',
                    'data': {
                        'type': 'FeatureCollection',
                        'features': [
                            {
                                'type': 'Feature',
                                'geometry': {
                                    'type': 'Point',
                                    'coordinates': [parseFloat(center[1]), parseFloat(center[0])]
                                }
                            }
                        ]
                    }
                });
                map.current.addLayer({
                    'id': 'points',
                    'type': 'symbol',
                    'source': 'points',
                    'layout': {
                        'icon-image': 'pulsing-dot'
                    }
                });
            });
        }
    });

    const handlePlayPause = useCallback(() => {
        setIsPlaying((prev) => !prev);

        if (isPlaying) {
            clearInterval(intervalRef.current);
        } else {
            intervalRef.current = setInterval(() => {
                setFrameValue((prevFrame) => (prevFrame + 1) % frames.length);
            }, 500); // Change frame every 500ms
        }
    }, [frames.length, frame, isPlaying]);

    useEffect(() => {
        if(frameValue !== -1 && frames !== undefined && frames.length > 0)
            setFrame(frames[frameValue]);
    }, [frames, frameValue]);

    return (
        <>
            <div className="relative" style={{zIndex: "10"}}>
                <div className={clsx("absolute select-none text-sm", app ? "pl-4" : "p-2.5")}
                     style={{zIndex: "60", marginTop: app ? "20px" : top ? "35px" : "0", width: "275px"}}>
                    {app && (
                        <div onClick={() => setSidebarOpen(!sidebarOpen)} className={clsx("absolute bg-lwbg left-0 px-[11px] py-2.5 cursor-pointer", !sidebarOpen && "rounded-r-[4px]")} style={{boxSHadow: "0 0 0 2px rgba(0,0,0,.1)"}}>
                            <img src={doubleRightArrow} alt="Double Right Arrow Selection (Sidebar Toggle)" className="h-2 w-2"/>
                        </div>
                    )}
                    {/*{show.radar && (*/}
                    {/*    <div className="bg-lwbg rounded-lg text-white font-medium py-3 px-5 space-y-2">*/}
                    {/*        <div className="flex items-center justify-center space-x-3">*/}
                    {/*            <Button name={running ? "Stop" : "Play"} className="text-white !bg-[#00BFFF]"*/}
                    {/*                    func={() => {*/}
                    {/*                        // rainViewer.playStop();*/}
                    {/*                        // setRunning(!running);*/}
                    {/*                    }}/>*/}
                    {/*            <input type="range" min={0} max={maxRange} value={range} onChange={e => {*/}
                    {/*                // setRange(e.target.value);*/}
                    {/*                // rainViewer.setPosition(Number.parseInt(e.target.value));*/}
                    {/*            }} className="bg-lwbg focus:outline-none"/>*/}
                    {/*        </div>*/}
                    {/*        <div className="text-gray-100 text-sm font-semibold text-center">RADAR COMING BACK SOON</div>*/}
                    {/*        /!*<div className="text-gray-100 text-sm font-semibold text-center">{time || "LOADING..."}</div>*!/*/}
                    {/*    </div>*/}
                    {/*)}*/}
                    {(!app || sidebarOpen) && (
                        <>
                            {(show.outlooks === true) && (
                                <div className={clsx("flex-row items-center bg-lwbg rounded-lg text-white py-2 px-3.5 space-y-0.5", show.radar && "mt-1.5 w-full inline-block")}>
                                    <div className="flex items-center justify-between">
                                        <div>
                                            <Checkbox name="Severe" checked={situation === "severe"} onCheck={() => setSituation("severe")}/>
                                        </div>
                                        <div>
                                            <Checkbox name="Fire" reverse={true} checked={situation === "fire"} onCheck={() => {
                                                setSituation("fire");
                                                setOutlookType("category");
                                            }}/>
                                        </div>
                                    </div>
                                    <div className="flex items-center justify-between">
                                        <div>
                                            <Checkbox name="Forecasts" checked={situation === "forecasts"} onCheck={() => {
                                                if(day > 3)
                                                    setDay(1);

                                                setSituation("forecasts");
                                                setOutlookType("category");
                                            }}/>
                                        </div>
                                        <div>
                                            <Checkbox name="WSO" reverse={true} checked={situation === "wso"} onCheck={() => {
                                                if(day > 4)
                                                    setDay(1);

                                                setSituation("wso");
                                                setOutlookType("category");
                                            }}/>
                                        </div>
                                    </div>
                                    <div className="flex items-center justify-between">
                                        <div>
                                            <Checkbox name="Snow Reports" checked={situation === "snowfall"} onCheck={() => {
                                                setSituation("snowfall");
                                                setDay(8);
                                                setOutlookType("category");
                                            }}/>
                                        </div>
                                        <div>
                                            <Checkbox name="WSSI" reverse={true} checked={situation === "wssi"}  onCheck={() => {
                                                if(day > 3)
                                                    setDay(1);

                                                setSituation("wssi");
                                                setOutlookType("category");
                                            }}/>
                                        </div>
                                    </div>
                                    <div>
                                        <Checkbox name="Storm Reports" checked={situation === "storms"} onCheck={() => {
                                            setSituation("storms");
                                            setDay(1);
                                            setOutlookType("category");
                                        }}/>
                                    </div>
                                    <div>
                                        <Checkbox name="Excessive Rainfall" reverse={false} checked={situation === "eros"}  onCheck={() => {
                                            if(day > 5)
                                                setDay(1);

                                            setSituation("eros");
                                            setOutlookType("category");
                                        }}/>
                                    </div>
                                </div>
                            )}
                            {typeof show.alerts == "boolean" && (
                                <div className={clsx("bg-lwbg rounded-lg text-white py-2 px-3.5", show.radar && "w-full inline-block", show.outlooks && "mt-1.5")} >
                                    <div className="flex items-center justify-between">
                                        <Checkbox name="Alerts" checked={alerts} onCheck={c => setAlerts(c)}/>
                                        {show.outlooks && <Checkbox name="Outlooks" checked={outlooks} reverse={true} onCheck={c => setOutlooks(c)} />}
                                        {/*{!show.outlooks && <Checkbox name="Outlooks" checked={false} reverse={true} onCheck={() => {}}/>}*/}
                                    </div>
                                </div>
                            )}
                            {(show.outlooks  || show.wso || show.wssi) && (
                                <div>
                                    <div className="flex-row bg-lwbg rounded-lg text-white py-2 px-3.5 mt-1.5">
                                        <div className="flex-row">
                                            {situation !== "snowfall" && situation !== "storms" ? (
                                                <>
                                                    <div className={clsx("flex justify-center space-x-2 mb-0.5", situation !== "wssi" && situation !== "forecasts" && "border-b border-lwbg3 pb-1.5 !mb-1.5", situation === "fire" && "space-x-7")}>
                                                        <Checkbox name="Day 1" checked={day === 1} onCheck={() => {
                                                            setDay(1);

                                                            if(outlookType === "probabilistic")
                                                                setOutlookType("category");
                                                        }}/>
                                                        <Checkbox name="Day 2" checked={day === 2} onCheck={() => {
                                                            setDay(2);

                                                            if(outlookType === "probabilistic")
                                                                setOutlookType("category");
                                                        }}/>
                                                        {situation !== "fire" && <Checkbox name="Day 3" checked={day === 3} onCheck={() => {
                                                            setDay(3);
                                                            setOutlookType("category");
                                                        }}/>}
                                                    </div>
                                                    {!situation.includes("w") && situation !== "forecasts" && (
                                                        <div className="flex-row">
                                                            <div className="flex space-x-2 items-center justify-center">
                                                                {situation === "fire" && <Checkbox name="Day 3" checked={day === 3} onCheck={() => {
                                                                    setDay(3);
                                                                    setOutlookType("category");
                                                                }}/>}
                                                                <Checkbox name="Day 4" checked={day === 4} onCheck={() => {
                                                                    setDay(4);
                                                                    setOutlookType("category");
                                                                }}/>
                                                                <Checkbox name="Day 5" checked={day === 5} onCheck={() => {
                                                                    setDay(5);
                                                                    setOutlookType("category");
                                                                }}/>
                                                                {situation === "severe" && <Checkbox name="Day 6" checked={day === 6} onCheck={() => {
                                                                    setDay(6);
                                                                    setOutlookType("category");
                                                                }}/>}
                                                            </div>
                                                            {situation !== "eros" && (
                                                                <div className={clsx("flex space-x-2 mt-1.5", situation === "severe" && "justify-center space-x-7")}>
                                                                    {situation === "fire" && <Checkbox name="Day 6" checked={day === 6} onCheck={() => {
                                                                        setDay(6);
                                                                        setOutlookType("category");
                                                                    }}/>}
                                                                    <Checkbox name="Day 7" checked={day === 7} onCheck={() => {
                                                                        setDay(7);
                                                                        setOutlookType("category");
                                                                    }}/>
                                                                    <Checkbox name="Day 8" checked={day === 8} onCheck={() => {
                                                                        setDay(8);
                                                                        setOutlookType("category");
                                                                    }}/>
                                                                </div>
                                                            )}
                                                        </div>
                                                    )}
                                                    {situation === "wso" && (
                                                        <div className="flex justify-center">
                                                            <Checkbox name="Day 4" checked={day === 4} onCheck={() => {
                                                                setDay(4);
                                                            }}/>
                                                        </div>
                                                    )}
                                                </>
                                            ) : (
                                                <>
                                                    {situation === "snowfall" ? (
                                                        <div className="grid grid-cols-2 gap-y-0.5">
                                                            <Checkbox name="1 Hour" checked={day === 1} onCheck={() => {
                                                                setDay(1);
                                                            }}/>
                                                            <Checkbox name="3 Hours" reverse={true} checked={day === 2} onCheck={() => {
                                                                setDay(2);
                                                            }}/>
                                                            <Checkbox name="6 Hours" checked={day === 3} onCheck={() => {
                                                                setDay(3);
                                                            }}/>
                                                            <Checkbox name="12 Hours" reverse={true} checked={day === 4} onCheck={() => {
                                                                setDay(4);
                                                            }}/>
                                                            <Checkbox name="24 Hours" checked={day === 5} onCheck={() => {
                                                                setDay(5);
                                                            }}/>
                                                            <Checkbox name="36 Hours" reverse={true} checked={day === 6} onCheck={() => {
                                                                setDay(6);
                                                            }}/>
                                                            <Checkbox name="48 Hours" checked={day === 7} onCheck={() => {
                                                                setDay(7);
                                                            }}/>
                                                            <Checkbox name="72 Hours" reverse={true} checked={day === 8} onCheck={() => {
                                                                setDay(8);
                                                            }}/>
                                                        </div>
                                                    ) : (
                                                        <div className="grid grid-cols-2 gap-y-0.5">
                                                            <Checkbox name="Today" checked={day === 1} onCheck={() => {
                                                                setDay(1);
                                                            }}/>
                                                            <Checkbox name="Yesterday" reverse={true} checked={day === 2} onCheck={() => {
                                                                setDay(2);
                                                            }}/>
                                                        </div>
                                                    )}
                                                </>
                                            )}
                                        </div>
                                    </div>
                                    {situation === "severe" && (
                                        <div className="flex justify-between space-x-2 bg-lwbg rounded-lg text-white py-2 px-3.5 mt-1.5">
                                            <div>
                                                <Checkbox name="Category" checked={outlookType === "category"} onCheck={() => setOutlookType("category")}/>
                                                {(day === 1 || day === 2) && <Checkbox name="Tornado" className="mt-1" checked={outlookType === "tornado"} onCheck={() => setOutlookType("tornado")}/>}
                                            </div>
                                            <div>
                                                {day === 3 && situation === "severe" && <Checkbox name="Probs" checked={outlookType === "probabilistic"} onCheck={() => setOutlookType("probabilistic")}/>}
                                                {(day === 1 || day === 2) && <Checkbox name="Wind" reverse={true} checked={outlookType === "wind"} onCheck={() => setOutlookType("wind")}/>}
                                                {(day === 1 || day === 2) && <Checkbox name="Hail" className="mt-1" reverse={true} checked={outlookType === "hail"} onCheck={() => setOutlookType("hail")}/>}
                                            </div>
                                        </div>
                                    )}
                                    <div className=" items-center justify-between space-x-2 bg-lwbg rounded-lg text-white py-2 px-3.5 mt-1.5 mb-2">
                                        <div className="flex items-center justify-center space-x-3">
                                            <Button name={isPlaying ? "Stop" : "Play"} className="text-white !bg-[#00BFFF]"
                                                    func={handlePlayPause}/>
                                            <input type="range" min={0} max={frames.length - 1} value={frameValue} onChange={e => {
                                                setFrameValue(e.target.value);
                                            }} className="bg-lwbg focus:outline-none"/>
                                        </div>
                                        {/*<div className="text-gray-100 text-sm font-semibold text-center">RADAR COMING BACK SOON</div>*/}
                                        <div className="text-gray-100 text-sm font-semibold text-center"><RMoment format={`hh:mm a`} unix className="text-xs-md font-medium ">{frame.time}</RMoment></div>
                                        {/*<div>*/}
                                        {/*    <div className="mb-1.5">*/}
                                        {/*        <RMoment format={`hh:mm a`} unix className="text-xs-md font-medium ">*/}
                                        {/*            {frame.time}*/}
                                        {/*        </RMoment>*/}
                                        {/*    </div>*/}
                                        {/*    <Button name={isPlaying ? "Stop" : "Play"} className="text-white !bg-[#00BFFF]" func={handlePlayPause}/>*/}
                                        {/*</div>*/}
                                        {/*<input type="range" min={0} max={frames.length - 1} value={frameValue} onChange={e => {*/}
                                        {/*    setFrameValue(e.target.value);*/}
                                        {/*}} className="bg-gray-300/30 dark:bg-gray-700/30 focus:outline-none"/>*/}
                                    </div>
                                </div>
                            )}
                        </>
                    )}
                </div>
            </div>
            <div className={app ? "margin-top-app margin-bottom-app" : top ? "margin-top" : undefined} style={{zIndex: "60", marginTop: app ? "0" : top ? "-35px" : "0", minWidth: "275x"}}>
                <div ref={mapContainer} className="map" style={{width: "100%", height: app ? "calc(100vh - 80px)" : height === undefined ? (top ? "calc(100vh - 64px - 41px)" : "calc(100vh - 64px - 41px)") : height, borderRadius: borderRadius ? "6px" : ""}}/>
            </div>
        </>
    );
}