import { useEffect, useState } from 'react';

import { NavLink, useLocation } from 'react-router-dom';
import { useFetchAsync } from '../../utils/FetchUtil';

import LocationPreview from '../location/LocationPreview';

import Loader from 'react-loader-spinner';
import clsx from 'clsx';

import {
    addLocation,
    clearLocations,
    currentLocationGeo,
    getLocations,
    getNearestCity, usePage
} from '../../utils/LocationUtil';

export default function Search({small, mobile}) {
    const page = usePage(true, "");
    const loc = useLocation();

    const [search, setSearch] = useState('');
    const [typing, setTyping] = useState(false);
    const [focusing, setFocusing] = useState(false);
    const [typingTimeout, setTypingTimeout] = useState(0);
    const [results, setResults] = useState([]);
    const [locations, setLocations] = useState(getLocations());
    const [fetch, setFetch] = useState(false);

    useFetchAsync(`/api/cities/${fetch}`, {method: 'POST'}, (data) => {
        if(fetch !== false) {
            setTyping(false);
            setResults(data);
        }

        setFetch(false);
    }, (err) => {
        setFetch(false);
    });

    useEffect(() => {
        if(small !== undefined && small)
            document.addEventListener('locationChange', onLocationChange);

        return () => {
            if(small !== undefined && small)
                document.removeEventListener('locationChange', onLocationChange);
        }
    }, [locations, small]);

    useEffect(() => {
        setFocusing(false);
        setSearch('');
    }, [loc.pathname])

    function onLocationChange() {
        setLocations(getLocations());
    }

    function onChange(e) {
        if (typingTimeout)
            clearTimeout(typingTimeout);

        setSearch(e.target.value);
        setTyping(true);
        setTypingTimeout(setTimeout(() => {
            if(e.target.value.split(' ').join('') !== "") {
                setFetch(e.target.value);
            }
        }, 800));
    }

    function focusEnter(e) {
        if (e.currentTarget === e.target) {
            setFocusing(true);
        }
    }

    function focusLeave(e) {
        if (e.currentTarget === e.target && e.relatedTarget === null)
            setFocusing(false);

        if(e.relatedTarget !== null && e.relatedTarget.id === "list") {
            setTimeout(() => {
                setFocusing(false);
                setSearch('');
            }, 200);
        }
    }

    function currentLocationCity() {
        currentLocationGeo((lat, lng) => {
            if(lat !== undefined) {
                getNearestCity(lat, lng, (city) => {
                    if (city !== null && city.zip_codes.length > 0) {
                        addLocation(city);

                        window.location.href = (process.env.NODE_ENV !== `production` ? "http://localhost:3000" : "https://letsweather.com") + `/en/${city.country_code.toLowerCase()}/${city.name.toLowerCase().replaceAll(" ", "-")}/${city.zip_codes[0]}${page === "home" ? "" : page}${city.offsetId !== undefined ? `#${city.offsetId}` : ""}`;
                    }
                });
            }
        })
    }

    const show = search !== "" && !typing;
    const isHome = small === undefined;
    const isMobile = mobile !== undefined;
    const max = isHome ? 8 : 12;

    return (
        <div tabIndex="0" className="w-full outline-none">
            <div className={clsx("flex bg-gray-800/10 shadow-sm", isHome ? 'mx-auto h-12 p-2 max-w-3xl' : 'h-8 p-1 max-w-[14.5rem]', focusing ? 'rounded-t-lg border border-lwbg3 border-b-transparent' : 'rounded-lg border border-lwbg3')}>
                <div className={clsx("text-gray-700", isHome ? "pt-[5px] ml-2 " : "pt-1 ml-1.5")}>
                    <svg xmlns="http://www.w3.org/2000/svg" className={clsx(isHome ? "w-5 h-5" : "w-4 h-4")} viewBox="0 0 16 16">
                        <path fill="currentColor" d="M15.784 13.68l-4.096-4.096c.585-.955.94-2.067.94-3.27a6.315 6.315 0 1 0-6.314 6.314 6.253 6.253 0 0 0 3.27-.937l4.096 4.094a.751.751 0 0 0 1.052 0l1.052-1.052a.75.75 0 0 0 0-1.053zm-9.47-3.157a4.21 4.21 0 1 1 0-8.419 4.21 4.21 0 0 1 0 8.42z"/>
                    </svg>
                </div>
                <input onFocus={focusEnter.bind(this)} onBlur={focusLeave.bind(this)} value={search} onChange={onChange} placeholder="Search" className={clsx("w-full bg-transparent mx-3 dark:text-white font-medium focus:outline-none", isHome ? "text-lg" : "text-sm")}/>
                {search.split(' ').join('') !== "" && typing && (
                    <div className="flex flex-center mr-1.5">
                        <Loader type="Oval" color="#00BFFF" className={isHome ? "mt-1" : "mt-0.5"} height={isHome ? 25 : 20} width={isHome ? 30 : 15} />
                    </div>
                )}
            </div>
            {focusing && (
                <div className={clsx('absolute transform w-full z-50', isMobile ? 'max-w-[14.5rem]' : (isHome ? 'min-w-3xl max-w-3xl left-1/2 -translate-x-1/2 px-4 md:px-0' : 'min-w-[14.5rem] max-w-[14.5rem] px-2 sm:px-0'))} id="list">
                    <div className="rounded-b-lg shadow-lg text-gray-800 truncate bg-[#0C0F15] border border-t-transparent border-lwbg3">
                        {show ? (
                            <ul>
                                <li>
                                    <div className={clsx("flex cursor-pointer transition-colors duration-200 hover:bg-gray-800/20", small === undefined ? "p-3 text-md" : "p-2 text-sm")} onMouseDown={() => currentLocationCity()}>
                                        <svg xmlns="http://www.w3.org/2000/svg" className="ml-1 mt-1" width="14" height="14" viewBox="0 0 14 18">
                                            <path fill="#00BFFF" fillOpacity=".996" fillRule="evenodd" d="M13.696 0l-3.304 17.722-3.496-5.944L0 11.722z"/>
                                        </svg>
                                        <div className="font-medium text-gray-300 ml-2">Use your current location</div>
                                    </div>
                                </li>
                                {results.filter(l => l.zipCodes.length > 0).slice(0, max).map((r, i) => (
                                    <li key={i}>
                                        <NavLink id="list" to={`/en/${r.countryCode.toLowerCase()}/${r.name.toLowerCase().replaceAll(" ", "-")}/${r.zipCodes[0]}${page}`}>
                                            <div className={clsx("transition-colors duration-200 hover:bg-gray-800/20 text-gray-300", isHome ? "p-3" : "p-2 text-sm")}>
                                                <div className="pl-1">
                                                    {r.fullName}
                                                </div>
                                            </div>
                                        </NavLink>
                                    </li>
                                ))}
                            </ul>
                        ) : (
                            <ul>
                                <li>
                                    <div className={clsx("flex cursor-pointer transition-colors duration-200 hover:bg-gray-800/20", small === undefined ? "p-3 text-md" : "p-2 text-sm")} onMouseDown={() => currentLocationCity()}>
                                        <svg xmlns="http://www.w3.org/2000/svg" className="ml-1 mt-1" width="14" height="14" viewBox="0 0 14 18">
                                            <path fill="#00BFFF" fillOpacity=".996" fillRule="evenodd" d="M13.696 0l-3.304 17.722-3.496-5.944L0 11.722z"/>
                                        </svg>
                                        <div className="font-medium text-gray-300 ml-2">Use your current location</div>
                                    </div>
                                </li>
                                {!isHome && locations.filter(loc => loc !== undefined).slice(0, max).map((l, i) => (
                                    <li key={i}>
                                        <LocationPreview search={true} loc={l} showAlerts={true}/>
                                    </li>
                                ))}
                                {!isHome && locations.length > 1 && (
                                    <div className="py-2 text-center text-xs-md font-semibold transition-colors duration-200 hover:bg-gray-800/20 font-medium text-gray-300 cursor-pointer" onClick={() => clearLocations()}>
                                        Clear all
                                    </div>
                                )}
                            </ul>
                        )}
                    </div>
                </div>
            )}
        </div>
    )
}