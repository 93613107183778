import { pickTextColorBasedOnBgColorSimple } from '../../../utils/IconUtil';

import Card from '../../ui/Card';

import Moment from '../../../utils/TimeUtil';

export default function AirQuality({air}) {
    return air === undefined || air === null ? null : (
        <Card className="px-4">
            <div className="flex items-center justify-between">
                <div>
                    <span className="text-sm font-semibold tracking-wider text-white uppercase">Air Quality</span>
                    <div className="text-xs font-medium">
                        <Moment unix={air.time}/>
                    </div>
                </div>
                <div>
                    <div className="rounded-full px-3 py-1 text-sm dark:text-white font-medium" style={{color: pickTextColorBasedOnBgColorSimple("#" + air.pollution.color, '#FFF', '#000'), backgroundColor: "#" + air.pollution.color}}>
                        {air.pollution.name} &#x2022; {air.aqi} AQI
                    </div>
                </div>
            </div>
            <div className="text-sm pt-1.5 dark:text-gray-300 font-medium">
                {air.pollution.health} {air.pollution.statement || ""}
            </div>
        </Card>
    )
}
