import Search from '../components/interactive/Search';
import LocationPreview from '../components/location/LocationPreview';

import { addLocation, currentLocationIP, getLocations, getNearestCity } from '../utils/LocationUtil';
import { useEffect, useState } from 'react';

export default function Home() {
    const [locations, setLocations] = useState(getLocations());

    function onLocationChange() {
        setLocations(getLocations());
    }

    useEffect(() => {
        if(locations.length === 0) {
            currentLocationIP((lat, lng) => {
                if(lat !== undefined) {
                    getNearestCity(lat, lng, (city) => {
                        addLocation(city, true);
                    });
                }
            });
        }

        document.addEventListener('locationChange', onLocationChange);

        return () => document.removeEventListener('locationChange', onLocationChange);
    }, [locations]);

    return (
        <div className="pt-[62px]">
            <div className="mx-4 lg:mx-0">
                <Search/>
            </div>

            <div className="md:flex md:justify-center md:items-center space-y-5 md:space-x-8 sm:px-6 md:px-0 md:space-y-0 max-w-[55rem] mt-6 w-full mx-auto">
                {locations.filter(loc => loc !== undefined).slice(0, 3).map((location, i) => (
                    <div key={i} className="w-[227px] mx-auto md:mx-0 z-auto">
                        <LocationPreview loc={location} big={true} showAlerts={true}/>
                    </div>
                ))}
            </div>
        </div>
    );
}