import { PROB_NAMES, PROB_NAMES2 } from '../location/sidebar/WSO';
import { TYPE_DESC, TYPE_SIG } from '../location/sidebar/Outlook';

import { Loading } from '../ui/Icons';
import { capitalize } from '../../utils/WordUtil';

import clsx from 'clsx';

import Moment from '../../utils/TimeUtil';
import React from 'react';

const RegionNames = ["Unknown", "Europe<sup>1</sup>", "Oceania", "North America", "Asia", "South America", "Hungary", "Africa", "Japan", "Japan", "Estonia", "Europe", "Europe", "North America", "Japan", "Unknown", "Unknown"];

function toRegularTime(militaryTime) {
    var [hours, minutes, seconds] = militaryTime.split(':');
    if (hours === "00") {
        hours = "01"
    }
    return `${(hours > 12) ? hours - 12 : hours}:${minutes}${seconds ? `:${seconds}` : ''} ${(hours >= 12) ? 'pm' : 'am'}` + " UTC";
}

export default function MapPopup({popup, loading, outlookType, showOutlooks, showAlerts, situation, onUrlClick, popupFeature}) {
    let o = undefined;

    const isFire = situation === "fire";

    if(popupFeature !== null && popupFeature !== undefined) {
        return situation === "snowfall" ? (
            <>
                <div className="flex justify-between items-center">
                    <div className="font-bold tracking-wide text-white text-base truncate mr-5" style={{color: popupFeature.properties.color}}>{popupFeature.properties.location}, {popupFeature.properties.state}</div>
                    <div>
                        <button id="hide-popup" className="px-1.5 -mt-1 text-lg">×</button>
                    </div>
                </div>
                <div className="font-medium text-white text-md space-y-1 mt-0.5">
                    <div className="text-xs text-gray-200">
                        {popupFeature.properties.remarks}
                    </div>
                    <div className="text-xs-md text-gray-300">
                        <div><span className="font-semibold">Amount:</span> {popupFeature.properties.amount}"</div>
                        <div><span className="font-semibold">Time:</span> <Moment unix={popupFeature.properties.valid_time * 1000}/></div>
                    </div>
                </div>
            </>
        ) : (
            <>
                {situation === "storms" ? (
                    <div>
                        <div className="flex justify-between items-center">
                            <div className="flex items-center font-bold tracking-wide text-white text-base truncate mr-5" style={{color: popupFeature?.properties?.color}}>{capitalize(popupFeature?.properties?.type?.toLowerCase())} Report <span className="font-base text-xs ml-2.5 ">({popupFeature.properties.lat}, {popupFeature.properties.lon})</span></div>
                            <div>
                                <button id="hide-popup" className="px-1.5 -mt-1 text-lg">×</button>
                            </div>
                        </div>
                        <div>{!popupFeature.properties.today && "yesterday "}at {toRegularTime(popupFeature.properties.time)}</div>
                        <div className="font-medium text-white text-md space-y-1 mt-0.5">
                            <div className="text-xs-md text-gray-300">
                                {popupFeature.properties.type === "HAIL" && <div><span className="font-semibold">Size:</span> {popupFeature.properties.size === -1 ? "Unknown" : popupFeature.properties.size.toFixed(2)}</div>}
                                {popupFeature.properties.type === "WIND" && <div><span className="font-semibold">Speed:</span> {popupFeature.properties.speed === - 1 ? "Unknown" : popupFeature.properties.speed}</div>}
                                {popupFeature.properties.type === "TORNADO" && <div><span className="font-semibold">Scale:</span> {popupFeature.properties.fScale === "UNK" ? "Unknown" : popupFeature.properties.fScale}</div>}
                                <div><span className="font-semibold">Location:</span> {popupFeature.properties.location}, {popupFeature.properties.state} ({popupFeature.properties.county} County)</div>
                                <div><span className="font-semibold">Office:</span> {popupFeature.properties.office}</div>
                                {popupFeature.properties.comments !== "" && <div><span className="font-semibold">Comments:</span> {popupFeature.properties.comments}</div>}
                            </div>
                        </div>
                    </div>
                ) : (
                    <>
                        <div className="flex justify-between items-center">
                            <div className="font-bold tracking-wide text-white text-base truncate mr-5" style={{color: popupFeature.properties.color}}><Moment date={true} unix={popupFeature.properties.time.toString().substring(0, 13)}/> at <Moment time={true} unix={popupFeature.properties.time.toString().substring(0, 13)}/></div>
                            <div>
                                <button id="hide-popup" className="px-1.5 -mt-1 text-lg">×</button>
                            </div>
                        </div>
                        <div className="font-medium text-white text-md space-y-1 mt-0.5">
                            <div className="text-xs-md text-gray-300">
                                <div><span className="font-semibold">Region:</span> {popupFeature.properties.region} ({RegionNames[popupFeature.properties.region]})</div>
                                <div><span className="font-semibold">Delay:</span> {popupFeature.properties.delay} seconds</div>
                                <div><span className="font-semibold">Sigs:</span> {JSON.parse(popupFeature.properties.sig).length}</div>
                            </div>
                        </div>
                    </>
                )}
            </>
        )
    }

    if(popup === null) {
        return (
            <div className="font-bold tracking-wide text-white text-base truncate mr-5">
                <div className="font-medium text-red-300 text-red-400 text-md mt-1">
                    An error has occurred.
                </div>
            </div>
        )
    }

    if(popup !== undefined && popup.outlooks !== undefined && popup.outlooks.length > 0) {
        if(!isFire && popup.outlooks.some(o => o.label.includes("Significant"))) {
            const withoutSignificant = popup.outlooks.filter(o => !o.label.includes("Significant"));

            o = [withoutSignificant[withoutSignificant.length -1], popup.outlooks.filter(o => o.label.includes("Significant"))[0]];
        } else {
            o = isFire ? popup.outlooks : [popup.outlooks[popup.outlooks.length - 1]];
        }
    }

    return (
        <>
            <div className="flex justify-between items-center font-bold tracking-wide text-white text-base truncate">
                <div>
                    <a className="!text-white cursor-pointer" href={loading ? "#" : `/en/${popup?.country_code?.toLowerCase() || ""}/${(popup?.name?.toLowerCase() || "").replaceAll(" ", "-")}/${popup?.zip_codes[0]}#${popup?.offsetId}`} onClick={() => onUrlClick(loading ? "#" : `/en/${popup?.country_code?.toLowerCase() || ""}/${(popup?.name?.toLowerCase() || "").replaceAll(" ", "-")}/${popup?.zip_codes[0]}#${popup?.offsetId}`)}>{popup === undefined || popup.loading ? "Loading..." : popup.city.name}</a>
                </div>
                <div>
                    <button id="hide-popup" className="px-1.5 -mt-1 text-lg">×</button>
                </div>
            </div>
            {!loading ? (
                <div className="font-medium text-white text-md space-y-2 mt-1.5 w-[330px]">
                    {showAlerts && (
                        <>
                            {popup?.alerts.map((a, i) => (
                                <div key={a.id}>
                                    <a href={`/alert/${a.id}`} onClick={() => onUrlClick(`/alert/${a.id}`)} className="font-semibold cursor-pointer" style={{color: a.color.hexColor}}>{a.event}</a>
                                    <div className="text-xs-md text-gray-200">{a.headline.split(" issued ")[1]}</div>
                                    {a.isInAlert !== undefined && (
                                        <div className={clsx("text-[12px] mt-0.5 font-medium", a.isInAlert ? "text-red-400" : "text-yellow-400")}>This location is {a.isInAlert ? "inside" : "outside"} the immediate area affected by this alert.</div>
                                    )}
                                </div>
                            ))}
                        </>
                    )}
                    {situation === "severe" && o !== undefined && showOutlooks && (
                        <>
                            {o.filter(s => !s.label.includes("Significant")).map((outlook, i) => (
                                <div key={i} className={clsx(i !== 0 && "!mt-1")}>
                                    <div className="font-semibold text-md" style={{color: outlook.stroke === "#000000" ? "#999" : outlook.stroke}}>{o.some(s => s.label.includes("Significant ")) ? outlook.label.replace("%", "%#") : outlook.label}</div>
                                    {(outlook?.category?.description !== undefined || outlookType !== 'category') && (
                                        <div className="text-xs-md text-gray-200">
                                            {outlookType === 'category' ? outlook.category.description : TYPE_DESC[outlookType || 'probabilistic']}
                                            {o.some(s => s.label.includes("Significant ")) && (outlookType === 'category' ? " " + outlook.category.descriptionMore : <div><br/>{TYPE_SIG[outlookType || 'probabilistic']}</div>)}
                                        </div>
                                    )}
                                </div>
                            ))}
                        </>
                    )}
                    {showOutlooks && situation === "wsos" && popup !== undefined && popup?.wsos !== undefined && popup?.wsos.length > 0 && (
                        <>
                            {popup?.wsos?.filter((w, i) => i === popup?.wsos.length - 1).map((w, i) => (
                                <div key={i}>
                                    <div className="font-semibold text-md" style={{color: w.hex}}>{PROB_NAMES[w.probability]}</div>
                                    <div className="text-xs-md text-gray-200">
                                        There is a {PROB_NAMES2[w.probability]} chance of exceeding warning criteria from <Moment time={false} unix={w.valid}/> at <Moment timeDay={true} unix={w.valid}/> to <Moment time={false} unix={w.valid + 8.64e+7}/> at <Moment timeDay={true} unix={w.valid + 8.64e+7}/>.
                                    </div>
                                </div>
                            ))}
                        </>
                    )}
                    {showOutlooks && situation === "wssis" && popup !== undefined && popup.wssis !== undefined && popup?.wssis.length > 0 && (
                        <>
                            {popup?.wssis.filter((w, i) => i === popup.wssis.length - 1).map((w, i) => (
                                <div key={i}>
                                    <div className="font-semibold" style={{color: w.hex}}>{capitalize(w.impact.toLowerCase())} Impact</div>
                                    <div className="text-xs-md">{w.impactDesc.split("\n")[0]}</div>
                                    <div className="font-normal text-xs" dangerouslySetInnerHTML={{__html: w.impactDesc.split("\n").slice(1).join("\n").replaceAll("\n", '<br/>')}}/>
                                </div>
                            ))}
                        </>
                    )}
                    {showOutlooks && situation === "eros" && popup !== undefined && popup.eros !== undefined && popup?.eros.length > 0 && (
                        <>
                            {popup?.eros.filter((w, i) => i === popup.eros.length - 1).map((e, i) => (
                                <div key={i}>
                                    <div className="font-semibold" style={{color: e.hex}}>{capitalize(e.category.toLowerCase())} Impact</div>
                                    <div className="text-xs-md">{e.impactDesc.split("\n")[0]}</div>
                                    <div className="font-normal text-xs" dangerouslySetInnerHTML={{__html: e.impactDesc.split("\n").slice(1).join("\n").replaceAll("\n", '<br/>')}}/>
                                </div>
                            ))}
                        </>
                    )}
                    {showOutlooks && situation === "forecasts" && (
                        <>
                            {popup?.forecasts.map((forecast, i) => (
                                <div key={i} className={clsx(i !== 0 && "!mt-1")}>
                                    <div className="font-semibold text-md" style={{color: forecast.stroke === "#000000" ? "#999" : forecast.stroke}}>{forecast.label}</div>
                                    <div className="text-xs-md text-gray-200">
                                        {forecast.description}
                                    </div>
                                </div>
                            ))}
                        </>
                    )}
                    {((!showAlerts && !showOutlooks) || (situation !== "forecasts" || (popup?.forecasts !== undefined && popup?.forecasts.length === 0)) && (popup?.eros !== undefined && popup?.eros.length === 0) && (popup?.wsos !== undefined && popup?.wsos.length === 0) && (popup.wssis !== undefined && popup.wssis.length === 0) && (o === undefined || !showOutlooks) && (popup.alerts.length === 0 || !showAlerts)) && <div className="text-sm text-center">No information for this location.</div>}
                </div>
            ) : <Loading/>}
        </>
    )
}