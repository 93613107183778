import { useState } from 'react';

import { OpenClose } from '../ui/Icons';
import { Map } from '../map/Map';

import Button from '../interactive/Button';

import { NewspaperIcon } from '@heroicons/react/24/outline';

import Moment from '../../utils/TimeUtil';

export default function Product({product, o = false, showMap = true}) {
    const [open, setOpen] = useState(o);
    const [map, setMap] = useState(false);

    return (
        <>
            <div onClick={() => setOpen(!open)} className="flex justify-between items-center text-xl cursor-pointer">
                <div className="flex text-[1.05rem] font-semibold items-center">
                    <div className="flex items-center mr-2 h-5 w-5 bg-amber-100 rounded-full">
                        <NewspaperIcon className="h-[13px] w-[13px] mx-auto text-gray-800"/>
                    </div>
                    <div className="text-white uppercase tracking-tight text-[16px] font-bold">{product.productName}</div>
                </div>
                <div className="flex items-center font-medium text-xs-md text-gray-50 float-right">
                    <span className="hidden sm:block">
                        <Moment unix={product.issuanceTime}/>
                    </span>
                    <svg className="flex ml-2 mb-0.5 h-3.5 w-3.5 text-white" viewBox="0 0 24 24">
                        <OpenClose open={open}/>
                    </svg>
                </div>
            </div>
            {open && (
                <div className="px-3 pb-2 text-sm text-gray-200">
                    <div dangerouslySetInnerHTML={{__html: product.productText.replaceAll("\n", '<br/>')}}/>

                    {product.geometry !== undefined && showMap && (
                        <>
                            <div className="text-white uppercase tracking-tight font-semibold pt-2">Map</div>
                            {map ? <div className="mt-2 mb-1.5"><Map zoom={2.5} show={{alerts: [{geometry: product.geometry, id: product.id, color: {hexColor: "#FFFFF7"}}], radar: false, popups: false, outlooks: false}} borderRadius={true} height="500px"/></div> : <Button className="mt-1.5 mb-2" name="Open Map" func={() => setMap(true)}/>}
                        </>
                    )}
                </div>
            )}
        </>
    )
}
