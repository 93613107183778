import { Helmet } from '../components/head/Helmet';
import { useEffect } from 'react';
import { useLetsWeatherContext } from '../utils/LocationUtil';

import Button from '../components/interactive/Button';

export default function NotFound() {
    const { location, setLocation } = useLetsWeatherContext();

    useEffect(() => {
        setLocation(null);
    });

    return (
        <div style={{height: "calc(100vh - 64px - 77px)"}}>
            <Helmet title="404 - LetsWeather"/>
            <div className="flex items-center justify-center h-full text-center">
                <div>
                    <h1 className="font-semibold text-7xl">404</h1>
                    <p className="text-sm text-gray-400">{"<"} Page Not Found {"/>"}</p>
                    <Button name="Home" func="/" className="w-fit mx-auto mt-2.5"/>
                </div>
            </div>
        </div>
    )
}