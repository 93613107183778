import Card from '../../ui/Card';

import { pickTextColorBasedOnBgColorSimple } from '../../../utils/IconUtil';
import { NavLink, useParams } from 'react-router-dom';
import { capitalize } from '../../../utils/WordUtil';
import { useState } from 'react';
import { useHash, useUrl } from '../../../utils/LocationUtil';

import Button from '../../interactive/Button';

import Moment from '../../../utils/TimeUtil';

export default function WSSI({wssi}) {
    const offset = useHash();
    const url = useUrl();
    const [day, setDay] = useState(getFirst(wssi?.wssis || []));

    const time = wssi === undefined  ? 0 : Math.max(wssi.wssis.map(s => s.issued));
    const w = wssi === undefined ? undefined : wssi.wssis.filter(s => s.day === day)[0];
    const days = wssi === undefined ? [] : wssi.wssis.map(s => s.day);

    return w === undefined || wssi === undefined ? null : (
        <Card className="px-4">
            <div className="flex items-center justify-between">
                <div>
                    <span className="text-sm font-semibold tracking-wider text-white uppercase">{w.impact === "MODERATE" ? "Winter Storm Severity IN..." : "Winter Storm Severity Index"}</span>
                    <div className="text-xs font-medium">
                        <Moment unix={w.issued}/>
                    </div>
                </div>
                <div>
                    <div className="rounded-full px-3 py-1 text-sm dark:text-white font-medium" style={{color: pickTextColorBasedOnBgColorSimple(w.impactHex, '#FFF', '#000'), backgroundColor: w.impactHex}}>
                        <NavLink to={`${url}/map?situation=wssi&day=${day}${offset === "" ? "" : `#${offset}`}`}>
                            {capitalize(w.impact.toLowerCase())}
                        </NavLink>
                    </div>
                </div>
            </div>
            <div className="text-sm pt-1.5 text-gray-300 font-medium">
                <NavLink to={`${url}/map?situation=wssi&day=${day}${offset === "" ? "" : `#${offset}`}`}>
                    <div>{w.impactDesc.split("\n")[0]}</div>
                    <div className="text-xs-md" dangerouslySetInnerHTML={{__html: w.impactDesc.split("\n").slice(1).join("\n").replaceAll("\n", '<br/>')}}/>
                </NavLink>
                <div className="mt-1.5">
                    <a className="text-xs text-sky-400 hover:underline" target="_blank" rel="noreferrer" href="https://www.wpc.ncep.noaa.gov/wwd/wssi/wssi.php">More info</a>
                </div>
            </div>
            {days.length > 0 && (
                <div className="border-t border-lwbg3 pt-3 mt-3">
                    <div className="flex text-md justify-evenly items-center">
                        {days.map((n, i) => <Button key={i} active={day === n} name={`Day ${n}`} className={day === n ? "border-lwbg3" : ""} func={() => setDay(n)}/>)}
                    </div>
                </div>
            )}
        </Card>
    )
}

function getFirst(wssi) {
    if(wssi === undefined) {
        return -1;
    } else if(wssi.some(s => s.day === 1)) {
        return 1;
    } else if(wssi.some(s => s.day === 2)) {
        return 2;
    } else if(wssi.some(s => s.day === 3)) {
        return 3;
    }

    return -1;
}
