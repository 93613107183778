export function isNight(sunset = -1) {
    if(sunset === -1) {
        const h = new Date().getHours();
        return !(h > 6 && h < 20);
    } else {
        return new Date().getTime() > sunset;
    }
}


export function pickTextColorBasedOnBgColorSimple(bgColor, lightColor, darkColor) {
    let color = (bgColor.charAt(0) === '#') ? bgColor.substring(1, 7) : bgColor;
    let r = parseInt(color.substring(0, 2), 16); // hexToR
    let g = parseInt(color.substring(2, 4), 16); // hexToG
    let b = parseInt(color.substring(4, 6), 16); // hexToB

    return (((r * 0.299) + (g * 0.73) + (b * 0.114)) > 186) ?
        darkColor : lightColor;
}