import Card from '../../ui/Card';

import { pickTextColorBasedOnBgColorSimple } from '../../../utils/IconUtil';
import { useState } from 'react';

import Button from '../../interactive/Button';

import Moment from '../../../utils/TimeUtil';
import moment from 'moment/moment';
import { NavLink, useParams } from 'react-router-dom';
import { useHash, useUrl } from '../../../utils/LocationUtil';
import { capitalize } from '../../../utils/WordUtil';

const PROB_COLORS = {
    "MARGINAL": "#03FF01",
    "SLIGHT": "#FFFF00",
    "MODERATE": "#EE2C2C",
    "HIGH": "#FF00FF"
}

const PROB_NAMES = {
    "MARGINAL": "5",
    "SLIGHT": "15",
    "MODERATE": "40",
    "HIGH": "70"
}

export default function ERO({ero}) {
    const url = useUrl();
    const offset = useHash();

    const [day, setDay] = useState(getFirst(ero?.eros || []));

    // const time = wso === undefined  ? 0 : Math.max(wso.wsos.map(s => s.issued));
    const e = ero === undefined ? undefined : ero.eros.filter(s => s.day === day)[0];
    const days = ero === undefined ? [] : ero.eros.map(s => s.day);

    return e === undefined || ero === undefined ? null : (
        <Card className="px-4">
            <div className="flex items-center justify-between">
                <div>
                    <span className="text-sm font-semibold tracking-wider text-white uppercase">Excessive Rain Outlook</span>
                    <div className="text-xs font-medium">
                        <Moment unix={e.issued}/>
                    </div>
                </div>
                <div>
                    <div className="rounded-full px-3 py-1 text-sm dark:text-white font-medium" style={{color: pickTextColorBasedOnBgColorSimple(PROB_COLORS[e.category], '#FFF', '#000'), backgroundColor: PROB_COLORS[e.category]}}>
                        <NavLink to={`${url}/map?situation=ero&day=${day}${offset === "" ? "" : `#${offset}`}`}>
                            {capitalize(e.category.toLowerCase())}
                        </NavLink>
                    </div>
                </div>
            </div>
            <div className="text-xs-md pt-1.5 dark:text-gray-300 font-medium">
                <NavLink to={`${url}/map?situation=ero&day=${day}${offset === "" ? "" : `#${offset}`}`}>
                    There is at least a {PROB_NAMES[e.category]}% chance risk of exceeding flash flood guidance within 25 mi of a point {e.day === 1 ? "today" : e.day === 2 ? "tomorrow" : `${moment.unix(e.start / 1000).format('dddd')}`}.
                </NavLink>
                <div className="mt-1.5">
                    <a className="text-xs text-sky-400 hover:underline" target="_blank" rel="noreferrer" href="https://www.wpc.ncep.noaa.gov/qpf/excessive_rainfall_outlook_ero.php">More info</a>
                </div>
            </div>
            {days.length > 0 && (
                <div className="border-t border-lwbg3 pt-3 mt-3">
                    <div className="flex text-md justify-evenly items-center">
                        {days.map((n, i) => <Button key={i} active={day === n} name={`Day ${n}`} className={day === n ? "border-lwbg3" : ""} func={() => setDay(n)}/>)}
                    </div>
                </div>
            )}
        </Card>
    )
}

function getFirst(ero) {
    if(ero === undefined) {
        return -1;
    } else if(ero.some(s => s.day === 1)) {
        return 1;
    } else if(ero.some(s => s.day === 2)) {
        return 2;
    } else if(ero.some(s => s.day === 3)) {
        return 3;
    } else if(ero.some(s => s.day === 4)) {
        return 4;
    } else if(ero.some(s => s.day === 5)) {
        return 5;
    }

    return -1;
}
