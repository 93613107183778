import { NavLink, useLocation } from 'react-router-dom';

import { isNight } from '../utils/IconUtil';
import { IconText } from '../components/ui/Icons';
import { temperature, useUnit } from '../utils/ConversionUtil';

import Logo from '../assets/images/Logo';
import Search from '../components/interactive/Search';
import Button from '../components/interactive/Button';

import clsx from 'clsx';
import { useLetsWeatherContext } from '../utils/LocationUtil';
import { usePage } from '../utils/LocationUtil';

const Pages = [
    // {
    //     name: 'Alerts',
    // },
    {
        name: 'Radar'
    },
    {
        name: 'Cameras',
        new: true
    },
    // {
    //     name: 'Stories'
    // }
]

export default function Header() {
    const { location } = useLetsWeatherContext();

    const page = usePage(false, "home", false);
    const loc = useLocation();
    const unit = useUnit();

    return (
        <div className="sticky top-0 md:bg-lwbg" style={{zIndex: "500"}}>
            <div className={clsx("relative z-100 h-0 my-0 mx-auto", loc.pathname === "/" ? "max-w-[1260px]" : "max-w-[1360px]")}>
                <Logo node={(
                    <>
                        {loc.pathname.startsWith("/en/") && location !== undefined && (
                            <div className="flex items-center space-x-2 font-semibold lg:font-medium text-sm mt-[2px] md:mt-0 truncate">
                                {location === null ? (
                                    <div>Loading...</div>
                                ) : (
                                    <>
                                        <div>
                                            {location.name}
                                        </div>
                                        <div>
                                            {temperature(unit, location.temperature)}
                                        </div>
                                        <div style={{zIndex: "500"}}>
                                            <IconText size={20} text={location.text} night={isNight()}/>
                                            {location.alerts.length > 0 && (
                                                <div className="bg-red-600/60 absolute -mt-[1.6rem] ml-[0.7rem] rounded-full flex items-center text-center text-[10px] w-[15px] h-[15px]">
                                                   <div className="mx-auto pr-[1px] pb-[1px]">
                                                       {location.alerts.length}
                                                   </div>
                                                </div>
                                            )}
                                        </div>
                                    </>
                                )}
                            </div>
                        )}
                    </>
                )}/>
            </div>
            <div className={clsx((page === "map" || page === "radar") && "absolute min-w-full h-[62px] border-b border-b-lwbg3", !loc.pathname.startsWith("/en/") && !loc.pathname !== "/map" && !loc.pathname !== "/radar" && "pb-3")}>
                <header className={clsx(page === "radar" || page === "map" ? "h-[61px]" : "h-[62px]", page === "map" ? "mb-[-63px]" : "mb-[-62px]", page !== "map" && page !== "radar" && "border-b border-lwbg3", "mx-auto hidden md:flex flex-row justify-center items-center p-[22px] mx-auto relative z-10 duration-300 transition-opacity bg-lwbg", page === "home" ? "max-w-[1260px]" : "max-w-[1360px]")}>
                    <div className="text-[13px] font-medium tracking-[0.2px] flex items-center gap-[32px]">
                        {Pages.map((page, i) => (
                            <div key={i} className={page.new !== undefined && page.new ? "flex items-center gap-[6px]" : ""}>
                                <NavLink to={`/${page.name.toLowerCase()}`} className={({isActive}) => !isActive ?
                                    "text-white opacity-[60%] hover:opacity-100 transition-opacity duration-300" : "text-white"
                                }>
                                    {page.name}
                                </NavLink>
                                {page.new !== undefined && page.new && (
                                    <span
                                        className="relative inline-flex items-center py-[1px] px-[6px] bg-none rounded-[10px] gradient text-[#2bafff] text-[10px] tracking-[0.2px] bf-gradient">New</span>
                                )}
                            </div>
                        ))}
                    </div>
                    <div
                        className="absolute right-[24px] flex flex-row items-center justify-end gap-[18px] text-[13px] font-medium tracking-[0.2px] duration-200 transition-all transition">
                        {loc.pathname !== "/" && (
                            <div className="w-auto mr-2.5">
                                <Search small={true} />
                            </div>
                        )}
                        {/*<NavLink to="/log-in" className={({isActive}) => !isActive ?*/}
                        {/*    "text-white opacity-[60%] hover:opacity-100 transition-opacity duration-300 hidden xl:flex" : "text-white hidden xl:flex"*/}
                        {/*}>Log In</NavLink>*/}
                        {/*<Button name="Sign Up" func="/sign-up"*/}
                        {/*        className="hidden xl:flex text-white opacity-[60%] hover:opacity-100 transition-opacity duration-300 tracking-[0.2px]"/>*/}
                    </div>
                </header>
            </div>
        </div>
    )
}