import Card from '../../components/ui/Card';
import Day from '../../components/day/Day';

export default function Weekend({data, loading, error}) {
    return (
        <div className="space-y-4"> {/* divide-y divide-gray-800/40 -mx-0.5 */}
            {data.days.length > 0 && data.days[0].length > 0 && (
                <div className="space-y-2.5 px-0.5">
                    {data.days[0].map((d, i) => (
                        <Card className="px-2" key={i}>
                            {i === 0 && <div className="text-center mt-0.5 mx-2 mb-2.5 text-md font-semibold border-b pb-2.5 border-gray-800/30">This Weekend</div>}
                            <Day o={i === 0} day={d}/>
                        </Card>
                    ))}
                </div>
            )}
            {data.days.length > 1 && data.days[1].length > 1 && (
                <div className="pt-4 space-y-2.5 px-0.5">
                    {data.days[1].map((d, i) => (
                        <Card className="px-2" key={i}>
                            {i === 0 && <div className="text-center mt-0.5 mx-2 mb-2.5 text-md font-semibold border-b pb-2.5 border-gray-800/30">Next Weekend</div>}
                            <Day o={i === 0} day={d}/>
                        </Card>
                    ))}
                </div>
            )}
        </div>
    )
}