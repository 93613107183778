import { useFetch } from '../utils/FetchUtil';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Loading } from '../components/ui/Icons';

import { Alert as AlertComponent } from '../components/alerts/Alert';
import Card from '../components/ui/Card';
import PageHeader from '../components/page/PageHeader';
import { useLetsWeatherContext } from '../utils/LocationUtil';

export default function Alert() {
    const { location, setLocation } = useLetsWeatherContext();
    const { data, loading, error, fetchNow } = useFetch();

    const params = useParams();

    useEffect(() => {
        setLocation(null);
        fetchNow(`/api/alert/${params.id}`, {method: 'POST'});
    }, [params.id]);

    return loading || data === undefined ? <Loading/> : (
        <div className="mx-auto max-w-d mt-2">
            {data.alerts.length > 0 ? (
                <Card>
                    <AlertComponent alert={data.alerts[0]} o={true}/>
                </Card>
            ) : (
                <PageHeader title="Alert" data={data} emptyMessage="Unknown alert."/>
            )}
        </div>
    )
}