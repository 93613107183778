import { Fragment, useState } from 'react';

import { NavLink } from 'react-router-dom';
import { InfoIcon, OpenClose } from '../ui/Icons';

import { capitalize } from '../../utils/WordUtil';
import { convertUnit, useUnit } from '../../utils/ConversionUtil';

import clsx from 'clsx';

import Moment from '../../utils/TimeUtil';
import { Map } from '../map/Map';
import Button from '../interactive/Button';

//
// Folds a string at a specified length, optionally attempting
// to insert newlines after whitespace characters.
//
// s          -  input string
// n          -  number of chars at which to separate lines
// useSpaces  -  if true, attempt to insert newlines at whitespace
// a          -  array used to build result
//
// Returns an array of strings that are no longer than n
// characters long.  If a is specified as an array, the lines
// found in s will be pushed onto the end of a.
//
// If s is huge and n is very small, this metho will have
// problems... StackOverflow.
//

function fold(s, n, useSpaces, a) {
    a = a || [];
    if (s.length <= n) {
        a.push(s);
        return a;
    }
    let line = s.substring(0, n);
    if (! useSpaces) { // insert newlines anywhere
        a.push(line);
        return fold(s.substring(n), n, useSpaces, a);
    }
    else { // attempt to insert newlines after whitespace
        let lastSpaceRgx = /\s(?!.*\s)/;
        let idx = line.search(lastSpaceRgx);
        let nextIdx = n;
        if (idx > 0) {
            line = line.substring(0, idx);
            nextIdx = idx;
        }
        a.push(line);
        return fold(s.substring(nextIdx), n, useSpaces, a);
    }
}

export function Alert({alert, o = false, showMap = true}) {
    const unit = useUnit();
    const [open, setOpen] = useState(o);
    const [map, setMap] = useState(false);

    const descriptions = alert.descriptionRaw.split("* ");
    const isSws = alert.event === "Special Weather Statement";

    return (
        <>
            <div onClick={() => setOpen(!open)} className="flex justify-between items-center text-gray-3 text-xl pl-1.5 pr-1.5 cursor-pointer">
                <div className="flex text-[1.05rem] font-semibold items-center">
                    <NavLink to={`/alert/${alert.id}`}>
                        <InfoIcon className="mr-2 h-4 w-4" color={alert.color.hexColor}/>
                    </NavLink>
                    <div className="text-white uppercase text-[16px] tracking-tight font-bold">{alert.event}</div>
                </div>
                <div className="flex items-center font-medium text-xs-md text-gray-50 float-right">
                    <span className="hidden sm:block">
                        <Moment unix={alert.effective}/> &#x2022; Ends{" "}<Moment unix={alert.ends > alert.expires ? alert.ends : alert.expires}/>
                    </span>
                    <svg className="flex ml-2 mb-0.5 h-3.5 w-3.5 text-white" viewBox="0 0 24 24">
                        <OpenClose open={open}/>
                    </svg>
                </div>
            </div>
            {open && (
                <div className="px-3 pb-2">
                    {alert.isInAlert !== undefined && (
                        <div className={clsx("text-sm font-medium pb-2", alert.isInAlert ? "text-red-400" : "text-yellow-400")}>This location is {alert.isInAlert ? "inside" : "outside"} the immediate area affected by this alert.</div>
                    )}

                    <div className="block sm:hidden mt-0.5 pb-3 text-white text-xs">
                        <span><Moment unix={alert.effective}/> &#x2022; Ends{" "}<Moment unix={alert.ends > alert.expires ? alert.ends : alert.expires}/></span>
                    </div>

                    <div className="sm:mt-2 text-white text-md uppercase tracking-tight font-semibold">Issued By</div>
                    <div className="text-gray-300 text-sm font-medium">{alert.sender}</div>

                    <div className="flex items-center text-md text-white uppercase tracking-tight font-semibold pt-2">Affected Counties{alert.counties.length > 1 ? <span className="text-md text-gray-300 ml-1">({alert.counties.length})</span> : ""}</div>
                    <div className="text-gray-300 text-sm font-medium">{getSplit(alert.counties).join(", ")}</div>

                    {alert.states.length > 0 && (
                        <>
                            <div className="flex text-md items-center text-white uppercase tracking-tight font-semibold pt-2">Affected States{alert.states.length > 1 ? <span className="text-md text-gray-300 ml-1">({alert.states.length})</span> : ""}</div>
                            <div className="text-gray-300 text-sm font-medium">{getSplit(alert.states).join(", ")}</div>
                        </>
                    )}

                    {alert.threats.length > 0 && (
                        <>
                            <div className="text-white text-md uppercase tracking-tight font-semibold pt-2">Threats</div>
                            {alert.threats.length === 2 ? (
                                <div className="text-gray-300 text-sm font-medium">{capitalize(`${alert.threats[0].toLowerCase()} and ${alert.threats[1].toLowerCase()}`, false)}.</div>
                            ) : (
                                <>
                                    {alert.threats.length === 1 ? (
                                        <div className="text-gray-300 text-sm font-medium">{capitalize(alert.threats[0].toLowerCase(), false)}.</div>
                                    ) : (
                                        <div className="text-gray-300 text-sm font-medium">{capitalize(`${alert.threats.slice(0, alert.threats.length - 1).join(", ").toLowerCase()}, and ${alert.threats[alert.threats.length - 1].toLowerCase()}`, false)}.</div>
                                    )}
                                </>

                            )}
                        </>
                    )}

                    {alert.descriptionRaw !== undefined && (
                        <>
                            <div className="text-white text-md uppercase tracking-tight font-semibold pt-2">Description</div>
                            <span className="text-gray-300 text-sm font-medium">
                                {isSws ? (
                                    <div dangerouslySetInnerHTML={{__html: convertUnit(unit, alert.descriptionRaw.replaceAll('%n', '<br/>'))}}/>
                                ) : (
                                    <div dangerouslySetInnerHTML={{__html: descriptions.map(d => fold(convertUnit(unit, d.replaceAll("%n", ' ')), 68, true).join('<br/>')).join('<br/><br/> * ').replace('<br/>', '').replace('<br/>', '')}}/>
                                )}
                            </span>
                        </>
                    )}

                    {alert.instruction !== undefined && (
                        <>
                            <div className="text-white text-md uppercase tracking-tight font-semibold pt-2">Instruction</div>
                            <span className="text-gray-300 text-sm font-medium">
                                <div dangerouslySetInnerHTML={{__html: alert.instruction.replaceAll("%n", '<br/>')}}/>
                            </span>
                        </>
                    )}
                    {alert.geometry !== undefined && showMap && (
                        <>
                            <div className="text-white uppercase tracking-tight font-semibold pt-2">Map</div>
                            {map ? <div className="mt-2 mb-1.5"><Map zoom={2.5} show={{outlooks: false, alerts: [alert], radar: false, popups: false}} borderRadius={true} height="500px"/></div> : <Button className="mt-1.5 mb-2" name="Open Map" func={() => setMap(true)}/>}
                        </>
                    )}
                </div>
            )}
        </>
    )
}

function getSplit(s) {
    return s.map(ss => ss.split(", ")[0]);
}