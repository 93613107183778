import Checkbox from '../../components/ui/Checkbox';

import { useState } from 'react';
import Card from '../../components/ui/Card';
import { useFetch } from '../../utils/FetchUtil';
import { usePushContext } from '../../utils/PushUtil';
import { useHash, useUrl } from '../../utils/LocationUtil';
import Button from '../../components/interactive/Button';

export default function Notifications({data, error, loading}) {
    const [checked, setChecked] = useState(data.notifications.enabledTypes);
    const [unsubscribe, setUnsubscribe] = useState(false);

    const { unsubscribeDevice, getSubscriptionJson } = usePushContext();
    const { fetchNow } = useFetch();

    const url = useUrl();
    const offset = useHash(true);

    function toggleCheck(t) {
        setChecked(checked.includes(t) ? checked.filter((c) => c !== t) : [...checked, t]);
        fetch(t);
    }

    function fetch(t) {
        fetchNow(`/api/toggleNotification/${url.replace("/en/us/", "")}${offset}`, {
            body: JSON.stringify({... getSubscriptionJson(), type: t}),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            method: 'POST'
        })
    }

    function fetchUnsubscribe() {
        fetchNow(`/api/unsubscribeNotification`, {
            body: JSON.stringify({... getSubscriptionJson()}),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            method: 'POST'
        })
    }

    return (
        <Card>
            <div className="space-y-1 px-2 py-2">
                {data.notifications.possibleTypes.map((t, i) => {
                    return (
                        <div key={i}>
                            <Checkbox name={t.replaceAll("_", " ")} label={t} checked={checked.includes(t)} onCheck={() => toggleCheck(t)}/>
                        </div>
                    )
                })}
                <div className="pt-3 flex space-x-2.5 items-center">
                    <Button name="Unsubscribe" func={() => {
                        if(unsubscribe) {
                            fetchUnsubscribe();
                            unsubscribeDevice();
                            setChecked([]);
                        } else {
                            setUnsubscribe(true);
                        }
                    }}/>
                    <p className="text-xs text-red-400 font-medium">WARNING: {unsubscribe && "Are you sure?"} This cannot be undone. <span className="font-semibold">YOU WILL LOOSE ALL NOTIFICATION DATA.</span></p>
                </div>
            </div>
        </Card>
    )
}