
import { useLetsWeatherContext } from '../utils/LocationUtil';import { setUnit, useUnit } from '../utils/ConversionUtil';
import { useEffect, useState } from 'react';
import { useHash, usePage } from '../utils/LocationUtil';

import { NavLink, useParams } from 'react-router-dom';

import clsx from 'clsx';
import { PUSH_STATE, usePushContext } from '../utils/PushUtil';
const LocationPages = ["Current", "Hourly", "Daily", "Weekend", "Alerts", "Map", "Products", "Graphs", "Precipitation", "Notifications"];

export default function LocationHeader() {
    let { location } = useLetsWeatherContext();
    let { subscriptionState } = usePushContext();

    let params = useParams();
    let offset = useHash();
    let page = usePage(false);
    let unit = useUnit();

    let path = params.latlng !== undefined ? `${params.latlng}` : `${params.country}/${params.city}/${params.zipcode}`;

    let [isOpaque, setIsOpaque] = useState(page === "map")

    useEffect(() => {
        setIsOpaque(page === "map");
    }, [page]);

    useEffect(() => {
        if(page === "map")
            return;

        let offset = 10;

        function onScroll() {
            if (!isOpaque && window.scrollY > offset) {
                setIsOpaque(true)
            } else if (isOpaque && window.scrollY <= offset) {
                setIsOpaque(false)
            }
        }

        onScroll();
        window.addEventListener('scroll', onScroll, {passive: true});

        return () => window.removeEventListener('scroll', onScroll, {passive: true});
    }, [isOpaque])

    return (
        <div style={{zIndex: "10"}} className={clsx(isOpaque
            ? 'supports-backdrop-blur:bg-gray-900/75'
            : 'bg-transparent supports-backdrop-blur:bg-white/60 bg-transparent', page === "map" && "md:max-w-full border-b border-b-lwbg3/30", page === "map" && (location === null || location === undefined) && "border-b-transparent", isOpaque && "backdrop-blur bg-transparent", "sticky top-[62px] hide-sb overflow-x-scroll max-w-full text-md flex flex-col justify-between mx-auto pb-1.5")}>
            <div className="mx-auto w-full max-w-[1360px]">
                <div className="md:mx-6 flex justify-between text-sm font-medium uppercase">
                    <div className="flex justify-between space-x-2.5 pt-2 px-3">
                        {LocationPages.filter(p => p !== "Notifications" || (p === "Notifications" && subscriptionState.state === PUSH_STATE.SUBSCRIBED)).map((p, i) => (
                            <div key={i}>
                                <NavLink end to={`/en/${path}${i === 0 ? "" : `/${p.toLowerCase()}`}${offset === undefined ? "" : `#${offset}`}`} className={({isActive}) => !isActive ?
                                    "text-white opacity-[60%] hover:opacity-100 transition-opacity duration-300 hover:border-t border-gray-200/70 pt-2 px-1" : "font-semibold text-white border-t border-gray-200/70 pt-2 px-1"
                                }>{p}</NavLink>
                            </div>
                        ))}
                    </div>
                    {/*<div className="flex items-center justify-evenly space-x-2.5 pt-2 px-3 text-sm font-medium uppercase">*/}
                    <div className="px-3">
                        <div className="flex items-center space-x-2.5 pt-2">
                            <div>
                                <NavLink onClick={e => e.preventDefault()} to={offset === undefined ? "#" : `?${offset}`} className={({isActive}) => !isActive ?
                                    "text-white opacity-[60%] hover:opacity-100 transition-opacity duration-300 hover:border-t border-gray-200/70 pt-2 px-1" : "font-semibold text-white border-t border-gray-200/70 pt-2 px-1"
                                }>NWS</NavLink>
                            </div>
                            <div>
                                <NavLink onClick={e => {
                                    e.preventDefault();
                                    setUnit(unit === "C" ? "F" : "C");
                                }} to={offset === undefined ? "#" : `?${offset}`} className={({isActive}) => !isActive ?
                                    "text-white opacity-[60%] hover:opacity-100 transition-opacity duration-300 hover:border-t border-gray-200/70 pt-2 px-1" : "font-semibold text-white border-t border-gray-200/70 pt-2 px-1"
                                }>{unit}°</NavLink>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
