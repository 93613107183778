import { createContext, useContext, useEffect, useMemo, useState } from 'react';

export const PUSH_KEYS = {
    subject: "mailto: <vapid@eazyftw.com>",
    publicKey: "BAERDWyVJ_hz0gL3Vxzi61bmevyfLGq7XCGcMKONgWx93q2-8ogWmOLj4u3G3vFW0wHRjy_-9bJh-5-oyBDj-M8",
    // privateKey: "KTbK48oPVnQAsvA_aaZDWOc6SETkPi8XNfD8Q60R8as"
}

export const PUSH_STATE = {
    UNSUPPORTED: {
        id: 'UNSUPPORTED',
        interactive: false,
        pushEnabled: false,
    },
    INITIALISING: {
        id: 'INITIALISING',
        interactive: false,
        pushEnabled: false,
    },
    PERMISSION_DENIED: {
        id: 'PERMISSION_DENIED',
        interactive: false,
        pushEnabled: false,
    },
    PERMISSION_GRANTED: {
        id: 'PERMISSION_GRANTED',
        interactive: true,
    },
    PERMISSION_PROMPT: {
        id: 'PERMISSION_PROMPT',
        interactive: true,
        pushEnabled: false,
    },
    ERROR: {
        id: 'ERROR',
        interactive: false,
        pushEnabled: false,
    },
    STARTING_SUBSCRIBE: {
        id: 'STARTING_SUBSCRIBE',
        interactive: false,
        pushEnabled: true,
    },
    SUBSCRIBED: {
        id: 'SUBSCRIBED',
        interactive: true,
        pushEnabled: true,
    },
    STARTING_UNSUBSCRIBE: {
        id: 'STARTING_UNSUBSCRIBE',
        interactive: false,
        pushEnabled: false,
    },
    UNSUBSCRIBED: {
        id: 'UNSUBSCRIBED',
        interactive: true,
        pushEnabled: false,
    },
};

export const LetsWeatherPushContext = createContext({subscription: null, unsubscribeDevice: () => {}, getSubscriptionJson: () => {}, subscribeDevice: () => {}, setSubscription: () => {} , subscriptionState: PUSH_STATE.INITIALISING, _setSubscriptionState: () => {}});

export const LetsWeatherPushProvider = LetsWeatherPushContext.Provider;

export function usePushContext() {
    return useContext(LetsWeatherPushContext);
}

export function PushContext(props) {
    const [subscription, setSubscription] = useState(null);
    const [subscriptionState, _setSubscriptionState] = useState({subscribeDevice, unsubscribeDevice, getSubscriptionJson, state: PUSH_STATE.INITIALISING, info: undefined});

    const value = useMemo(
        () => ({ subscription, setSubscription, subscriptionState, _setSubscriptionState, subscribeDevice, unsubscribeDevice, getSubscriptionJson }),
        [subscription, subscriptionState]
    );

    function setSubscriptionState(state, info) {
        _setSubscriptionState({state, info});
        // console.debug('Subscription state changed to: ', state, info)
    }

    function getSubscriptionJson() {
        if (!subscription || subscriptionState.state !== PUSH_STATE.SUBSCRIBED)
            return {endpoint: "", key: "", auth: ""};

        let key = subscription.getKey ? subscription.getKey('p256dh') : '';
        let auth = subscription.getKey ? subscription.getKey('auth') : '';

        return {
            endpoint: subscription.endpoint,
            // Take byte[] and turn it into a base64 encoded string suitable for
            // POSTing to a server over HTTP
            key: key ? btoa(String.fromCharCode.apply(null, new Uint8Array(key))) : '',
            auth: auth ? btoa(String.fromCharCode.apply(null, new Uint8Array(auth))) : ''
        };
    }

    function permissionStateChange(permissionState) {
        // If the notification permission is denied, it's a permanent block.
        switch (permissionState) {
            case 'denied':
                setSubscriptionState(PUSH_STATE.PERMISSION_DENIED);
                break;
            case 'granted':
                setSubscriptionState(PUSH_STATE.PERMISSION_GRANTED);
                break;
            case 'default':
                setSubscriptionState(PUSH_STATE.PERMISSION_PROMPT);
                break;
            default:
                console.error('Unexpected permission state: ', permissionState);
                break;
        }
    }

    async function subscribeDevice() {
        setSubscriptionState(PUSH_STATE.STARTING_SUBSCRIBE);

        try {
            switch (Notification.permission) {
                case 'denied':
                    throw new Error('Push messages are blocked.');
                case 'granted':
                    break;
                default:
                    await new Promise((resolve, reject) => {
                        Notification.requestPermission((result) => {
                            if (result !== 'granted')
                                reject(new Error('Bad permission result'));

                            resolve();
                        });
                    });
            }

            try {
                const reg = await navigator.serviceWorker.ready;
                const subscription = await reg.pushManager.subscribe(
                    {
                        userVisibleOnly: true,
                        applicationServerKey: base64UrlToUint8Array(PUSH_KEYS.publicKey),
                    },
                );

                setSubscriptionState(PUSH_STATE.SUBSCRIBED);
                setSubscription(subscription);
            } catch (err) {
                console.error('Failed to subscribe the user: ', err);
                setSubscriptionState(PUSH_STATE.ERROR, err);
            }
        } catch (err) {
            console.error('subscribeDevice() ', err);
            // Check for a permission prompt issue
            // permissionStateChange(Notification.permission);
            setSubscriptionState(PUSH_STATE.ERROR, err);
        }
    }

    async function unsubscribeDevice() {
        setSubscriptionState(PUSH_STATE.STARTING_UNSUBSCRIBE);

        try {
            const reg = await navigator.serviceWorker.ready;
            const subscription = await reg.pushManager.getSubscription();

            // Check we have everything we need to unsubscribe
            if (!subscription) {
                setSubscriptionState(PUSH_STATE.UNSUBSCRIBED);
                setSubscription(null);
                return;
            }

            const successful = await subscription.unsubscribe();
            if (!successful)
                console.warn('Unsubscription failed.');

            setSubscriptionState(PUSH_STATE.UNSUBSCRIBED);
            setSubscription(null);
        } catch (err) {
            console.error('Error thrown while revoking push notifications. Most likely because push was never registered', err);
        }
    }

    useEffect(() => {
        const check = async() => {
            if (!('serviceWorker' in navigator))
                setSubscriptionState(PUSH_STATE.UNSUPPORTED, 'Service worker not available on this browser.');
            if (!('PushManager' in window))
                setSubscriptionState(PUSH_STATE.UNSUPPORTED, 'PushManager not available on this browser.');
            if (!('showNotification' in ServiceWorkerRegistration.prototype))
                setSubscriptionState(PUSH_STATE.UNSUPPORTED, 'Showing Notifications from a service worker is not available on this browser.');
        }

        const worker = async() => {
            if(subscriptionState.state === PUSH_STATE.UNSUPPORTED)
                return;

            registerServiceWorker();
            permissionStateChange(Notification.permission);

            const reg = await navigator.serviceWorker.ready;
            const subscription = await reg.pushManager.getSubscription();

            setSubscription(subscription);

            if(!subscription)
                return;

            setSubscriptionState(PUSH_STATE.SUBSCRIBED);
        }

        check().then(() => worker());
    }, []);

    return (
        <LetsWeatherPushProvider value={value}>
            {props.children}
        </LetsWeatherPushProvider>
    )
}

// Converts the URL-safe base64 encoded |base64UrlData| to an Uint8Array buffer.
export function base64UrlToUint8Array(base64UrlData) {
    const padding = '='.repeat((4 - base64UrlData.length % 4) % 4);
    const base64 = (base64UrlData + padding)
        .replace(/-/g, '+')
        .replace(/_/g, '/');

    const rawData = window.atob(base64);
    const buffer = new Uint8Array(rawData.length);

    for (let i = 0; i < rawData.length; ++i) {
        buffer[i] = rawData.charCodeAt(i);
    }
    return buffer;
}

export function registerServiceWorker() {
    // Check that service workers are supported
    if ('serviceWorker' in navigator) {
        navigator.serviceWorker.register('/service-worker.js')
            .catch((err) => {
                console.error(err);
                console.error(
                    'Unable to Register SW',
                    'Sorry this demo requires a service worker to work and it ' +
                    'failed to install - sorry :(',
                );
            });
    } else {
        console.error(
            'Service Worker Not Supported',
            'Sorry this demo requires service worker support in your browser. ' +
            'Please try this demo in Chrome or Firefox Nightly.',
        );
    }
}
