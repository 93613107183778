import { IconText } from '../ui/Icons';

import { NavLink } from 'react-router-dom';

import { isNight } from '../../utils/IconUtil';
import { useFetch } from '../../utils/FetchUtil';
import { removeLocation, STATE_ABBREVIATIONS, usePage } from '../../utils/LocationUtil';

import clsx from 'clsx';
import { temperature, useUnit } from '../../utils/ConversionUtil';
import { useEffect } from 'react';

export default function LocationPreview({loc, search, showAlerts, big = false}) {
    const { data, error, loading, fetchNowLocation } = useFetch();

    const unit = useUnit();
    const page = usePage(true, "");

    useEffect(() => {
        fetchNowLocation('preview', loc === undefined ? undefined : {city: loc.name.toLowerCase().replaceAll(" ", "-"), zipcode: loc.zip_codes[0]});
    }, [loc]);

    if(loc === undefined)
        return null;

    return big ? (
        <div className="group">
            <NavLink to={`/en/${loc.country_code.toLowerCase()}/${loc.name.toLowerCase().replaceAll(" ", "-")}/${loc.zip_codes[0]}${loc.offsetId !== undefined ? `#${loc.offsetId}` : ""}`} className={clsx(search ? "flex items-center justify-between transition-colors duration-200 bg-gray-800/10 hover:bg-gray-800/20 font-medium text-gray-300 duration-200 text-sm border border-lwbg3" : "relative flex flex-col px-4 pr-5 shadow-sm py-2 text-sm rounded-lg bg-gray-800/10 hover:bg-gray-800/20 transition-colors duration-200 cursor-pointer border border-lwbg3")}>
                {showAlerts !== undefined && showAlerts && data !== undefined && data.alerts !== undefined && data.alerts.length > 0 && (
                    <div className="font-medium text-xs absolute flex items-center text-center left-[-11px] top-[15px] h-[22px] w-[22px] bg-[#161C26] rounded-full">
                        <div className="mx-auto">
                            {data.alerts.length}
                        </div>
                    </div>
                )}
                <div className="flex items-center justify-between">
                    <div className="truncate">
                        <div className="text-md font-medium truncate">
                            {loc.name}
                        </div>
                        <div className="text-xs truncate">
                            {STATE_ABBREVIATIONS[loc.state_code] !== undefined ? STATE_ABBREVIATIONS[loc.state_code] : loc.state_code}
                        </div>
                    </div>
                    <div>
                        <div className="flex items-center space-x-1.5">
                            <div>
                                <IconText size={22} text={data === undefined || data.text === undefined ? "" : data.text} night={isNight()}/>
                            </div>
                            <div className="text-md tracking-[0.02rem] text-right">
                                {data === undefined || data.temperature === undefined ? "--" : temperature(unit, data.temperature)}
                            </div>
                        </div>
                    </div>
                </div>
            </NavLink>
        </div>
    ) : (
        <div className="group">
            {search !== undefined && search && (
                <div className="group-hover flex text-xl font-bold whitespace-pre-wrap bg-gray-900">
                    <div className="absolute -ml-7 pr-1 h-[36px] flex items-center opacity-0 border-0 group-hover:opacity-100" aria-label="Anchor">​
                        <button onClick={() => removeLocation(loc.id, loc.offsetId)} className="text-gray-400 rounded-md shadow-sm flex items-center justify-center bg-[#0E121C] hover:shadow hover:bg-[#13171E]">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 p-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        </button>
                    </div>
                </div>
            )}
            <NavLink to={`/en/${loc.country_code.toLowerCase()}/${loc.name.toLowerCase().replaceAll(" ", "-")}/${loc.zip_codes[0]}${page}${loc.offsetId !== undefined ? `#${loc.offsetId}` : ""}`} className={clsx(search ? "flex px-3 py-2 items-center justify-between transition-colors duration-200 hover:bg-gray-800/20 font-medium text-gray-300 duration-200 text-sm" : "relative flex px-4 shadow-sm py-2 font-medium text-sm items-center justify-between rounded-lg bg-gray-300/30 hover:bg-gray-800/20 transition-colors duration-200 cursor-pointer")}>
                <div className="truncate">
                    {loc.fullName}
                </div>
                <div className="flex items-center space-x-1">
                    <div>
                        <IconText size={20} text={data === undefined || data.text === undefined ? "" : data.text} night={isNight()}/>
                        {showAlerts !== undefined && showAlerts && data !== undefined && data.alerts !== undefined && data.alerts.length > 0 && (
                            <div className="bg-red-600/60 absolute -mt-[1.6rem] ml-[0.7rem] rounded-full flex items-center text-center text-[10px] w-[15px] h-[15px]">
                                <div className="mx-auto pr-[1px] pb-[1px]">
                                    {data.alerts.length}
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="text-right" style={{width: "38px"}}>
                        {data === undefined || data.temperature === undefined ? "--" : temperature(unit, data.temperature)}
                    </div>
                </div>
            </NavLink>
        </div>
    )
}