import clsx from 'clsx';

import Moment from '../../utils/TimeUtil';

export default function Card({title, time, background, className, children, border = true}) {
    return (
        <div className={clsx(background === undefined ? "bg-lwbg2/40" : background, "rounded-md p-2.5", border && "border border-lwbg3 shadow-md", className)}>
            {title !== undefined && <div className="font-semibold text-white text-center">{title}</div>}
            {time !== undefined && time !== 0 && <div className="text-center text-sm font-medium uppercase -mt-0.5"><Moment time={true} unix={time}/></div>}
            {children}
        </div>
    )
}
